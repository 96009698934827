import gql from "graphql-tag";

export const HostSearchCriteriaAssignments = {
    Queries: {
        HostSearchCriteriaAssignments: gql`
        query hostSearchCriteriaAssignments($hostId: ID, $hostSearchCriteriaAssignmentStatusIds: [ID]) {
            hostSearchCriteriaAssignments(hostId: $hostId, hostSearchCriteriaAssignmentStatusIds: $hostSearchCriteriaAssignmentStatusIds) {
                Id
                HostId
                HostSearchCriteriaAssignmentStatusId
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
            }
        }`,
    },
    Mutations: {
        CreateHostSearchCriteriaAssignment: gql`
        mutation createHostSearchCriteriaAssignment($hostSearchCriteriaAssignment: HostSearchCriteriaAssignmentsInput) {
            createHostSearchCriteriaAssignment(hostSearchCriteriaAssignment: $hostSearchCriteriaAssignment) {
                IsSuccess
                Message
                StringData
            }
        }`,
    },
};
