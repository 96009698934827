<template lang="pug">
  .product-contract-editor.position-relative
    template(v-if="productContract")
      nav.sticky-top.top-nav.w-100.mt-4(style="top:55px;margin:0 -15px;")
        .d-flex.align-items-center
          StepBar.w-100.my-2(:current-step="currentStep" :steps="steps" :set-step="setStep")
      .w-100.pt-4
        .pt-4.container(v-if="host")
          HostDataStep(:host="host" v-if="currentStep === 1")
          ContractAcceptStep(:product-contract="productContract" v-if="currentStep === 2")
          template(v-if="currentStep === 1 || currentStep === 2")
            button.button.button-primary.button-tbook.w-100.mt-3(data-toggle='collapse' href='#collapseProductContract' role='button' aria-expanded='false' aria-controls='collapseProductContract') Vertragsvorschau ausklappen
            #collapseProductContract.collapse
              div(v-html="productContractHtml")

          div(:class="{ 'hide': currentStep !== 3 }")
            .host-data-form
              .text-center.mb-4
                .h1 Schritt 3: Datenblatt pflegen (optional)
                .txt-td-grey-basic
                  | Pflegen Sie in diesem Schritt Ihr Datenblatt, um bestmöglich in unserem Shop gelistet und gefunden zu werden.
            datasheet-search-criteria-areas(:host-id="host.Id")
          div(:class="{ 'hide': currentStep !== 4 }")
            .text-center.mb-4
              .h1 Schritt 4: Bilder hochladen (optional)
              .txt-td-grey-basic
                | In diesem Schritt haben Sie die Möglichkeit Bilder für unser Service-Team zu hinterlegen, damit Ihre Produkt auf schnellstem Weg online gestellt werden können.
            upload-images(:host-id="host.Id" :class="{ 'hide': currentStep !== 4 }")
      .row
        .col-12
          button.button.button-primary.button-tbook(@click="currentStep--" :disabled="!isPreviousStepValid" :class="{ 'disabled': !isPreviousStepValid }") zurück
          .float-right
            button.button.button-primary.button-tdays(@click="handleNextStep" :disabled="!isNextStepValid" :class="{ 'disabled': !isNextStepValid }") {{ nextStepDescription }}

</template>
<script>
import { ProductContracts } from "@/lib/graphql/productContracts/ProductContracts.ts";
import EventBus from "@/views/event-bus";
import StepBar from "@/views/modals/productContract/editor/StepBar.vue";
import HostDataStep from "@/views/modals/productContract/editor/HostDataStep.vue";
import ContractAcceptStep from "@/views/modals/productContract/editor/ContractAcceptStep.vue";
import { Hosts } from "@/lib/graphql/hosts/Hosts.ts";
import DatasheetSearchCriteriaAreas from "@/views/pages/account/datasheet/datasheet-search-criteria-areas.vue";
import { ProductContractStatusEnum } from "@/lib/enums/productContractStatus/ProductContractStatusEnum";
import UploadImages from "@/views/modals/productContract/editor/upload-images.vue";
import { BIC, IBAN } from "ibankit";
import { NotificationTypesEnum } from "@/lib/enums/notificationTypes/NotificationTypesEnum.ts";
import { Notifications } from "@/lib/graphql/notifications/Notifications.ts";
import moment from "moment";
import { UserGroups } from "@/lib/graphql/userGroups/UserGroups.ts";
import { HostsComponent } from "@/lib/components/hosts/HostsComponent";
import { ProductContractsComponent } from "@/lib/components/productContracts/ProductContractsComponent";

export default {
    name: "ProductContractEditor",
    components: {
        UploadImages,
        DatasheetSearchCriteriaAreas,
        ContractAcceptStep,
        HostDataStep,
        StepBar,
    },
    props: {
        productContractId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            productContractHtml: undefined,
            productContract: undefined,
            acceptedTerms: false,
            host: undefined,
            localHost: undefined,
            currentStep: 1,

            steps: [
                {
                    step: 1,
                    label: "Rechnungsdaten bearbeiten",
                    required: true,
                    isDone: false,
                    isLocked: false,
                },
                {
                    step: 2,
                    label: "Vertragsabschluss",
                    required: true,
                    isDone: false,
                    isLocked: true,
                },
                {
                    step: 3,
                    label: "Eigenschaften verwalten",
                    required: false,
                    isLocked: true,
                },
                {
                    step: 4,
                    label: "Bilder hinterlegen",
                    required: false,
                    isLocked: true,
                },
            ],
        };
    },
    async mounted() {
        try {
            this.loadProductContractHtml();
            await this.loadProductContract();
            await this.loadHost();

            if (
                this.productContract.ProductContractStatusId ==
                ProductContractStatusEnum.Confirmed
            ) {
                this.steps
                    .filter((s) => s.step < 3)
                    .forEach((s) => {
                        s.isDone = true;
                        s.isLocked = true;
                    });
                this.steps
                    .filter((s) => s.step > 2)
                    .forEach((s) => (s.isLocked = false));
                const secondStep = this.steps.find((s) => s.step == 2);
                if (!secondStep) {
                    return;
                }
                this.currentStep = 3;
            } else if (this.isHostDataValid) {
                const firstStep = this.steps.find((s) => s.step == 1);
                if (!firstStep) {
                    return;
                }
                firstStep.isDone = true;

                const secondStep = this.steps.find((s) => s.step == 2);
                if (!secondStep) {
                    return;
                }
                secondStep.isLocked = false;
                this.currentStep = 2;
            }
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async handleNextStep() {
            try {
                if (this.currentStep == 1) {
                    if (!this.hasHostAccountDataChanged) {
                        return this.nextStep();
                    }
                    const confirmed = await this.$confirm(
                        "Sind Sie sicher, dass Sie Ihre Rechnungsinformationen dem touriDat-Service übermitteln möchten?"
                    )
                        .then()
                        .catch((e) => e);
                    if (!confirmed) {
                        return;
                    }
                    if (!this.hasHostAccountDataChanged) {
                        this.setIsDone();
                    }
                    const userGroup = await this.$apollo
                        .query({
                            query: UserGroups.Queries.UserGroupByIndicator,
                            fetchPolicy: "no-cache",
                            variables: {
                                indicator: "büroleitung",
                            },
                        })
                        .then(({ data }) => data?.userGroupByIndicator)
                        .catch((e) => {
                            console.error(e);
                            return undefined;
                        });
                    if (!userGroup) {
                        EventBus.$emit("changeLoadingState", false);
                        return this.$alert(
                            "Keine zu addressierenden touriDat-Benutzer gefunden. Ihre Bilder wurden trotzdessen erfolgreich hochgeladen."
                        );
                    }
                    const notification = {
                        Title: `Geänderte Zahlungsinformationen von Gastgeber ${this.host.Id}`,
                        Message: `IBAN: ${this.localHost.IBAN} in ${this.host.IBAN}\nUStIDNr: ${this.localHost.UStIDNr} in ${this.host.UStIDNr}\nKontoinhaber(in): ${this.localHost.AccountOwner} in ${this.host.AccountOwner}`,
                        UserGroupId: userGroup.Id,
                        HostId: this.host.Id,
                        CreatedBy: this.$session.get("userId"),
                        NotificationTypeId:
                            NotificationTypesEnum.ProductManager,
                    };
                    EventBus.$emit("changeLoadingState", true);
                    this.productContract.UStIDNr = this.host.UStIDNr;
                    delete this.host.UStIDNr;
                    this.productContract.AccountOwner = this.host.AccountOwner;
                    delete this.host.AccountOwner;
                    this.productContract.IBAN = this.host.IBAN;
                    delete this.host.IBAN;
                    this.productContract.BIC = this.host.BIC;
                    delete this.host.BIC;
                    const productContractsComponent =
                        new ProductContractsComponent();
                    const savedProductContract =
                        await productContractsComponent.saveProductContract(
                            this.$apollo,
                            this.productContract
                        );
                    if (!savedProductContract) {
                        EventBus.$emit("changeLoadingState", false);
                        return this.$alert(
                            "Der Vertrag konnte nicht gespeichert werden."
                        );
                    }
                    if (!savedProductContract.IsSuccess) {
                        EventBus.$emit("changeLoadingState", false);
                        return this.$alert(savedProductContract.Message);
                    }
                    const hostsComponent = new HostsComponent();
                    const savedHost = await hostsComponent.saveHost(
                        this.$apollo,
                        this.host,
                        this.$session.get("userId")
                    );
                    if (!savedHost) {
                        EventBus.$emit("changeLoadingState", false);
                        return this.$alert(
                            "Der Gastgeber konnte nicht gespeichert werden."
                        );
                    }
                    if (!savedHost.IsSuccess) {
                        EventBus.$emit("changeLoadingState", false);
                        return this.$alert(savedHost.Message);
                    }
                    const savedNotification = await this.$apollo
                        .mutate({
                            mutation: Notifications.Mutations.SaveNotification,
                            fetchPolicy: "no-cache",
                            variables: {
                                notification: notification,
                            },
                        })
                        .then(({ data }) => data?.saveNotification)
                        .catch((e) => {
                            console.error(e);
                            return undefined;
                        });
                    EventBus.$emit("changeLoadingState", false);
                    if (!savedNotification) {
                        return this.$alert(
                            "Die Benachrichtigung der geänderten Zahlungsinformationen konnte nicht an den touriDat-Service übermittelt werden."
                        );
                    }
                    if (!savedNotification.IsSuccess) {
                        return this.$alert(savedNotification.Message);
                    }
                    this.setIsDone();
                } else if (this.currentStep == 2) {
                    const confirmed = await this.$confirm(
                        "Sind Sie sicher, dass Sie den Vertrag bestätigen möchten?"
                    )
                        .then()
                        .catch((e) => e);
                    if (!confirmed) {
                        return;
                    }
                    const savedProductContract =
                        await this._saveProductContract();
                    if (!savedProductContract) {
                        return this.$alert(
                            "Der Vertrag konnte nicht gespeichert werden."
                        );
                    }
                    if (savedProductContract.Message) {
                        this.$alert(savedProductContract.Message);
                    }
                    if (!savedProductContract.IsSuccess) {
                        return;
                    }
                    this.steps
                        .filter((s) => s.step > 2)
                        .forEach((s) => (s.isLocked = false));
                    this.steps
                        .filter((s) => s.step < 3)
                        .forEach((s) => {
                            s.isDone = true;
                            s.isLocked = true;
                        });
                    this.nextStep();
                } else if (this.currentStep == 3) {
                    const step = this.steps[this.currentStep - 1];
                    if (!step) {
                        return;
                    }
                    if (step.isDone) {
                        return this.nextStep();
                    }
                    const confirmed = await this.$confirm(
                        "Möchten Sie in zwei Wochen an die Pflege Ihres Datenblatts erinnert werden?"
                    )
                        .then()
                        .catch((e) => e);
                    step.isDone = true;
                    if (!confirmed) {
                        return this.nextStep();
                    }
                    const notification = {
                        Title: "Bitte pflegen Sie Ihr Datenblatt.",
                        UserId: this.$session.get("userId"),
                        HostId: this.host.Id,
                        CreatedBy: this.$session.get("userId"),
                        NotificationDate: moment().add(2, "weeks").toDate(),
                        NotificationTypeId: NotificationTypesEnum.Dashboard,
                    };
                    const savedNotification = await this.$apollo
                        .mutate({
                            mutation: Notifications.Mutations.SaveNotification,
                            fetchPolicy: "no-cache",
                            variables: {
                                notification: notification,
                            },
                        })
                        .then(({ data }) => data?.saveNotification)
                        .catch((e) => {
                            console.error(e);
                            return undefined;
                        });
                    if (!savedNotification) {
                        return this.$alert(
                            "Die Erinnerung konnte nicht erstellt werden."
                        );
                    }
                    if (!savedNotification.IsSuccess) {
                        return this.$alert(savedNotification.Message);
                    }
                    return this.nextStep();
                } else if (this.currentStep == 4) {
                    const confirmed = await this.$confirm(
                        "Sind Sie sicher, dass Sie den Vertragsprozess abschließen und somit zur Startseite zurückkehren möchten?"
                    )
                        .then()
                        .catch((e) => e);
                    if (!confirmed) {
                        return;
                    }
                    return await this.$router.push({ name: "Home" });
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async _saveHost() {
            try {
                const hostsComponent = new HostsComponent();
                EventBus.$emit("changeLoadingState", true);
                const savedHost = await hostsComponent.saveHost(
                    this.$apollo,
                    this.host,
                    this.$session.get("userId")
                );
                EventBus.$emit("changeLoadingState", false);
                if (!savedHost) {
                    return {
                        IsSuccess: false,
                        Message:
                            "Der Gastgeber konnte nicht gespeichert werden.",
                    };
                }
                return savedHost;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        async _saveProductContract() {
            try {
                EventBus.$emit("changeLoadingState", true);
                delete this.productContract.AcceptedTerms;
                this.productContract.ProductContractStatusId =
                    ProductContractStatusEnum.Confirmed;
                this.productContract.ConfirmedBy = this.$session.get("userId");
                this.productContract.ConfirmedAt = new Date();
                const savedProductContract = await this.$apollo
                    .mutate({
                        mutation:
                            ProductContracts.Mutations.SaveProductContract,
                        variables: {
                            productContract: this.productContract,
                        },
                    })
                    .then(({ data }) => data?.saveProductContract)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                EventBus.$emit("changeLoadingState", false);
                return savedProductContract;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        setStep(step) {
            try {
                if (!step) {
                    return;
                }
                const _step = this.steps[step - 1];
                if (!step) {
                    return;
                }
                if (_step.isLocked) {
                    return;
                }
                this.currentStep = _step.step;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        setIsDone() {
            try {
                const step = this.steps.find((s) => s.step == this.currentStep);
                if (!step) {
                    return;
                }
                step.isDone = true;
                const nextStep = this.steps.find(
                    (s) => s.step == this.currentStep + 1
                );
                if (!nextStep) {
                    return this.nextStep();
                }
                nextStep.isLocked = false;
                this.nextStep();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        nextStep() {
            try {
                const nextStep = this.steps[this.currentStep];
                if (!nextStep) {
                    return;
                }
                this.currentStep += 1;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProductContract() {
            try {
                EventBus.$emit("changeLoadingState", true);
                const productContract = await this.$apollo
                    .query({
                        query: ProductContracts.Queries.ProductContract,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.productContractId,
                        },
                    })
                    .then(({ data }) => data?.productContract)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                EventBus.$emit("changeLoadingState", false);
                if (!productContract) {
                    return this.$alert(
                        "Es konnte kein Vertrag geladen werden."
                    );
                }
                if (
                    productContract.ProductContractStatusId !=
                    ProductContractStatusEnum.Sent
                ) {
                    this.$alert(
                        "Der Vertrag ist leider nicht mehr aktuell, wenden Sie sich bitte dazu an Ihren touriDat-Kundenberater."
                    );
                    return await this.$router.push({ name: "Home" });
                }
                this.productContract = productContract;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadHost() {
            try {
                const host = await this.$apollo
                    .query({
                        query: Hosts.Queries.HostByProductContractId,
                        variables: {
                            productContractId: this.productContractId,
                        },
                    })
                    .then(({ data }) => data?.hostByProductContractId)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!host) {
                    return this.$alert(
                        "Es konnte kein Gastgeber geladen werden."
                    );
                }
                this.host = host;
                this.localHost = this._clone(host);
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProductContractHtml() {
            try {
                const productContractHtml = await this.$apollo
                    .mutate({
                        mutation:
                            ProductContracts.Mutations.LoadProductContractHtml,
                        fetchPolicy: "no-cache",
                        variables: {
                            productContractId: this.productContractId,
                        },
                    })
                    .then(({ data }) => data?.loadProductContractHtml)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!productContractHtml) {
                    return this.$alert(
                        "Es konnte keine Vertragsvorschau geladen werden."
                    );
                }
                if (!productContractHtml.IsSuccess) {
                    return this.$alert(productContractHtml.Message);
                }
                this.productContractHtml = productContractHtml.StringData;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        _clone(a) {
            try {
                return JSON.parse(JSON.stringify(a));
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
    computed: {
        isHostDataValid() {
            try {
                if (!this.host) {
                    return false;
                }
                if (!this.host.RAdresse1?.trim().length) {
                    return false;
                }
                if (!this.host.RAdresse3?.trim().length) {
                    return false;
                }
                if (!this.host.RAdresse4?.trim().length) {
                    return false;
                }
                if (!this.host.RPLZ?.trim().length) {
                    return false;
                }
                if (!this.host.RORT?.trim().length) {
                    return false;
                }
                if (!this.host.RLAND?.trim().length) {
                    return false;
                }
                if (!this.host.Internetadresse2?.trim().length) {
                    return false;
                }
                if (!this.host.UStIDNr?.trim().length) {
                    return false;
                }
                if (!this.host.AccountOwner?.trim().length) {
                    return false;
                }
                if (!IBAN.isValid(this.host.IBAN?.trim())) {
                    return false;
                }
                if (!BIC.isValid(this.host.BIC?.trim())) {
                    return false;
                }
                return true;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        isContractDataValid() {
            try {
                if (!this.productContract.Editor?.trim()?.length) {
                    return false;
                }
                if (!this.productContract.Location?.trim()?.length) {
                    return false;
                }
                if (!this.productContract.AcceptedTerms) {
                    return false;
                }
                if (!this.productContract.Signature) {
                    return false;
                }
                return true;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        isPreviousStepValid() {
            try {
                if (this.currentStep == 1) {
                    return false;
                } else if (this.currentStep == 2) {
                    return true;
                } else if (this.currentStep == 3) {
                    return false;
                } else if (this.currentStep == 4) {
                    return true;
                }
                return false;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        isNextStepValid() {
            try {
                if (this.currentStep == 1) {
                    return this.isHostDataValid;
                } else if (this.currentStep == 2) {
                    return this.isContractDataValid;
                }
                return true;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        nextStepDescription() {
            try {
                if (this.currentStep == 1) {
                    return "weiter";
                } else if (this.currentStep == 2) {
                    return "unterschreiben";
                } else if (this.currentStep == 3) {
                    return "überspringen";
                } else if (this.currentStep == 4) {
                    return "fertig";
                }
                return undefined;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        hasHostAccountDataChanged() {
            try {
                if (!this.host) {
                    return false;
                }
                if (!this.host.Id) {
                    return false;
                }
                if (!this.localHost) {
                    return false;
                }
                if (!this.localHost.Id) {
                    return false;
                }
                if (this.host.RAdresse1 != this.localHost.RAdresse1) {
                    return true;
                }
                if (this.host.RAdresse3 != this.localHost.RAdresse3) {
                    return true;
                }
                if (this.host.RAdresse4 != this.localHost.RAdresse4) {
                    return true;
                }
                if (this.host.RPLZ != this.localHost.RPLZ) {
                    return true;
                }
                if (this.host.RORT != this.localHost.RORT) {
                    return true;
                }
                if (this.host.RLAND != this.localHost.RLAND) {
                    return true;
                }
                if (
                    this.host.Internetadresse2 !=
                    this.localHost.Internetadresse2
                ) {
                    return true;
                }
                if (this.host.UStIDNr != this.localHost.UStIDNr) {
                    return true;
                }
                if (this.host.IBAN != this.localHost.IBAN) {
                    return true;
                }
                if (this.host.AccountOwner != this.localHost.AccountOwner) {
                    return true;
                }
                if (this.host.BIC != this.localHost.BIC) {
                    return true;
                }
                return false;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
    },
};
</script>
<style lang="scss">
@import "@/styles/colors";

.product-contract-editor {
    .button[disabled] {
        pointer-events: none;
        opacity: 0.5;
        filter: grayscale(1);
    }

    .form-group {
        .validation-message {
            visibility: hidden;
            font-size: 80%;
            color: $tpics-basic;
        }
    }
}
</style>
<style lang="scss" scoped>
.footer-buttons {
    gap: 1rem;
}

.product-contract-editor {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    display: grid;
    z-index: 1;
    height: calc(100vh - 100px);
}

.preview {
    position: relative;
    width: 100%;
    aspect-ratio: 0.71 / 1;
    background-color: gray;
}

.top-nav {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(3px);
}

.hide {
    height: 0;
    width: 0;
    overflow: hidden;
}
</style>
