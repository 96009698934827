import { ProductContracts } from "@/lib/graphql/productContracts/ProductContracts.ts";

export class ProductContractsComponent {
    async saveProductContract(apollo, productContract) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!productContract) {
                return {
                    IsSuccess: false,
                    Message: "Kein Vertrag gefunden.",
                };
            }
            const savedProductContract = await apollo
                .mutate({
                    mutation: ProductContracts.Mutations.SaveProductContract,
                    fetchPolicy: "no-cache",
                    variables: {
                        productContract: productContract,
                    },
                })
                .then(({ data }) => data.saveProductContract)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedProductContract) {
                return {
                    IsSuccess: false,
                    Message: "Der Vertrag konnte nicht gespeichert werden.",
                };
            }
            return savedProductContract;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getProductContracts(
        apollo,
        hostId,
        userId,
        productDraftId,
        productContractStatusIds,
        productContractTypeIds,
        offset,
        limit
    ) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostId && !userId && !productDraftId) {
                return undefined;
            }
            const productContracts = await apollo
                .query({
                    query: ProductContracts.Queries.ProductContracts,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostId: hostId,
                        userId: userId,
                        productDraftId: productDraftId,
                        productContractStatusIds: productContractStatusIds,
                        productContractTypeIds: productContractTypeIds,
                        offset: offset,
                        limit: limit,
                    },
                })
                .then(({ data }) => data?.productContracts)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!productContracts) {
                return undefined;
            }
            return productContracts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getProductContractsCount(
        apollo,
        hostId,
        userId,
        productDraftId,
        productContractStatusIds,
        productContractTypeIds
    ) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostId && !userId && !productDraftId) {
                return undefined;
            }
            const productContractsCount = await apollo
                .query({
                    query: ProductContracts.Queries.ProductContractsCount,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostId: hostId,
                        userId: userId,
                        productDraftId: productDraftId,
                        productContractStatusIds: productContractStatusIds,
                        productContractTypeIds: productContractTypeIds,
                    },
                })
                .then(({ data }) => data?.productContractsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (productContractsCount == undefined) {
                return undefined;
            }
            return productContractsCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getProductContract(apollo, id) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id) {
                return undefined;
            }
            const productContract = await apollo
                .query({
                    query: ProductContracts.Queries.ProductContract,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: id,
                    },
                })
                .then(({ data }) => data?.productContract)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!productContract) {
                return undefined;
            }
            return productContract;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async reuploadProductContract(apollo, productContractId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!productContractId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Vertrags-Id gefunden.",
                };
            }
            const reuploadedProductContract = await apollo
                .mutate({
                    mutation:
                        ProductContracts.Mutations.ReuploadProductContract,
                    fetchPolicy: "no-cache",
                    variables: {
                        productContractId: productContractId,
                    },
                })
                .then(({ data }) => data?.reuploadProductContract)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!reuploadedProductContract) {
                return {
                    IsSuccess: false,
                    Message:
                        "Der Vertrag konnte nicht erneut hochgeladen werden.",
                };
            }
            return reuploadedProductContract;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async reuploadGeneralTermsAndConditions(apollo, productContractId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!productContractId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Vertrags-Id gefunden.",
                };
            }
            const reuploadedGeneralTermsAndConditions = await apollo
                .mutate({
                    mutation:
                        ProductContracts.Mutations
                            .ReuploadGeneralTermsAndConditions,
                    fetchPolicy: "no-cache",
                    variables: {
                        productContractId: productContractId,
                    },
                })
                .then(({ data }) => data?.reuploadGeneralTermsAndConditions)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!reuploadedGeneralTermsAndConditions) {
                return {
                    IsSuccess: false,
                    Message: "Die AGB konnten nicht erneut hochgeladen werden.",
                };
            }
            return reuploadedGeneralTermsAndConditions;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}
