<template lang="pug">
li.list-group-item.border-0
  .form-check
    input.form-check-input(type="checkbox", id="Id", v-model="filterProductStates")
    label.form-check-label(for="Id") {{$t("products.tableColumn.status." + Id.toString())}}

</template>

<script>
export default {
    name: "product-list-item-checkbox",
    props: {
        Id: String,
        Name: String,
    },
};
</script>

<style scoped></style>
