<template lang="pug">
  .contract-accept-step
    .text-center.mb-4
      .h1 Schritt 2: Vertragsabschluss
      .txt-td-grey-basic Bestätigen Sie in diesem Schritt Ihr Einverständnis indem Sie Ihren Namen und Ort hinterlegen und unsere AGB akzeptieren.
    .row
      .col-12
        .form-group
          label.form-label Name*
          input.form-control(type="text" v-model="productContract.Editor")
          .validation-message(:class="{'visible':!hasValidEditor}") Bitte füllen Sie dieses Feld aus.
      .col-12
        .form-group
          label.form-label Ort*
          input.form-control(type="text" v-model="productContract.Location")
          .validation-message(:class="{'visible':!hasValidLocation}") Bitte füllen Sie dieses Feld aus.
      .col-12.mt-2
        .form-group
          label.d-block
            input.mr-2(type="checkbox" v-model="productContract.AcceptedTerms")
            | Ich stimme den&nbsp;
            a(href="https://td-cloud.s3.eu-central-1.amazonaws.com/Gastgeber_AGB_de.pdf" target="_blank") AGB
            | &nbsp;der touriDat GmbH & Co. KG zu.*
      .col-12.mt-2
        VueSignaturePad.border(width="100%" height="400px" ref="signaturePad" :options="{ onEnd }")
        button.button.button-primary.button-tpics.w-100(@click="deleteSignature") Unterschrift löschen

</template>

<script>
export default {
    name: "ContractAcceptStep",
    props: {
        productContract: {
            type: Object,
            required: true,
        },
    },
    methods: {
        onEnd() {
            try {
                const { data } = this.$refs.signaturePad.saveSignature();
                if (data == "data:,") {
                    return;
                }
                this.productContract.Signature = data;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        deleteSignature() {
            try {
                this.$refs.signaturePad.clearSignature();
                this.productContract.Signature = undefined;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    computed: {
        hasValidEditor() {
            try {
                return this.productContract.Editor?.trim()?.length;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        hasValidLocation() {
            try {
                return this.productContract.Location?.trim()?.length;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.signature-pad {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 400px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    aspect-ratio: 2.5 / 1;
}
</style>
