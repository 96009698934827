<template lang="pug">
.text-center
  button.button.button-primary.button-tdays(@click="loadMore" :class="{ 'disabled': currentCount == overallCount }" :disabled="currentCount == overallCount") mehr laden
  .justify-content-center.d-flex.mt-2
    .progress.bg-td-grey-basic.rounded-0.position-relative.w-50
      .progress-bar.bg-tdays-lighter(role='progressbar' :aria-valuenow='progressWidth' :style="'width: ' + progressWidth + '%'" aria-valuemin='0' aria-valuemax='100')
        span.justify-content-center.d-flex.position-absolute.w-100 {{ currentCount }} / {{ overallCount }}

</template>

<script>
export default {
    name: "new-pagination",
    props: {
        overallCount: {
            type: Number,
            required: true,
        },
        currentCount: {
            type: Number,
            required: true,
        },
        loadMore: {
            type: Function,
            required: true,
        },
    },
    computed: {
        progressWidth() {
            try {
                if (!this.currentCount) {
                    return 0;
                }
                if (this.overallCount == 0) {
                    return 0;
                }
                const result = (this.currentCount / this.overallCount) * 100;
                return result;
            } catch (e) {
                console.log(e);
                return 0;
            }
        },
    },
};
</script>

<style scoped></style>
