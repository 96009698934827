const {
    BookingTickets,
} = require("@/lib/graphql/bookingTickets/BookingTickets.ts");

class BookingTicketsComponent {
    async getBookingTicket(apollo, id) {
        try {
            if (!apollo || !id) {
                return undefined;
            }
            const bookingTicket = await apollo
                .query({
                    query: BookingTickets.Queries.BookingTicket,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: id,
                    },
                })
                .then(({ data }) => data?.bookingTicket)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return bookingTicket;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getBookingTicketsByBookingRequestId(apollo, bookingRequestId) {
        try {
            if (!apollo) {
                return undefined;
            }
            const bookingTickets = await apollo
                .query({
                    query: BookingTickets.Queries
                        .BookingTicketsByBookingRequestId,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingRequestId: bookingRequestId,
                    },
                })
                .then(({ data }) => data?.bookingTicketsByBookingRequestId)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return bookingTickets;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getBookingTickets(
        apollo,
        hostIds,
        bookingTicketStatusIds,
        filter,
        offset,
        limit
    ) {
        try {
            if (!apollo) {
                return undefined;
            }
            const bookingTickets = await apollo
                .query({
                    query: BookingTickets.Queries.BookingTickets,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostIds: hostIds,
                        bookingTicketStatusIds: bookingTicketStatusIds,
                        filter: filter,
                        offset: offset,
                        limit: limit,
                    },
                })
                .then(({ data }) => data?.bookingTickets)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return bookingTickets;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getBookingTicketsCount(
        apollo,
        hostIds,
        bookingTicketStatusIds,
        filter
    ) {
        try {
            if (!apollo) {
                return undefined;
            }
            const bookingTicketsCount = await apollo
                .query({
                    query: BookingTickets.Queries.BookingTicketsCount,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostIds: hostIds,
                        bookingTicketStatusIds: bookingTicketStatusIds,
                        filter: filter,
                    },
                })
                .then(({ data }) => data?.bookingTicketsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return bookingTicketsCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async cancelBookingTicket(apollo, bookingTicketId, userId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!bookingTicketId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Buchungsticket-Id gefunden.",
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Benutzer-Id gefunden.",
                };
            }
            const cancelledBookingTicket = await apollo
                .mutate({
                    mutation: BookingTickets.Mutations.CancelBookingTicket,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: bookingTicketId,
                        userId: userId,
                    },
                })
                .then(({ data }) => data?.cancelBookingTicket)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!cancelledBookingTicket) {
                return {
                    IsSuccess: false,
                    Message:
                        "Das Buchungsticket konnte nicht storniert werden.",
                };
            }
            return cancelledBookingTicket;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}

module.exports = BookingTicketsComponent;
