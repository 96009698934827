import gql from "graphql-tag";

export const Login = {
    Mutations: {
        Login: gql`
        mutation Login($email: String!, $password: String!) {
            Login(email: $email, password: $password) {
                Authenticated
                Token
                Message
                HostIds
                UserId
                Permissions {
                    Id
                    Description
                }
            }
        }`,
        LoginDashboardUser: gql`
        mutation loginDashboardUser($email: String, $password: String, $guid: String, $userGuid: String) {
            loginDashboardUser(email: $email, password: $password, guid: $guid, userGuid: $userGuid) {
                Authenticated
                Token
                Message
                HostIds
                UserId
            }
        }`,
    },
};
