import gql from "graphql-tag";

export const SearchCriteriaAreas = {
    Queries: {
        SearchCriteriaAreas: gql`
        query searchCriteriaAreas($isActive: Boolean) {
            searchCriteriaAreas(isActive: $isActive) {
                Id
                Description
                Image
                SortNumber
                IsActive
            }
        }`,
    },
};
