<template lang="pug">
.container-fluid
  .row(v-if="notification")
    .col-12
      h2 {{$t("products.detail.headline")}}
    .col-12
      .form-group
        label(for="Status").form-label Titel
        input#Status.form-control(type="text", disabled="true", :value="notification.Title")
    .col-12
      .form-group
        label.form-label Nachricht
        textarea-autosize.form-control(v-model="notification.Message" disabled)
    .col-6
      .float-left(v-if="notification.RedirectUrl")
        a.button.button-primary.button-tdays(:href="notification.RedirectUrl" tag="button" targer="_blank")
          | Zur Aktion
    .col-6
      .float-right
        router-link.button.button-primary.button-tdays(to="/account/notifications" tag="button")
          | {{$t("constants.buttons.back")}}

</template>

<script>
import EventBus from "../../../event-bus";
import { Notifications } from "@/lib/graphql/notifications/Notifications.ts";

export default {
    name: "NotificationDetails",
    props: {
        notificationId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            notification: undefined,
        };
    },
    async mounted() {
        try {
            this.loadNotification();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadNotification() {
            try {
                const notification = await this.$apollo
                    .query({
                        query: Notifications.Queries.Notification,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.notificationId,
                        },
                    })
                    .then(({ data }) => data?.notification)
                    .catch((e) => {
                        console.error(e);
                        this.$alert(e.message);
                    });
                if (!notification) {
                    return this.$alert(
                        "Es konnte keine Benachrichtigung geladen werden."
                    );
                }
                this.notification = notification;
                if (notification.IsSeen) {
                    return;
                }
                this.notification.IsSeen = true;
                this.notification.SeenBy = this.$session.get("userId");
                this.notification.SeenAt = new Date();
                const savedNotification = await this.$apollo
                    .mutate({
                        mutation: Notifications.Mutations.SaveNotification,
                        variables: {
                            notification: this.notification,
                        },
                    })
                    .then(({ data }) => data?.saveNotification)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!savedNotification) {
                    return this.$alert(
                        "Die Benachrichtigung konnte nicht als gelesen gespeichert werden."
                    );
                }
                if (savedNotification.IsSuccess) {
                    EventBus.$emit("refreshUnseenNotifications");
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
