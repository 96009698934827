<template lang="pug">
  .wrapper(style="padding: 0rem")
    p.m-0(v-for="dateRequest in dateRequests")
      span {{ dateRequest.Date | formatDateWithDay($t("lang")) }} - {{ moment(dateRequest.Date).add(nights, "days")  | formatDateWithDay($t("lang")) }}
      br(v-if="dateRequests.indexOf(dateRequest) < dateRequests.length - 1")
</template>

<script>
import moment from "moment";

export default {
    name: "date-request-template",
    props: {
        dateRequests: {
            type: Array,
            required: true,
        },
        nights: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            moment: moment,
        };
    },
};
</script>
