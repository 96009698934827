import { Vouchers } from "@/lib/graphql/vouchers/Vouchers.ts";

export class VouchersComponent {
    async saveVoucher(apollo, voucher, bookingRequest) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: `Kein Apollo-Client gefunden.`,
                };
            }
            if (!voucher) {
                return {
                    IsSuccess: false,
                    Message: `Kein Gutschein gefunden.`,
                };
            }
            const savedVoucher = await apollo
                .mutate({
                    mutation: Vouchers.Mutations.SaveVoucher,
                    variables: {
                        voucher: voucher,
                        bookingRequest: bookingRequest,
                    },
                })
                .then(({ data }) => data?.saveVoucher)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!savedVoucher) {
                return {
                    IsSuccess: false,
                    Message: `Der Gutschein konnte nicht gespeichert werden.`,
                };
            }
            return savedVoucher;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getVoucher(apollo, id, voucherId) {
        try {
            if (!apollo || (!id && !voucherId)) {
                return undefined;
            }
            const voucher = await apollo
                .query({
                    query: Vouchers.Queries.Voucher,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: id,
                        voucherId: voucherId,
                    },
                })
                .then(({ data }) => data?.voucher)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return voucher;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getVouchers(
        apollo,
        hostId,
        productId,
        bookingRequestId,
        voucherStatus,
        filter,
        offset,
        limit
    ) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostId && !productId && !bookingRequestId) {
                return undefined;
            }
            const vouchers = await apollo
                .query({
                    query: Vouchers.Queries.Vouchers,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostId: hostId,
                        productId: productId,
                        bookingRequestId: bookingRequestId,
                        voucherStatus: voucherStatus,
                        filter: filter,
                        offset: offset,
                        limit: limit,
                    },
                })
                .then(({ data }) => data?.vouchers)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!vouchers) {
                return undefined;
            }
            return vouchers;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostsVouchers(
        apollo,
        hostIds,
        filter,
        voucherStatusIds,
        from,
        to,
        offset,
        limit,
        sort
    ) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostIds) {
                return undefined;
            }
            const vouchers = await apollo
                .query({
                    query: Vouchers.Queries.HostsVouchers,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostIds: hostIds,
                        filter: filter,
                        voucherStatus: voucherStatusIds,
                        from: from,
                        to: to,
                        offset: offset,
                        limit: limit,
                        sort: sort,
                    },
                })
                .then(({ data }) => data?.hostsVouchers)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!vouchers) {
                return undefined;
            }
            return vouchers;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostsVouchersCount(
        apollo,
        hostIds,
        filter,
        voucherStatusIds,
        from,
        to
    ) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostIds) {
                return undefined;
            }
            const vouchersCount = await apollo
                .query({
                    query: Vouchers.Queries.HostsVouchersCount,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostIds: hostIds,
                        filter: filter,
                        voucherStatus: voucherStatusIds,
                        from: from,
                        to: to,
                    },
                })
                .then(({ data }) => data?.hostsVouchersCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (vouchersCount == undefined) {
                return undefined;
            }
            return vouchersCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostVouchersTable(
        apollo,
        hostIds,
        filter,
        voucherStatus,
        from,
        to,
        offset,
        limit,
        sort
    ) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostIds) {
                return undefined;
            }
            const hostsVouchersHtml = await apollo
                .query({
                    query: Vouchers.Queries.HostsVouchersHtml,
                    fetchPolicy: "no-fetch",
                    variables: {
                        hostIds: hostIds,
                        filter: filter,
                        voucherStatus: voucherStatus,
                        sort: sort,
                        from: from,
                        to: to,
                        offset: offset,
                        limit: limit,
                        includeTableHeader: offset == 0,
                    },
                })
                .then(({ data }) => data?.hostsVouchersHtml)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!hostsVouchersHtml) {
                return undefined;
            }
            return hostsVouchersHtml;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    // @ts-ignore
    createElementFromHTML(htmlString) {
        try {
            const div = document.createElement("div");
            div.innerHTML = htmlString.trim();
            // Change this to div.childNodes to support multiple top-level nodes.
            return div.firstChild;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
