<template lang="pug">
router-link(:to="`/account/notifications/detail/${Notification.Id}`" v-if="Notification.IsSeen")
  .single-notification {{Notification.Title}}
  span {{formatDate(Notification.CreatedAt)}}

router-link.unread(:to="`/account/notifications/detail/${Notification.Id}`" v-else)
  .single-notification {{Notification.Title}}
  span {{formatDate(Notification.CreatedAt)}}

</template>

<script>
import moment from "moment";

export default {
    name: "notification-panel",
    props: {
        Notification: {
            type: Object,
            allowNull: false,
        },
    },
    methods: {
        formatDate: function (date) {
            if (date) {
                return moment(date).format("DD.MM.YYYY HH:mm:ss");
            }
        },
    },
};
</script>

<style scoped></style>
