<template lang="pug">
  tr
    td
      b(v-if="host") {{ host.Name }}
      b(v-else-if="product") {{ product.Id }}: {{ product.Uebernachtungen }} ÜN / {{ product.Verpflegung }}
    td
      b {{ totalSalesCount }} / {{ totalSales | formatEuro }}
    td
      b {{ regularSalesCount }} / {{ regularSales | formatEuro }}
    td
      b {{ dealSalesCount }} / {{ dealSales | formatEuro }}
    td
      b {{ cancelledSalesCount }} / {{ cancelledSales | formatEuro }}

</template>

<script>
import { Hosts } from "@/lib/graphql/hosts/Hosts.ts";
import { Products } from "@/lib/graphql/products/Products.ts";
import { OrderStatusEnum } from "@/lib/enums/orderStatus/OrderStatusEnum.ts";
import { ItemsComponent } from "@/lib/components/items/ItemsComponent";

export default {
    name: "SalesEvaluationsTableHeadRow",
    props: {
        id: {
            type: String,
            required: true,
        },
        isHost: {
            type: Boolean,
            required: true,
        },
        from: {
            type: Date,
            required: true,
        },
        to: {
            type: Date,
            required: true,
        },
    },
    data() {
        return {
            host: undefined,
            product: undefined,
            items: [],
            cancelledItems: [],
        };
    },
    async mounted() {
        try {
            if (this.isHost) {
                const host = await this.getHost(this.id);
                if (!host) {
                    return this.$alert(
                        `Es konnte kein Gastgeber für ${this.id} geladen werden.`
                    );
                }
                this.host = host;
            } else {
                const product = await this.getProduct(this.id);
                if (!product) {
                    return this.$alert(
                        `Es konnte kein Produkt für ${this.id} geladen werden.`
                    );
                }
                this.product = product;
            }
            const itemsComponent = new ItemsComponent();
            const items = await itemsComponent.getItems(
                this.$apollo,
                this.product ? this.product.Id : undefined,
                this.host ? this.host.Id : undefined,
                undefined,
                undefined,
                this.from,
                this.to,
                undefined,
                undefined,
                undefined,
                undefined,
                [
                    OrderStatusEnum.Received,
                    OrderStatusEnum.Paid,
                    OrderStatusEnum.Completed,
                ]
            );
            if (!items) {
                return this.$alert(
                    `Es konnten keine Verkäufe für ${
                        this.product ? "Produkt" : ""
                    }${this.host ? "Gastgeber" : ""} ${
                        this.product ? this.product.Id : ""
                    }${this.host ? this.host.Id : ""} geladen werden.`
                );
            }
            this.items = items;
            const cancelledItems = await itemsComponent.getItems(
                this.$apollo,
                this.product ? this.product.Id : undefined,
                this.host ? this.host.Id : undefined,
                undefined,
                undefined,
                this.from,
                this.to,
                undefined,
                undefined,
                undefined,
                undefined,
                [OrderStatusEnum.Cancelled]
            );
            if (!cancelledItems) {
                return this.$alert(
                    `Es konnten keine stornierten Verkäufe für ${
                        this.product ? "Produkt" : ""
                    }${this.host ? "Gastgeber" : ""} ${
                        this.product ? this.product.Id : ""
                    }${this.host ? this.host.Id : ""} geladen werden.`
                );
            }
            this.cancelledItems = cancelledItems;
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async getHost(id) {
            try {
                const host = await this.$apollo
                    .query({
                        query: Hosts.Queries.Host,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: id,
                        },
                    })
                    .then(({ data }) => data?.host)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!host) {
                    return undefined;
                }
                return host;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        async getProduct(id) {
            try {
                const product = await this.$apollo
                    .query({
                        query: Products.Queries.Product,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: id,
                        },
                    })
                    .then(({ data }) => data?.product)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!product) {
                    return undefined;
                }
                return product;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
    computed: {
        totalSalesCount() {
            try {
                if (!this.items) {
                    return undefined;
                }
                let result = 0;
                this.items.forEach((i) => (result += i.Quantity));
                return result;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        totalSales() {
            try {
                if (!this.items) {
                    return undefined;
                }
                let result = 0;
                this.items.forEach(
                    (i) =>
                        (result +=
                            i.Quantity *
                            (i.SingleItemPriceGross - i.InsuranceAmount))
                );
                return result;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        regularSalesCount() {
            try {
                if (!this.items) {
                    return undefined;
                }
                let items = this.items;
                if (!items) {
                    return undefined;
                }
                items = items.filter((i) => !i.IsDealSale);
                if (!items) {
                    return undefined;
                }
                let result = 0;
                items.forEach((i) => (result += i.Quantity));
                return result;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        regularSales() {
            try {
                if (!this.items) {
                    return undefined;
                }
                let items = this.items;
                if (!items) {
                    return undefined;
                }
                items = items.filter((i) => !i.IsDealSale);
                if (!items) {
                    return undefined;
                }
                let result = 0;
                items.forEach(
                    (i) =>
                        (result +=
                            i.Quantity *
                            (i.SingleItemPriceGross - i.InsuranceAmount))
                );
                return result;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        dealSalesCount() {
            try {
                if (!this.items) {
                    return undefined;
                }
                let items = this.items;
                if (!items) {
                    return undefined;
                }
                items = items.filter((i) => i.IsDealSale);
                if (!items) {
                    return undefined;
                }
                let result = 0;
                items.forEach((i) => (result += i.Quantity));
                return result;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        dealSales() {
            try {
                if (!this.items) {
                    return undefined;
                }
                let items = this.items;
                if (!items) {
                    return undefined;
                }
                items = items.filter((i) => i.IsDealSale);
                if (!items) {
                    return undefined;
                }
                let result = 0;
                items.forEach(
                    (i) =>
                        (result +=
                            i.Quantity *
                            (i.SingleItemPriceGross - i.InsuranceAmount))
                );
                return result;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        cancelledSalesCount() {
            try {
                if (!this.items) {
                    return undefined;
                }
                let result = 0;
                this.cancelledItems.forEach((i) => (result += i.Quantity));
                return result;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        cancelledSales() {
            try {
                if (!this.items) {
                    return undefined;
                }
                let result = 0;
                this.cancelledItems.forEach(
                    (i) =>
                        (result +=
                            i.Quantity *
                            (i.SingleItemPriceGross - i.InsuranceAmount))
                );
                return result;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
};
</script>

<style scoped></style>
