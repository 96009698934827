<template lang="pug">
  .input-group.input-group-sm.choose-host-dropdown(v-if="availableHosts.length > 1")
    multiselect.form-control(
      :options="availableHosts"
      select-label="Zum Auswählen klicken",
      selected-label="Ausgewählt",
      deselect-label="Zum Entfernen klicken",
      v-model="selectedHost",
      :searchable="true",
      :multiple="false",
      :close-on-select="true",
      :show-labels="true"
      label="DisplayName",
      track-by="Id",
      :placeholder="this.$t('partials.hostSelectPlaceholder')"
    )
    .input-group-append
      button.button.button-primary.button-tdays(@click="changeHostId" :disabled="selectedHost.Id == hostId" :class="{ 'disabled': selectedHost.Id == hostId }") {{$t("partials.hostSelectSwitchButton")}}
</template>

<script>
import EventBus from "../../../views/event-bus";
import { HostsComponent } from "@/lib/components/hosts/HostsComponent";

export default {
    name: "host-select",
    data() {
        return {
            availableHosts: [],
            selectedHost: [],
            hostId: this.$session.get("hostId"),
        };
    },
    async mounted() {
        try {
            this.loadAvailableHosts();
            EventBus.$on(
                "changeHostId",
                function () {
                    this.hostId = this.$session.get("hostId");
                }.bind(this)
            );
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadAvailableHosts() {
            try {
                const hostsComponent = new HostsComponent();
                const availableHosts = await hostsComponent.getAvailableHosts(
                    this.$apollo,
                    this.$session.get("userId")
                );
                if (!availableHosts) {
                    return this.$alert(
                        "Es konnten keine Gastgeber geladen werden."
                    );
                }
                const hostIds = [];
                for (const host of availableHosts) {
                    hostIds.push(host.Id);
                    host.DisplayName = `${host.Id} | ${host.Match1}`;
                }
                this.$session.set("hostIds", hostIds);
                this.availableHosts = availableHosts;

                if (!this.$session.get("hostId")) {
                    this.selectedHost = availableHosts[0];
                    this.changeHostId();
                } else {
                    this.selectedHost = availableHosts.find(
                        (h) => h.Id == this.$session.get("hostId")
                    );
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        changeHostId() {
            this.$session.set("hostId", this.selectedHost.Id);
            EventBus.$emit("changeHostId", this.selectedHost.Id);
        },
    },
};
</script>
