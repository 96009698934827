import gql from "graphql-tag";

export const GetHostSettlementsQuery = gql`
    query GetSettlements($host: ID, $offset: Int, $limit: Int) {
        settlementsForHost(hostId: $host, offset: $offset, limit: $limit) {
            ID
            Vorgangdatum
            Sum
            PDFPfad
            System
        }
    }
`;
