import gql from "graphql-tag";

export const Images = {
    Mutations: {
        UploadImages: gql`
        mutation uploadImages($hostId: ID, $files: [FilesInput]) {
            uploadImages(hostId: $hostId, files: $files) {
                IsSuccess
                Message
            }
        }`,
    },
};
