module.exports = () => {
    switch (process.env.VUE_APP_MODE) {
        default:
            return {
                server: {
                    host: "localhost",
                    port: 5000,
                },
                graphQl: {
                    uri: "http://localhost:5225/graphql",
                },
                sentry: {
                    tracesSampleRate: 1.0,
                },
            };
        case "staging":
            return {
                server: {
                    host: "0.0.0.0",
                    port: 5000,
                },
                graphQl: {
                    uri: "https://staging-apollo-server.hub.touri.dev/graphql",
                },
                sentry: {
                    tracesSampleRate: 1.0,
                },
            };
        case "debug":
            return {
                server: {
                    host: "localhost",
                    port: 5000,
                },
                graphQl: {
                    uri: "http://localhost:5225/graphql",
                },
                sentry: {
                    tracesSampleRate: 1.0,
                },
            };
        case "production":
            return {
                server: {
                    host: "0.0.0.0",
                    port: 5000,
                },
                graphQl: {
                    uri: "https://graphql-apollo.htzn-cap-01.touri.dev/graphql",
                },
                sentry: {
                    tracesSampleRate: 0.5,
                },
            };
    }
};
