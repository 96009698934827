<template lang="pug">
  .row
    datasheet-search-criteria-area(
      :search-criteria-area="searchCriteriaArea"
      :search-criterias="searchCriterias"
      :host-search-criteria-assignment="hostSearchCriteriaAssignment"
      :add-search-criteria-assignment="addSearchCriteriaAssignment"
      :delete-search-criteria-assignment="deleteSearchCriteriaAssignment"
      v-for="searchCriteriaArea in searchCriteriaAreas"
    )

    .col-12
      .float-right
        button.button.button-primary.button-tdays(@click="saveHostSearchCriteriaAssignment")
          | {{$t("constants.buttons.save")}}

</template>

<script>
import { SearchCriteriaAreas } from "@/lib/graphql/searchCriteriaAreas/SearchCriteriaAreas.ts";
import DatasheetSearchCriteriaArea from "@/views/pages/account/datasheet/datasheet-search-criteria-area.vue";
import EventBus from "@/views/event-bus";
import { SearchCriterias } from "@/lib/graphql/searchCriterias/SearchCriterias.ts";
import { HostSearchCriteriaAssignmentStatusEnum } from "@/lib/enums/hostSearchCriteriaAssignmentStatus/HostSearchCriteriaAssignmentStatusEnum.ts";
import { HostSearchCriteriaAssignments } from "@/lib/graphql/hostSearchCriteriaAssignments/HostSearchCriteriaAssignments.ts";
import { SearchCriteriaAssignments } from "@/lib/graphql/searchCriteriaAssignments/SearchCriteriaAssignments.ts";
import { NotificationTypesEnum } from "@/lib/enums/notificationTypes/NotificationTypesEnum.ts";
import { Notifications } from "@/lib/graphql/notifications/Notifications.ts";
import { UserGroups } from "@/lib/graphql/userGroups/UserGroups.ts";

export default {
    name: "datasheet-search-criteria-areas",
    components: { DatasheetSearchCriteriaArea },
    props: {
        hostId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            searchCriteriaAreas: [],
            searchCriterias: [],
            hostSearchCriteriaAssignment: undefined,
            searchCriteriaAssignments: [],
        };
    },
    async mounted() {
        try {
            await this.loadSearchCriteriaAreas();
            await this.loadSearchCriterias();
            await this.loadHostSearchCriteriaAssignments();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    watch: {
        async hostId() {
            try {
                await this.loadSearchCriteriaAreas();
                await this.loadSearchCriterias();
                await this.loadHostSearchCriteriaAssignments();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    methods: {
        async loadHostSearchCriteriaAssignments() {
            try {
                const hostSearchCriteriaAssignments = await this.$apollo
                    .query({
                        query: HostSearchCriteriaAssignments.Queries
                            .HostSearchCriteriaAssignments,
                        fetchPolicy: "no-cache",
                        variables: {
                            hostId: this.hostId,
                            hostSearchCriteriaAssignmentStatusIds: [
                                HostSearchCriteriaAssignmentStatusEnum.Accepted,
                            ],
                        },
                    })
                    .then(({ data }) => data?.hostSearchCriteriaAssignments)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!hostSearchCriteriaAssignments) {
                    return;
                }
                if (hostSearchCriteriaAssignments.length == 0) {
                    return;
                }
                this.hostSearchCriteriaAssignment =
                    hostSearchCriteriaAssignments[
                        hostSearchCriteriaAssignments.length - 1
                    ];
                await this.loadSearchCriteriaAssignments();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadSearchCriteriaAssignments() {
            try {
                const searchCriteriaAssignments = await this.$apollo
                    .query({
                        query: SearchCriteriaAssignments.Queries
                            .SearchCriteriaAssignments,
                        fetchPolicy: "no-cache",
                        variables: {
                            hostSearchCriteriaAssignmentId:
                                this.hostSearchCriteriaAssignment?.Id,
                        },
                    })
                    .then(({ data }) => data?.searchCriteriaAssignments)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!searchCriteriaAssignments) {
                    return;
                }
                this.searchCriteriaAssignments = searchCriteriaAssignments;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadSearchCriteriaAreas() {
            try {
                this.searchCriteriaAreas = [];
                EventBus.$emit("changeLoadingState", true);
                const searchCriteriaAreas = await this.$apollo
                    .query({
                        query: SearchCriteriaAreas.Queries.SearchCriteriaAreas,
                        fetchPolicy: "no-cache",
                        variables: {
                            isActive: true,
                        },
                    })
                    .then(({ data }) => data?.searchCriteriaAreas)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                EventBus.$emit("changeLoadingState", false);
                if (!searchCriteriaAreas) {
                    return this.$alert(
                        "Es konnten keine Suchkriterien geladen werden."
                    );
                }
                this.searchCriteriaAreas = searchCriteriaAreas;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadSearchCriterias() {
            try {
                this.searchCriterias = [];
                const searchCriterias = await this.$apollo
                    .query({
                        query: SearchCriterias.Queries.SearchCriterias,
                        fetchPolicy: "no-cache",
                        variables: {
                            isActive: true,
                        },
                    })
                    .then(({ data }) => data?.searchCriterias)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!searchCriterias) {
                    return;
                }
                this.searchCriterias = searchCriterias;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async saveHostSearchCriteriaAssignment() {
            try {
                const confirmed = await this.$confirm(
                    "Sind Sie sicher, dass Sie das Datenblatt an den touriDat-Service zur Prüfung übermitteln möchten?"
                );
                if (!confirmed) {
                    return;
                }
                const hostSearchCriteriaAssignment = {
                    HostId: this.hostId,
                    HostSearchCriteriaAssignmentStatusId:
                        HostSearchCriteriaAssignmentStatusEnum.Draft,
                    CreatedBy: this.$session.get("userId"),
                };
                const createdHostSearchCriteriaAssignment = await this.$apollo
                    .mutate({
                        mutation:
                            HostSearchCriteriaAssignments.Mutations
                                .CreateHostSearchCriteriaAssignment,
                        fetchPolicy: "no-cache",
                        variables: {
                            hostSearchCriteriaAssignment:
                                hostSearchCriteriaAssignment,
                        },
                    })
                    .then(
                        ({ data }) => data?.createHostSearchCriteriaAssignment
                    )
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!createdHostSearchCriteriaAssignment) {
                    return this.$alert(
                        "Das Datenblatt konnte nicht gespeichert werden."
                    );
                }
                if (!createdHostSearchCriteriaAssignment.IsSuccess) {
                    return this.$alert(
                        createdHostSearchCriteriaAssignment.Message
                    );
                }
                for (const searchCriteriaAssignment of this
                    .searchCriteriaAssignments) {
                    delete searchCriteriaAssignment.Id;
                    searchCriteriaAssignment.HostSearchCriteriaAssignmentId =
                        createdHostSearchCriteriaAssignment.StringData;
                    const createdSearchCriteriaAssignment = await this.$apollo
                        .mutate({
                            mutation:
                                SearchCriteriaAssignments.Mutations
                                    .CreateSearchCriteriaAssignment,
                            fetchPolicy: "no-cache",
                            variables: {
                                searchCriteriaAssignment:
                                    searchCriteriaAssignment,
                            },
                        })
                        .then(
                            ({ data }) => data?.createSearchCriteriaAssignment
                        )
                        .catch((e) => {
                            console.error(e);
                            return undefined;
                        });
                    if (!createdSearchCriteriaAssignment) {
                        return this.$alert(
                            "Suchkriterium konnte nicht gespeichert werden."
                        );
                    }
                    if (!createdSearchCriteriaAssignment.IsSuccess) {
                        return this.$alert(
                            createdSearchCriteriaAssignment.Message
                        );
                    }
                }
                const userGroup = await this.$apollo
                    .query({
                        query: UserGroups.Queries.UserGroupByIndicator,
                        fetchPolicy: "no-cache",
                        variables: {
                            indicator: "büroleitung",
                        },
                    })
                    .then(({ data }) => data?.userGroupByIndicator)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!userGroup) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(
                        "Keine zu addressierenden touriDat-Benutzer gefunden."
                    );
                }
                const notification = {
                    Title: "Neue Datenblattanfrage",
                    UserGroupId: userGroup.Id,
                    HostId: this.hostId,
                    CreatedBy: this.$session.get("userId"),
                    NotificationTypeId: NotificationTypesEnum.ProductManager,
                };
                const savedNotification = await this.$apollo
                    .mutate({
                        mutation: Notifications.Mutations.SaveNotification,
                        fetchPolicy: "no-cache",
                        variables: {
                            notification: notification,
                        },
                    })
                    .then(({ data }) => data?.saveNotification)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!savedNotification) {
                    return this.$alert(
                        "Die Benachrichtigung für das touriDat-Service Team konnte nicht erstellt werden."
                    );
                }
                this.$alert(
                    "Das Datenblatt wurde erfolgreich an den touriDat-Service übermittelt und wartet nun auf die Freigabe."
                );
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        addSearchCriteriaAssignment(searchCriteriaAssignment) {
            try {
                const _searchCriteriaAssignment =
                    this.searchCriteriaAssignments.find(
                        (h) =>
                            h.SearchCriteriaId ==
                            searchCriteriaAssignment.SearchCriteriaId
                    );
                if (!_searchCriteriaAssignment) {
                    this.searchCriteriaAssignments.push(
                        searchCriteriaAssignment
                    );
                } else {
                    this.searchCriteriaAssignments[
                        this.searchCriteriaAssignments.indexOf(
                            _searchCriteriaAssignment
                        )
                    ] = searchCriteriaAssignment;
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        deleteSearchCriteriaAssignment(searchCriteriaAssignment) {
            try {
                const _searchCriteriaAssignment =
                    this.searchCriteriaAssignments.find(
                        (h) =>
                            h.SearchCriteriaId ==
                            searchCriteriaAssignment.SearchCriteriaId
                    );
                if (!_searchCriteriaAssignment) {
                    return;
                }
                this.searchCriteriaAssignments.splice(
                    this.searchCriteriaAssignments.indexOf(
                        _searchCriteriaAssignment
                    ),
                    1
                );
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>
