<template lang="pug">
  tr(:class="{ 'd-none': !isVisible }")
    td(colspan=10)
      b-collapse(:id="option.id" v-model="isVisible")
        .table-responsive(v-if="isVisible")
          table.table.table-bordered
            tbody
              sales-evaluations-table-sub-row(:date="date" :display-unit="displayUnit" :id="option.id" :is-host="!!option.isHost" v-for="date in dates")

</template>

<script>
import moment from "moment";
import SalesEvaluationsTableSubRow from "@/views/pages/sales/SalesEvaluationsTableSubRow.vue";

export default {
    name: "SalesEvaluationsTableSubTable",
    components: { SalesEvaluationsTableSubRow },
    props: {
        option: {
            type: Object,
            required: true,
        },
        dates: {
            type: Array,
            required: true,
        },
        displayUnit: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            orders: [],

            isVisible: false,
            moment: moment,
        };
    },
};
</script>

<style scoped></style>
