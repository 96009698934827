<template lang="pug">
  .wrapper
    tD-loading-overlay(v-if="$apollo.loading")
    .row
      .col-12.col-sm-6.col-lg-8
        h3 {{$t("account.productContracts.headline")}}
      .col-12.col-sm-6.col-lg-4
        .float-right
          a.button.button-primary.button-tdays(href="https://td-cloud.s3.eu-central-1.amazonaws.com/Auftrags-AGB-07-2021.pdf" target="_blank")
            | Agb als PDF

      .col-12
        .table-responsive
          table.table.table-bordered
            thead
              tr
                th(scope="col") {{$t("account.productContracts.table.productContractNumber")}}
                th(scope="col") {{$t("account.productContracts.table.sentAt")}}
                th(scope="col") {{$t("account.productContracts.table.status")}}
                th(scope="col") {{$t("account.productContracts.table.confirmedAt")}}
                th(scope="col") {{$t("account.productContracts.table.editor")}}
                th(scope="col")
            tbody
              tr(v-for="productContract in productContracts")
                td {{ productContract.ProductContractNumber }}
                td {{ productContract.SentAt | formatDateTime }}
                td
                  span(v-if="productContract.ProductContractStatusId == ProductContractStatusEnum.Draft") Entwurf
                  span(v-else-if="productContract.ProductContractStatusId == ProductContractStatusEnum.Sent") versendet
                  span(v-else-if="productContract.ProductContractStatusId == ProductContractStatusEnum.Confirmed") angenommen
                  span(v-else-if="productContract.ProductContractStatusId == ProductContractStatusEnum.Declined") abgelehnt
                  span(v-else-if="productContract.ProductContractStatusId == ProductContractStatusEnum.Revoked") zurückgezogen
                td {{ productContract.ConfirmedAt | formatDateTime }}
                td {{ productContract.Editor }}
                td
                  .float-right
                    router-link.button.button-primary.button-tdays(:to="{ name: 'ProductContractEditor', params: { productContractId: productContract.Id } }" v-if="productContract.ProductContractStatusId == ProductContractStatusEnum.Sent") Vertrag verwalten
                    button.button.button-primary.button-tdays(@click="openSignedObjectUrl(productContract.PdfFilePath.replace('https://td-hosts.s3.eu-central-1.amazonaws.com/', '').replace('https://td-cloud.s3.eu-central-1.amazonaws.com/', ''))" v-else) {{$t("account.documents.table.showPdf")}}

      template(v-if="productRestockContractsCount")
        .col-12
          h3 Aufstockungsverträge
        .col-12
          .table-responsive
            table.table.table-bordered
              thead
                tr
                  th(scope="col") {{$t("account.productContracts.table.productContractNumber")}}
                  th(scope="col") {{$t("account.productContracts.table.sentAt")}}
                  th(scope="col") {{$t("account.productContracts.table.status")}}
                  th(scope="col") {{$t("account.productContracts.table.confirmedAt")}}
                  th(scope="col") {{$t("account.productContracts.table.editor")}}
                  th(scope="col")
              tbody
                tr(v-for="productRestockContract in productRestockContracts")
                  td {{ productRestockContract.ProductContractNumber }}
                  td {{ productRestockContract.SentAt | formatDateTime }}
                  td
                    span(v-if="productRestockContract.ProductContractStatusId == ProductContractStatusEnum.Draft") Entwurf
                    span(v-else-if="productRestockContract.ProductContractStatusId == ProductContractStatusEnum.Sent") versendet
                    span(v-else-if="productRestockContract.ProductContractStatusId == ProductContractStatusEnum.Confirmed") angenommen
                    span(v-else-if="productRestockContract.ProductContractStatusId == ProductContractStatusEnum.Declined") abgelehnt
                    span(v-else-if="productRestockContract.ProductContractStatusId == ProductContractStatusEnum.Revoked") zurückgezogen
                  td {{ productRestockContract.ConfirmedAt | formatDateTime }}
                  td {{ productRestockContract.Editor }}
                  td
                    .float-right
                      router-link.button.button-primary.button-tdays(:to="{ name: 'ProductContractEditor', params: { productContractId: productRestockContract.Id } }" v-if="productRestockContract.ProductContractStatusId == ProductContractStatusEnum.Sent") Vertrag verwalten
                      button.button.button-primary.button-tdays(@click="openSignedObjectUrl(productRestockContract.PdfFilePath.replace('https://td-hosts.s3.eu-central-1.amazonaws.com/', '').replace('https://td-cloud.s3.eu-central-1.amazonaws.com/', ''))") {{$t("account.documents.table.showPdf")}}

      template(v-if="productAmendmentContractsCount")
        .col-12
          h3 Änderungsverträge
        .col-12
          .table-responsive
            table.table.table-bordered
              thead
                tr
                  th(scope="col") {{$t("account.productContracts.table.productContractNumber")}}
                  th(scope="col") {{$t("account.productContracts.table.sentAt")}}
                  th(scope="col") {{$t("account.productContracts.table.status")}}
                  th(scope="col") {{$t("account.productContracts.table.confirmedAt")}}
                  th(scope="col") {{$t("account.productContracts.table.editor")}}
                  th(scope="col")
              tbody
                tr(v-for="productAmendmentContract in productAmendmentContracts")
                  td {{ productAmendmentContract.ProductContractNumber }}
                  td {{ productAmendmentContract.SentAt | formatDateTime }}
                  td
                    span(v-if="productAmendmentContract.ProductContractStatusId == ProductContractStatusEnum.Draft") Entwurf
                    span(v-else-if="productAmendmentContract.ProductContractStatusId == ProductContractStatusEnum.Sent") versendet
                    span(v-else-if="productAmendmentContract.ProductContractStatusId == ProductContractStatusEnum.Confirmed") angenommen
                    span(v-else-if="productAmendmentContract.ProductContractStatusId == ProductContractStatusEnum.Declined") abgelehnt
                    span(v-else-if="productAmendmentContract.ProductContractStatusId == ProductContractStatusEnum.Revoked") zurückgezogen
                  td {{ productAmendmentContract.ConfirmedAt | formatDateTime }}
                  td {{ productAmendmentContract.Editor }}
                  td
                    .float-right
                      router-link.button.button-primary.button-tdays(:to="{ name: 'ProductContractEditor', params: { productContractId: productAmendmentContract.Id } }" v-if="productAmendmentContract.ProductContractStatusId == ProductContractStatusEnum.Sent") Vertrag verwalten
                      button.button.button-primary.button-tdays(@click="openSignedObjectUrl(productAmendmentContract.PdfFilePath.replace('https://td-hosts.s3.eu-central-1.amazonaws.com/', '').replace('https://td-cloud.s3.eu-central-1.amazonaws.com/', ''))") {{$t("account.documents.table.showPdf")}}

</template>

<script>
import EventBus from "../../../views/event-bus";
import moment from "moment";
import ProductContractModal from "../../modals/productContract/product-contract-modal.vue";
import { ProductContractStatusEnum } from "@/lib/enums/productContractStatus/ProductContractStatusEnum";
import AwsS3Component from "@/lib/components/awsS3/AwsS3Component";
import { ProductContractsComponent } from "@/lib/components/productContracts/ProductContractsComponent";
import { ProductContractTypesEnum } from "@/lib/enums/productContractTypes/ProductContractTypesEnum.ts";

export default {
    name: "account-product-contracts",
    data() {
        return {
            productContracts: [],
            productContractsCount: 0,
            productRestockContracts: [],
            productRestockContractsCount: 0,
            productAmendmentContracts: [],
            productAmendmentContractsCount: 0,

            hostId: parseInt(this.$session.get("hostId")),
            moment: moment,
            ProductContractStatusEnum: ProductContractStatusEnum,
        };
    },
    components: {
        ProductContractModal,
    },
    mounted() {
        try {
            EventBus.$on(
                "updateProductContracts",
                function () {
                    this.loadProductContracts();
                    this.loadProductContractsCount();
                    this.loadProductRestockContracts();
                    this.loadProductRestockContractsCount();
                    this.loadProductAmendmentContracts();
                    this.loadProductAmendmentContractsCount();
                }.bind(this)
            );
            EventBus.$on(
                "changeHostId",
                function (hostId) {
                    this.hostId = hostId;
                }.bind(this)
            );
            this.loadProductContracts();
            this.loadProductContractsCount();
            this.loadProductRestockContracts();
            this.loadProductRestockContractsCount();
            this.loadProductAmendmentContracts();
            this.loadProductAmendmentContractsCount();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async openSignedObjectUrl(key) {
            try {
                if (!key) {
                    return this.$alert(
                        "Kein Key zum generieren der Url gefunden."
                    );
                }
                const awsS3Component = new AwsS3Component();
                EventBus.$emit("changeLoadingState", true);
                const signedObjectUrl = await awsS3Component.getSignedObjectUrl(
                    this.$apollo,
                    key
                );
                EventBus.$emit("changeLoadingState", false);
                if (!signedObjectUrl) {
                    return this.$alert("Es konnte keine Url generiert werden.");
                }
                window.open(signedObjectUrl, "_blank").focus();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProductContracts() {
            try {
                const productContracts = await this.getProductContracts(
                    [ProductContractTypesEnum.Contract],
                    [
                        ProductContractStatusEnum.Sent,
                        ProductContractStatusEnum.Confirmed,
                    ]
                );
                if (!productContracts) {
                    return;
                }
                this.productContracts = productContracts;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProductContractsCount() {
            try {
                const productContractsCount =
                    await this.getProductContractsCount(
                        [ProductContractTypesEnum.Contract],
                        [
                            ProductContractStatusEnum.Sent,
                            ProductContractStatusEnum.Confirmed,
                        ]
                    );
                if (productContractsCount == undefined) {
                    return;
                }
                this.productContractsCount = productContractsCount;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProductRestockContracts() {
            try {
                const productContracts = await this.getProductContracts(
                    [ProductContractTypesEnum.Restock],
                    [
                        ProductContractStatusEnum.Sent,
                        ProductContractStatusEnum.Confirmed,
                    ]
                );
                if (!productContracts) {
                    return;
                }
                this.productRestockContracts = productContracts;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProductRestockContractsCount() {
            try {
                const productContractsCount =
                    await this.getProductContractsCount(
                        [ProductContractTypesEnum.Restock],
                        [
                            ProductContractStatusEnum.Sent,
                            ProductContractStatusEnum.Confirmed,
                        ]
                    );
                if (productContractsCount == undefined) {
                    return;
                }
                this.productRestockContractsCount = productContractsCount;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProductAmendmentContracts() {
            try {
                const productContracts = await this.getProductContracts(
                    [ProductContractTypesEnum.Amendment],
                    [
                        ProductContractStatusEnum.Sent,
                        ProductContractStatusEnum.Confirmed,
                    ]
                );
                if (!productContracts) {
                    return;
                }
                this.productAmendmentContracts = productContracts;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProductAmendmentContractsCount() {
            try {
                const productContractsCount =
                    await this.getProductContractsCount(
                        [ProductContractTypesEnum.Amendment],
                        [
                            ProductContractStatusEnum.Sent,
                            ProductContractStatusEnum.Confirmed,
                        ]
                    );
                if (productContractsCount == undefined) {
                    return;
                }
                this.productAmendmentContractsCount = productContractsCount;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async getProductContracts(
            productContractTypeIds,
            productContractStatusIds,
            offset,
            limit
        ) {
            try {
                const productContractsComponent =
                    new ProductContractsComponent();
                const productContracts =
                    await productContractsComponent.getProductContracts(
                        this.$apollo,
                        this.hostId,
                        undefined,
                        undefined,
                        productContractStatusIds,
                        productContractTypeIds,
                        offset,
                        limit
                    );
                if (!productContracts) {
                    return undefined;
                }
                return productContracts;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async getProductContractsCount(
            productContractTypeIds,
            productContractStatusIds
        ) {
            try {
                const productContractsComponent =
                    new ProductContractsComponent();
                const productContractsCount =
                    await productContractsComponent.getProductContractsCount(
                        this.$apollo,
                        this.hostId,
                        undefined,
                        undefined,
                        productContractStatusIds,
                        productContractTypeIds
                    );
                if (productContractsCount == undefined) {
                    return undefined;
                }
                return productContractsCount;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    watch: {
        async hostId() {
            try {
                this.loadProductContracts();
                this.loadProductContractsCount();
                this.loadProductRestockContracts();
                this.loadProductRestockContractsCount();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
