<template lang="pug">
  .container-fluid.p-4
    .row(v-if="voucher")
      .col-6
        .modal-headline {{$t("modals.voucherArrivalModal.modalHeadline")}}
      .col-6
        .float-right
          font-awesome-icon(:icon="['fas', 'times']" @click="$emit('close')" style="cursor: pointer;")
      .col-12
        hr
      .col-12(v-if="voucher")
        related-vouchers(
          :voucher="voucher"
          :bookingRequest="bookingRequest"
          :show-edit="true"
          :is-disabled="isDisabled"
        )
      .col-6
        .row
          .col-12.col-lg-6
            .form-group
              label(for="Email").form-label {{$t("vouchers.detail.guestMail")}}
              input#Email.form-control.custom-control(type="text", v-model="bookingRequest.Email" :disabled="isDisabled")
          .col-12.col-lg-6
            .form-group
              label.form-label.w-100 {{ $t("vouchers.detail.formOfAddress") }}
              select.custom-select(v-model="bookingRequest.Title" :disabled="isDisabled")
                option(value="Herr") {{ $t("constants.gender.m") }}
                option(value="Frau") {{ $t("constants.gender.f") }}
          .col-12.col-lg-6
            .form-group
              label(for="Forename").form-label {{$t("vouchers.detail.firstName")}}
              input#Forename.form-control.custom-control(type="text", v-model="bookingRequest.FirstName" :disabled="isDisabled")
          .col-12.col-lg-6
            .form-group
              label(for="Surname").form-label {{$t("vouchers.detail.lastName")}}
              input#Surname.form-control.custom-control(type="text", v-model="bookingRequest.LastName" :disabled="isDisabled")
          .col-12.col-lg-4
            .form-group
              label(for="StreetAndNumber").form-label {{$t("vouchers.detail.streetAndNumber")}}
              input#StreetAndNumber.form-control.custom-control(type="text", v-model="bookingRequest.Street" :disabled="isDisabled")
          .col-12.col-lg-4
            .form-group
              label(for="Postcode").form-label {{$t("vouchers.detail.zipCode")}}
              input#Postcode.form-control.custom-control(type="text", v-model="bookingRequest.ZipCode" :disabled="isDisabled")
          .col-12.col-lg-4
            .form-group
              label(for="City").form-label {{$t("vouchers.detail.city")}}
              input#City.form-control.custom-control(type="text", v-model="bookingRequest.City" :disabled="isDisabled")

          .col-12.col-lg-6
            .form-group
              label.form-label.w-100 {{$t("vouchers.detail.country")}}
              select.custom-select(v-model="bookingRequest.Country" :disabled="isDisabled")
                option(value="DE") {{$t("constants.countries.de")}}
                option(value="BE") {{$t("constants.countries.be")}}
                option(value="FR") {{$t("constants.countries.fr")}}
                option(value="IT") {{$t("constants.countries.it")}}
                option(value="LU") {{$t("constants.countries.lu")}}
                option(value="NL") {{$t("constants.countries.nl")}}
                option(value="AT") {{$t("constants.countries.at")}}
                option(value="PL") {{$t("constants.countries.pl")}}
                option(value="CH") {{$t("constants.countries.ch")}}
          .col-12.col-lg-6
            .form-group
              label(for="Phone").form-label {{$t("vouchers.detail.phone")}}
              input#Phone.form-control.custom-control(type="text", v-model="bookingRequest.Phone" :disabled="isDisabled")

      .col-6
        Datepicker(
          @input="voucher.BookedAt = $event",
          :value="voucher.BookedAt"
          :inline="true",
          :language="de",
          :monday-first="true",
          format="YYYY-MM-DD"
          :disabled-dates="disabledDates"
        )

      .col-6.mt-2
        button.button.button-primary.button-tdays.w-100.float-md-right(@click="takeDeliveryData" :disabled="isDisabled" :class="{ 'disabled': isDisabled }") {{$t("constants.buttons.takeDeliveryAddressData")}}
      .col-6.mt-2
        save-voucher-button.w-100(
          :voucher="voucher"
          :backup-voucher="backupVoucher"
          :booking-request="bookingRequest"
          :load-voucher="loadVoucher"
          :is-disabled="isDisabled"
        )

</template>

<script>
import { de, en } from "vuejs-datepicker/dist/locale";
import { BookingTicketStatusEnum } from "../../lib/enums/bookingTicketStatus/BookingTicketStatusEnum";
import Datepicker from "vuejs-datepicker";
import { BookingRequests } from "@/lib/graphql/bookingRequests/BookingRequests.ts";
import { Addresses } from "@/lib/graphql/addresses/Addresses.ts";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";
import SaveVoucherButton from "@/views/partials/saveVoucher/SaveVoucherButton.vue";
import VoucherStatusEnum from "@/lib/enums/voucherStatus/VoucherStatusEnum";
import moment from "moment";
import EventBus from "@/views/event-bus";
import RelatedVouchers from "@/views/partials/relatedVouchers/RelatedVouchers.vue";

export default {
    name: "VoucherArrivalModal",
    props: {
        voucherId: {
            type: [String, Number],
            required: true,
        },
    },
    components: {
        RelatedVouchers,
        SaveVoucherButton,
        Datepicker,
    },
    data() {
        return {
            voucher: undefined,
            backupVoucher: undefined,
            parentVoucher: undefined,
            vouchers: [],
            bookingRequest: {
                VoucherId: this.voucherId,
                Country: "DE",
                Title: "Herr",
                CreatedBy: this.$session.get("userId"),
            },
            deliveryAddress: undefined,
            invoiceAddress: undefined,
            disabledDates: {},

            de: this.$session.get("lang") == "en" ? en : de,
            BookingTicketStatusEnum: BookingTicketStatusEnum,
        };
    },
    async mounted() {
        try {
            this.loadVoucher();
        } catch (e) {
            console.log(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadVoucher() {
            try {
                const vouchersComponent = new VouchersComponent();
                EventBus.$emit("changeLoadingState", true);
                const voucher = await vouchersComponent.getVoucher(
                    this.$apollo,
                    this.voucherId
                );
                EventBus.$emit("changeLoadingState", false);
                if (!voucher) {
                    return this.$alert(
                        "Es konnte kein Gutschein geladen werden."
                    );
                }
                this.voucher = voucher;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadBookingRequest() {
            try {
                const bookingRequest = await this.$apollo
                    .query({
                        query: BookingRequests.Queries
                            .BookingRequestByVoucherId,
                        fetchPolicy: "no-cache",
                        variables: {
                            voucherId: this.voucherId,
                        },
                    })
                    .then(({ data }) => data?.bookingRequestByVoucherId)
                    .catch((e) => {
                        console.log(e);
                        return undefined;
                    });
                if (!bookingRequest) {
                    return;
                }
                this.bookingRequest = bookingRequest;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadDeliveryAddress() {
            try {
                const deliveryAddress = await this.$apollo
                    .query({
                        query: Addresses.Queries.DeliveryAddressByVoucherId,
                        fetchPolicy: "no-cache",
                        variables: {
                            voucherId: this.voucherId,
                        },
                    })
                    .then(({ data }) => data?.deliveryAddressByVoucherId)
                    .catch((e) => {
                        console.log(e);
                        return undefined;
                    });
                if (!deliveryAddress) {
                    return;
                }
                this.deliveryAddress = deliveryAddress;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadInvoiceAddress() {
            try {
                const invoiceAddress = await this.$apollo
                    .query({
                        query: Addresses.Queries.InvoiceAddressByVoucherId,
                        fetchPolicy: "no-cache",
                        variables: {
                            voucherId: this.voucherId,
                        },
                    })
                    .then(({ data }) => data?.invoiceAddressByVoucherId)
                    .catch((e) => {
                        console.log(e);
                        return undefined;
                    });
                if (!invoiceAddress) {
                    return;
                }
                this.invoiceAddress = invoiceAddress;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async takeDeliveryData() {
            try {
                if (!this.deliveryAddress) {
                    return this.$alert(
                        "Es steht keine Lieferadresse für die Datenübernahme zur Verfügung.",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                const bookingRequest = JSON.parse(
                    JSON.stringify(this.bookingRequest)
                );
                if (this.deliveryAddress.Email) {
                    bookingRequest.Email = this.deliveryAddress.Email;
                } else if (this.invoiceAddress && this.invoiceAddress.Email) {
                    bookingRequest.Email = this.invoiceAddress.Email;
                }
                bookingRequest.Title = this.deliveryAddress.FormOfAddress;
                bookingRequest.FirstName = this.deliveryAddress.FirstName;
                bookingRequest.LastName = this.deliveryAddress.LastName;
                bookingRequest.Street = this.deliveryAddress.Street;
                bookingRequest.ZipCode = this.deliveryAddress.PostalCode;
                bookingRequest.City = this.deliveryAddress.City;
                bookingRequest.Phone = this.deliveryAddress.Phone;
                this.bookingRequest = undefined;
                this.bookingRequest = bookingRequest;
            } catch (e) {
                console.error(e);
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
    },
    watch: {
        async voucher() {
            try {
                if (!this.voucher) {
                    return;
                }
                this.backupVoucher = JSON.parse(JSON.stringify(this.voucher));
                this.loadBookingRequest();
                this.loadInvoiceAddress();
                this.loadDeliveryAddress();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async bookingRequest() {
            try {
                if (!this.bookingRequest) {
                    return;
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        parentVoucher() {
            try {
                if (!this.parentVoucher) {
                    return;
                }
                this.disabledDates = {
                    from: new Date(
                        moment().subtract(10, "years").format("YYYY-MM-DD")
                    ),
                    to: new Date(
                        moment().add(10, "years").format("YYYY-MM-DD")
                    ),
                };
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    computed: {
        isDisabled() {
            try {
                return (
                    (!this.voucher.IsSelfSale &&
                        this.voucher.Status == VoucherStatusEnum.Created) ||
                    this.voucher.Status == VoucherStatusEnum.Cancelled ||
                    this.voucher.Status == VoucherStatusEnum.HostCancel ||
                    !!this.parentVoucher
                );
            } catch (e) {
                console.error(e);
                return true;
            }
        },
    },
};
</script>
