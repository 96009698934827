const ibantools = require('ibantools');

export class IbanComponent {
    isIBANValid(iban) {
        try {
            if (!iban) {
                return false;
            }
            const isIBANValid = ibantools.isValidIBAN(iban);
            return isIBANValid;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    isBICValid(bic) {
        try {
            if (!bic) {
                return false;
            }
            const isBICValid = ibantools.isValidBIC(bic);
            return isBICValid;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
}
