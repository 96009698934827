<template lang="pug">
  .container-fluid
    .row
      .col-12.mt-2
        h2 Bilder hochladen
    upload-images(:host-id="$session.get('hostId')")

</template>

<script>
import UploadImages from "@/views/modals/productContract/editor/upload-images.vue";

export default {
    name: "images",
    components: { UploadImages },
};
</script>

<style scoped></style>
