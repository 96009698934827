export class EmailValidatorComponent {
    validateEmail(email) {
        try {
            if (!email) {
                return false;
            }
            email = email.trim().toLowerCase();
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        } catch (e) {
            console.log(e);
            return false;
        }
    }
}
