<template lang="pug">
#become-a-host
  .become-a-host-content-wrapper.m-3
    .container-fluid
      .row
        .become-a-host-teaser.d-block.w-100.p-3.pt-md-0.pb-md-0.pr-lg-5.position-relative
          .logo-band
            img.img-fluid(src="https://s3-cloud.td-cdn.de/Images/Logos/touridat%3AClaim/white-plain/basic/touridat-claim-white-plain-basic-1000.png" alt="touriDat | Einfach. Ein Paar, Schöne Tage")
          .crefo
            img.img-fluid(src="https://s3-cloud.td-cdn.de/Images/Logos/companys/Weblogo_2020_4330123252_touriDat-GmbH-%26-Co-KG.png" alt="crefozert")

    .container-fluid
      .row.become-a-host-container.become-a-host-head-sub-wrap
        .col-12.text-center
          h1 {{$t("initial-contact.headline1")}}
          h3 {{$t("initial-contact.headline2")}}

    .container.become-a-host-container
      .become-a-host-benefits
        h1.text-center.mb-5 {{$t("initial-contact.teaser.headline")}}
        .row
          .col-12.col-lg-6.mb-4
            table
              tbody
                tr
                  td
                    span.background-circle.rounded-circle.d-block.mr-3.text-center 1
                  td
                    p.pl-3 {{$t("initial-contact.teaser.reason1")}}
          .col-12.col-lg-6.mb-4
            table
              tbody
              tr
                td
                  span.background-circle.rounded-circle.d-block.mr-3.text-center 2
                td
                  p.pl-3 {{$t("initial-contact.teaser.reason2")}}
          .col-12.col-lg-6.mb-4
            table
              tbody
                tr
                  td
                    span.background-circle.rounded-circle.d-block.mr-3.text-center 3
                  td
                    p.pl-3 {{$t("initial-contact.teaser.reason3")}}
          .col-12.col-lg-6.mb-4
            table
              tbody
                tr
                  td
                    span.background-circle.rounded-circle.d-block.mr-3.text-center 4
                    td
                      p.pl-3 {{$t("initial-contact.teaser.reason4")}}

    .container.become-a-host-container.text-center
      .row
        .col-12.mb-5
          h1 {{$t("initial-contact.facts.headline")}}
      .row.mb-5
        .col-12.mb-4
          .background-facts.align-self-center
            h5 25
            h6 {{$t("initial-contact.facts.bubble1")}}
        .col-12.text-center.align-self-center
          h5 {{$t("initial-contact.facts.text1")}}
      .row.mb-5
        .col-12.text-center.mb-4
          .background-facts.align-self-center
            h5 500.000
            h6 {{$t("initial-contact.facts.bubble2")}}
        .col-12.text-center.align-self-center
          h5 {{$t("initial-contact.facts.text2")}}
      .row.mb-5
        .col-12.text-left.align-self-center.mb-4
          .background-facts.align-self-center
            h5 2.000
            h6 {{$t("initial-contact.facts.bubble3")}}
        .col-12.text-center.align-self-center
          h5 {{$t("initial-contact.facts.text3")}}
      .row
        .col-12.mb-4
          .background-facts.align-self-center
            h5 30
            h6 {{$t("initial-contact.facts.bubble4")}}
        .col-12.text-center.align-self-center
          h5 {{$t("initial-contact.facts.text4")}}

    .container.become-a-host-container.text-center
      .row
        .col-12.mb-4
          h1 {{$t("initial-contact.awards.headline")}}
      .row(onclick="location.href=\"https://www.touridat.com/winner-2018\"")
        .col-12.col-lg-4.mb-4
          .award-single-box
            .award-bg.first.text-center
              .h3.text-uppercase {{$t("initial-contact.awards.first-award.headline1")}}
                br
                | {{$t("initial-contact.awards.first-award.headline2")}}
              p {{$t("initial-contact.awards.first-award.text1")}}
              .award-img-wrapper
                img(src="https://s3-cloud.td-cdn.de/Images/makaira/marketing/DtGV/2020-21/Testsieger-Gesamt-3column-grfx-2020-21-compressed.png")
            .award-txt
              .award-shadow
              p {{$t("initial-contact.awards.first-award.text2")}}
        .col-12.col-lg-4.mb-4
          .award-single-box
            .award-bg.second.text-center
              .h3.text-uppercase {{$t("initial-contact.awards.second-award.headline1")}}
                br
                | {{$t("initial-contact.awards.second-award.headline2")}}
              p {{$t("initial-contact.awards.second-award.text1")}}
              .award-img-wrapper
                img(src="https://s3-cloud.td-cdn.de/Images/makaira/marketing/DtGV/2020-21/Awards-3column-grfx-2020-21-compressed.png")
            .award-txt
              .award-shadow
              p  {{$t("initial-contact.awards.second-award.text4")}}
        .col-12.col-lg-4
          .award-single-box
            .award-bg.third.text-center
              .h3.text-uppercase {{$t("initial-contact.awards.third-award.headline1")}}
                br
                | {{$t("initial-contact.awards.third-award.headline2")}}
              p {{$t("initial-contact.awards.third-award.text1")}}
              .award-img-wrapper
                img(src="https://cdn.trustami.com/userimages/55816/boxedMinicards/56bb3a3ccc96c5432a8b476a.png?widget=true")
            .award-txt
              .award-shadow
              p {{$t("initial-contact.awards.third-award.text2")}}

    .container.become-a-host-container.text-center
      .row
        .col-12.mb-5
          h1 {{$t("initial-contact.how-it-works.headline1")}}
          h3 {{$t("initial-contact.how-it-works.headline2")}}
        .col-12.col-md-3.mb-5.d-flex
          .background-circle.rounded-circle.align-self-center
            h1.align-self-center 1
        .col-12.col-md-9.mb-5.text-center.text-md-left
          h3 {{$t("initial-contact.how-it-works.step-one.headline")}}
          p {{$t("initial-contact.how-it-works.step-one.text")}}
        .col-12.col-md-3.mb-5.d-flex
          .background-circle.rounded-circle.align-self-center
            h1.align-self-center 2
        .col-12.col-md-9.mb-5.text-center.text-md-left
          h3 {{$t("initial-contact.how-it-works.step-two.headline")}}
          p {{$t("initial-contact.how-it-works.step-two.text")}}
        .col-12.col-md-3.mb-5.d-flex
          .background-circle.rounded-circle.align-self-center
            h1.align-self-center 3
        .col-12.col-md-9.mb-5.text-center.text-md-left
          h3 {{$t("initial-contact.how-it-works.step-three.headline")}}
          p {{$t("initial-contact.how-it-works.step-three.text")}}
        .col-12.col-md-3.mb-5.d-flex
          .background-circle.rounded-circle.align-self-center
            h1.align-self-center 4
        .col-12.col-md-9.mb-5.text-center.text-md-left
          h3 {{$t("initial-contact.how-it-works.step-four.headline")}}
          p {{$t("initial-contact.how-it-works.step-four.text")}}

    .container.become-a-host-container.text-center
      .row
        .col-12.mb-4
          h1 {{$t("initial-contact.reviews.headline1")}}
          h3 {{$t("initial-contact.reviews.headline2")}}
      .row
        .col-12.col-lg-4.mb-3
          .rez-tile.mb-3
            h3.pt-2.pb-2 Hotel Amadeus Dresden
            p.pl-2.pr-2
              b Sonja Piontkowsky
              br
              | {{$t("initial-contact.reviews.review1.position")}}
            p.pl-2.pr-2: i {{$t("initial-contact.reviews.review1.text")}}
        .col-12.col-lg-4.mb-3
          .rez-tile.mb-3
            h3.pt-2.pb-2 Alexa Hotel Göhren
            p.pl-2.pr-2
              b Viola Höfs
              br
              | General Manager
            p.pl-2.pr-2: i  {{$t("initial-contact.reviews.review2")}}
        .col-12.col-lg-4
          .rez-tile.mb-3
            h3.pt-2.pb-2 GCH Hotel Group
            p.pl-2.pr-2
              b Michaela Bühler
              br
              | Key Account Manager Leisure
            p.pl-2.pr-2: i  {{$t("initial-contact.reviews.review3")}}

    .container-fluid.become-a-host-container.text-center
      .row
        .col-12.mb-4
          h1 {{$t("initial-contact.hotel-chains.headline1")}}
          h3 {{$t("initial-contact.hotel-chains.headline2")}}
      .row.hotel-logos-wrap
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/achat.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/akzent.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/ao.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/azimut.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/center-hotels.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/Couryard-by-Marriott.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/dorint.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/dormero.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/gch.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/goebel_logo.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/hilton.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/holidayinn.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/hs-hotels.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/ibis.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/kempinski.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/kommerzhotel.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/luckylogie.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/mercure.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/michel-hotel.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/mk-hotels.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/nh-hotels.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/orange-hotels.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/parkinn.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/plaza-hotelgroup.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/precise-hotels.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/prima-hotels.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/radisson-blue.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/ramada.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/ringhotels.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/savoy.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/select.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/sheraton.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/titanic.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/tryp-by-wyndham.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/tulip-inn.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/upstalsboom.jpg")
        .col-4.col-lg-2.offset-lg-4.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/vienna-house.jpg")
        .col-4.col-lg-2.mb-3
          img(src="https://s3-cloud.td-cdn.de/Images/makaira/logo-slider/update-q1-2022/wyndham-hotel-group.jpg")



    .container.text-center
      .row
        .col-12.mb-3
          h1 {{$t("initial-contact.contact.headline1")}}
          h3 {{$t("initial-contact.contact.headline2")}}
          p
            i {{$t("initial-contact.contact.text")}}
      .row
        .col-12.col-md-6.col-lg-4
          .personal-contact-wrapper
            .personal-contact-img-wrapper.person-contact-img-wrapper-marcel.mx-auto.mb-3
            h5.mb-1 Marcel Schulte
            i Geschäftsführer
            table.mt-3
              tr
                td
                  font-awesome-icon.mr-1(:icon="['fas','phone']")
                td.text-left 02974 9697-31
              tr
                td
                  font-awesome-icon.mr-1(:icon="['fas','envelope']")
                td marcel.schulte@touridat.de
        .col-12.col-md-6.col-lg-4
          .personal-contact-wrapper
            .personal-contact-img-wrapper.person-contact-img-wrapper-tati.mx-auto.mb-3
            h5.mb-1 Tatjana Dolcinelli
            i Key-Account-Manager
            table.mt-3
              tr
                td
                  font-awesome-icon.mr-1(:icon="['fas','phone']")
                td.text-left 02974 9697-32
              tr
                td
                  font-awesome-icon.mr-1(:icon="['fas','envelope']")
                td tatjana.dolcinelli@touridat.de
        .col-12.col-md-6.col-lg-4
          .personal-contact-wrapper
            .personal-contact-img-wrapper.person-contact-img-wrapper-rainer.mx-auto.mb-3
            h5.mb-1 Rainer Schulte
            i Account-Manager
            table.mt-3
              tr
                td
                  font-awesome-icon.mr-1(:icon="['fas','phone']")
                td.text-left 02974 9697-27
              tr
                td
                  font-awesome-icon.mr-1(:icon="['fas','envelope']")
                td rainer.schulte@touridat.de
        .col-12.col-md-6.col-lg-4
          .personal-contact-wrapper
            .personal-contact-img-wrapper.person-contact-img-wrapper-timo.mx-auto.mb-3
            h5.mb-1 Timo Mertens
            i Account-Manager
            table.mt-3
              tr
                td
                  font-awesome-icon.mr-1(:icon="['fas','phone']")
                td.text-left 02974 9697-24
              tr
                td
                  font-awesome-icon.mr-1(:icon="['fas','envelope']")
                td timo.mertens@touridat.de
        .col-12.col-md-6.col-lg-4
          .personal-contact-wrapper
            .personal-contact-img-wrapper.person-contact-img-wrapper-sabiye.mx-auto.mb-3
            h5.mb-1 Sabiye Cam
            i Account-Manager
            table.mt-3
              tr
                td
                  font-awesome-icon.mr-1(:icon="['fas','phone']")
                td.text-left 02974 9697-28
              tr
                td
                  font-awesome-icon.mr-1(:icon="['fas','envelope']")
                td sabiye.cam@touridat.de
        //.col-12.col-md-6.col-lg-4
          .personal-contact-wrapper
            .personal-contact-img-wrapper.person-contact-img-wrapper-annalena.mx-auto.mb-3
            h5.mb-1 Anna-Lena Schulte
            i Sales Support
            table.mt-3
              tr
                td
                  font-awesome-icon.mr-1(:icon="['fas','phone']")
                td.text-left 02974 9697-18
              tr
                td
                  font-awesome-icon.mr-1(:icon="['fas','envelope']")
                td anna-lena.schulte@touridat.de


</template>

<script>
export default {
    name: "InitialContact",
};
</script>
