<template lang="pug">
  .related-vouchers(v-if="voucher")
    voucher-group-booking-parent-voucher(
      :voucher="voucher"
    )
    voucher-group-booking-vouchers(
      v-if="bookingRequest && bookingRequest.Id"
      :voucher="voucher"
      :booking-request="bookingRequest"
      :show-edit="showEdit"
      :is-disabled="isDisabled"
    )

</template>

<script>
import VoucherGroupBookingVoucher from "@/views/partials/voucherGroupBookings/VoucherGroupBookingVoucher.vue";
import VoucherGroupBookingParentVoucher from "@/views/partials/voucherGroupBookings/VoucherGroupBookingParentVoucher.vue";
import VoucherGroupBookingVouchers from "@/views/partials/voucherGroupBookings/VoucherGroupBookingVouchers.vue";

export default {
    name: "RelatedVouchers",
    components: {
        VoucherGroupBookingVouchers,
        VoucherGroupBookingParentVoucher,
        VoucherGroupBookingVoucher,
    },
    props: {
        voucher: {
            type: Object,
            required: true,
        },
        bookingRequest: {
            type: Object,
            required: false,
        },
        showEdit: {
            type: Boolean,
            required: false,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            vouchers: [],
            parentVoucher: undefined,
        };
    },
};
</script>

<style scoped></style>
