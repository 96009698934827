import gql from "graphql-tag";

export const Employees = {
    Queries: {
        ContactPersons: gql`
        query contactPerson($hostId: ID) {
            contactPerson(hostId: $hostId) {
                Id
                Vorname
                Name
                Telefon
                Fax
                Email
                Bild
                Durchwahl
            }
        }`,
        TouriBookAdviser: gql`
        query touriBookAdviser($hostId: ID) {
            touriBookAdviser(hostId: $hostId) {
                Id
                Vorname
                Name
                Telefon
                Fax
                Email
                Bild
                Durchwahl
            }
        }`,
    },
};
