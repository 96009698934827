<template lang="pug">
  .col-12.mb-3
    h5 {{ searchCriteriaSubArea.Description }}
    .row
      datasheet-search-criteria-assignment(
        :search-criteria="searchCriteria"
        :host-search-criteria-assignment="hostSearchCriteriaAssignment"
        :add-search-criteria-assignment="addSearchCriteriaAssignment"
        :delete-search-criteria-assignment="deleteSearchCriteriaAssignment"
        v-for="searchCriteria in searchCriterias.filter((s) => s.SearchCriteriaSubAreaId == searchCriteriaSubArea.Id)"
      )
</template>

<script>
import DatasheetSearchCriteriaAssignment from "@/views/pages/account/datasheet/datasheet-search-criteria-assignment.vue";

export default {
    name: "datasheet-search-criteria-sub-area",
    components: { DatasheetSearchCriteriaAssignment },
    props: {
        searchCriteriaSubArea: {
            type: Object,
            required: true,
        },
        searchCriterias: {
            type: Array,
            required: true,
        },
        hostSearchCriteriaAssignment: {
            type: Object,
            required: false,
        },
        addSearchCriteriaAssignment: {
            type: Function,
            required: true,
        },
        deleteSearchCriteriaAssignment: {
            type: Function,
            required: true,
        },
    },
};
</script>

<style scoped></style>
