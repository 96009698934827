<template lang="pug">
  .wrapper
    .row.mt-3
      .col-12
        touribook-request-panel(
          :booking-ticket-id="bookingTicketId"
        )

      template(v-if="product")
        .col-12
          .form-group
            label.form-label {{$t("products.detail.arrangement")}}
            textarea-autosize.form-control(disabled="true", :value="product.Arrangement")

        .col-12
          .form-group
            label.form-label {{$t("products.detail.hotelServices")}}
            textarea-autosize.form-control(disabled="true", :value="product.HolidayServices")

        .col-12
          .form-group
            label.form-label {{$t("products.detail.touristicalServices")}}
            textarea-autosize.form-control(disabled="true", :value="product.AdditionalHolidayServices")

        .col-12
          .form-group
            label.form-label {{$t("products.detail.extraInfo")}}
            textarea-autosize.form-control(disabled="true", :value="product.ZusatzInfo")

        .col-12
          .float-right
            router-link.button.button-primary.button-tdays(to="/touribook" tag="button")
              | {{$t("constants.buttons.back")}}


</template>

<script>
import { BookingTicketStatusEnum } from "@/lib/enums/bookingTicketStatus/BookingTicketStatusEnum";
import { BookingTickets } from "@/lib/graphql/bookingTickets/BookingTickets.ts";
import { BookingRequests } from "@/lib/graphql/bookingRequests/BookingRequests.ts";
import { Vouchers } from "@/lib/graphql/vouchers/Vouchers.ts";
import { Products } from "@/lib/graphql/products/Products.ts";

export default {
    name: "TouribookDetails",
    props: {
        bookingTicketId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            bookingTicket: undefined,
            bookingRequest: undefined,
            voucher: undefined,
            product: undefined,

            BookingTicketStatusEnum: BookingTicketStatusEnum,
        };
    },
    async mounted() {
        try {
            await this.loadBookingTicket();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadBookingTicket() {
            try {
                const bookingTicket = await this.$apollo
                    .query({
                        query: BookingTickets.Queries.BookingTicket,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.bookingTicketId,
                        },
                    })
                    .then(({ data }) => data?.bookingTicket)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!bookingTicket) {
                    return;
                }
                this.bookingTicket = bookingTicket;
                this.loadBookingRequest();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingRequest() {
            try {
                const bookingRequest = await this.$apollo
                    .query({
                        query: BookingRequests.Queries.BookingRequest,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.bookingTicket?.BookingRequestId,
                        },
                    })
                    .then(({ data }) => data?.bookingRequest)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!bookingRequest) {
                    return;
                }
                this.bookingRequest = bookingRequest;
                await this.loadVoucher();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadVoucher() {
            try {
                const voucher = await this.$apollo
                    .query({
                        query: Vouchers.Queries.Voucher,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.bookingRequest?.VoucherId,
                        },
                    })
                    .then(({ data }) => data?.voucher)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!voucher) {
                    return;
                }
                this.voucher = voucher;
                await this.loadProduct();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProduct() {
            try {
                const product = await this.$apollo
                    .query({
                        query: Products.Queries.Product,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.voucher?.ProductId,
                        },
                    })
                    .then(({ data }) => data?.product)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!product) {
                    return;
                }
                this.product = product;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
