<template lang="pug">
  .voucher-group-booking-vouchers(v-if="vouchers && Array.isArray(vouchers) && vouchers.length")
      template(v-if="showEdit")
        h5 {{ $t('touriBook.additionalVouchers') }}:
        voucher-group-booking-voucher.mb-2(
          v-if="showEdit"
          v-for="voucher in vouchers"
          :voucher="voucher"
          :booking-request="bookingRequest"
          :load-vouchers="loadVouchers"
          :is-disabled="isDisabled"
          :key="voucher.Id"
        )
      template(v-else)
        .tb-ticket-voucher-no {{ $t('touriBook.additionalVouchers') }}:&nbsp;
          template(v-for="voucher in vouchers")
            router-link(
              :to="{ name: 'VoucherDetails', params: { voucherId: voucher.Id } }"
            )
              span.font-weight-bold {{ voucher.Id }}
            span.font-weight-bold(v-if="vouchers.indexOf(voucher) > 0") ,&nbsp;

</template>

<script>
import VoucherGroupBookingVoucher from "@/views/partials/voucherGroupBookings/VoucherGroupBookingVoucher.vue";
import VoucherGroupBookingParentVoucher from "@/views/partials/voucherGroupBookings/VoucherGroupBookingParentVoucher.vue";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";

export default {
    name: "VoucherGroupBookingVouchers",
    components: {
        VoucherGroupBookingParentVoucher,
        VoucherGroupBookingVoucher,
    },
    props: {
        voucher: {
            type: Object,
            required: true,
        },
        bookingRequest: {
            type: Object,
            required: false,
        },
        showEdit: {
            type: Boolean,
            required: false,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            vouchers: [],
            parentVoucher: undefined,
        };
    },
    async mounted() {
        try {
            this.loadVouchers();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadVouchers() {
            try {
                if (!this.bookingRequest?.Id) {
                    return;
                }
                const vouchersComponent = new VouchersComponent();
                const vouchers = await vouchersComponent.getVouchers(
                    this.$apollo,
                    undefined,
                    undefined,
                    this.bookingRequest?.Id
                );
                if (!vouchers) {
                    return;
                }
                this.vouchers = vouchers;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    watch: {
        bookingRequest() {
            try {
                this.loadVouchers();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
