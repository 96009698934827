const {
    VoucherGroupBookings,
} = require("@/lib/graphql/voucherGroupBookings/VoucherGroupBookings.ts");

class VoucherGroupBookingsComponent {
    async getVoucherGroupBooking(apollo, voucherId, bookingRequestId) {
        try {
            if (!apollo || !voucherId || !bookingRequestId) {
                return undefined;
            }
            const voucherGroupBooking = await apollo
                .query({
                    query: VoucherGroupBookings.Queries.VoucherGroupBooking,
                    fetchPolicy: "no-cache",
                    variables: {
                        voucherId: voucherId,
                        bookingRequestId: bookingRequestId,
                    },
                })
                .then(({ data }) => data?.voucherGroupBooking)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return voucherGroupBooking;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async deleteVoucherGroupBooking(apollo, voucherId, bookingRequestId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!voucherId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Gutschein-Id gefunden.",
                };
            }
            if (!bookingRequestId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Buchungsanfragen-Id gefunden.",
                };
            }
            const deletedVoucherGroupBooking = await apollo
                .mutate({
                    mutation:
                        VoucherGroupBookings.Mutations
                            .DeleteVoucherGroupBooking,
                    fetchPolicy: "no-cache",
                    variables: {
                        voucherId: voucherId,
                        bookingRequestId: bookingRequestId,
                    },
                })
                .then(({ data }) => data?.deleteVoucherGroupBooking)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!deletedVoucherGroupBooking) {
                return {
                    IsSuccess: false,
                    Message: `Die Gutscheingruppenanfrage für Gutschein ${voucherId} konnte nicht gelöscht werden.`,
                };
            }
            return deletedVoucherGroupBooking;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async saveVoucherGroupBooking(apollo, voucherGroupBooking) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!voucherGroupBooking) {
                return {
                    IsSuccess: false,
                    Message: "Keine Gutscheingruppenbuchung gefunden.",
                };
            }
            const savedVoucherGroupBooking = await apollo
                .mutate({
                    mutation:
                        VoucherGroupBookings.Mutations.SaveVoucherGroupBooking,
                    fetchPolicy: "no-cache",
                    variables: {
                        voucherGroupBooking: voucherGroupBooking,
                    },
                })
                .then(({ data }) => data?.saveVoucherGroupBooking)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedVoucherGroupBooking) {
                return {
                    IsSuccess: false,
                    Message: `Die Gutscheingruppenbuchung konnte nicht gespeichert werden.`,
                };
            }
            return savedVoucherGroupBooking;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}

module.exports = VoucherGroupBookingsComponent;
