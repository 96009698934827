import gql from "graphql-tag";

export const SearchCriteriaSubAreas = {
    Queries: {
        SearchCriteriaSubAreas: gql`
        query searchCriteriaSubAreas($searchCriteriaAreaId: ID, $isActive: Boolean, $offset: Int, $limit: Int) {
            searchCriteriaSubAreas(searchCriteriaAreaId: $searchCriteriaAreaId, isActive: $isActive, offset: $offset, limit: $limit) {
                Id
                Description
                SortNumber
                IsActive
                SearchCriteriaAreaId
            }
        }`,
    },
};
