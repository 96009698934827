import gql from "graphql-tag";

export const Maintenances = {
    Queries: {
        DashboardMaintenance: gql`
        query dashbordMaintenance {
            dashboardMaintenance {
                Id
                Description
                Platform
                IsActive
            }
        }`,
    },
};
