<template lang="pug">
.row
  .col-12
    .input-group
      input.form-control.custom-control(type="text", :placeholder="$t(searchPlaceholder)", v-model="filterValue" v-on:keyup.enter="applyFilter")
      .input-group-append
        button.button.button-primary.button-tdays(@click="applyFilter") {{$t("constants.buttons.search")}}
</template>

<script>
import EventBus from "../../views/event-bus";

export default {
    name: "table-filter",
    props: {
        isProduct: {
            type: Boolean,
            required: false,
        },
        isVoucher: {
            type: Boolean,
            required: false,
        },
        isTouribook: {
            type: Boolean,
            required: false,
        },
        isUser: {
            type: Boolean,
            required: false,
        },
        isSettlement: {
            type: Boolean,
            required: false,
        },
        searchHeadline: String,
        searchPlaceholder: String,
    },
    data() {
        return {
            filterValue: "",
        };
    },
    methods: {
        applyFilter() {
            if (this.isProduct) {
                EventBus.$emit("applyProductFilter", this.filterValue);
            } else if (this.isVoucher) {
                EventBus.$emit("applyVoucherFilter", this.filterValue);
            } else if (this.isTouribook) {
                EventBus.$emit("applyTouribookFilter", this.filterValue);
            } else if (this.isUser) {
                EventBus.$emit("applyUserFilter", this.filterValue);
            } else if (this.isSettlement) {
                EventBus.$emit("applySettlementFilter", this.filterValue);
            }
        },
    },
};
</script>

<style scoped></style>
