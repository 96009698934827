import gql from "graphql-tag";

export const BookingTicketStatus = {
    Queries: {
        BookingTicketStatusById: gql`
        query bookingTicketStatusById($id: ID) {
            bookingTicketStatusById(id: $id) {
                Id
                Description
                DisplayedDescriptionCustomer
                DisplayedDescriptionHost
            }
        }`,
    },
};
