<template lang="pug">
.row
  .col-3
    select.form-control(type="dropdown" v-model="privateProductId")
      sales-select-option(
        v-for="product in Products"
        v-bind:key="product.Id",
        :Id="product.Id",
        :Name="product.Name1",
      )
  .col-3
    input.form-control(type="date" id="fromDate" v-model="privateFrom")
  .col-3
    input.form-control(type="date" id="toDate" v-model="privateTo")
  .col-3
    | {{$t("sales.product-chart-component.totalSales")}}
</template>

<script>
export default {
    name: "sales-product-chart-component",
    props: {
        Products: [],
    },
};
</script>

<style scoped></style>
