<template lang="pug">
.row
  .col-6
    p(style="color:#777777") {{$t(DataCountText, { dataCount: this.dataCount})}}
  .col-6
    .float-right
      .nav
        ul.pagination
          li.page-item
            button.page-link(@click="goToFirstPage") &laquo;
          li.page-item
            button.page-link(@click="goToPreviousPage") &lsaquo;
          li.page-item.disabled
            button.page-link.disabled {{ this.currentPage }}
          li.page-item
            button.page-link(@click="goToNextPage") &rsaquo;
          li.page-item
            button.page-link(@click="goToLastPage") &raquo;

</template>

<script>
import EventBus from "../../views/event-bus";

export default {
    name: "pagination",
    props: {
        isProduct: {
            type: Boolean,
            required: false,
        },
        isVoucher: {
            type: Boolean,
            required: false,
        },
        isTouribook: {
            type: Boolean,
            required: false,
        },
        isUser: {
            type: Boolean,
            required: false,
        },
        isSettlement: {
            type: Boolean,
            required: false,
        },
        isDocument: {
            type: Boolean,
            required: false,
        },
        isNotification: {
            type: Boolean,
            required: false,
        },
        isContract: {
            type: Boolean,
            required: false,
        },
        isRestockContract: {
            type: Boolean,
            required: false,
        },
        DataCountText: String,
        PageSize: Number,
        DataCount: Number,
    },
    data() {
        return {
            currentPage: 1,
            dataCount: this.DataCount,
            pageSize: this.PageSize,
            offset: 0,

            lastPage: 1,
            previousPage: 1,
            nextPage: 1,
        };
    },
    mounted() {
        if (this.isProduct) {
            EventBus.$on(
                "changeProductPageSize",
                function (pageSize) {
                    this.pageSize = pageSize;
                    this.calcPages();
                }.bind(this)
            );
            EventBus.$on(
                "changeProductDataCount",
                function (dataCount) {
                    this.dataCount = dataCount;
                    this.calcPages();
                }.bind(this)
            );
        } else if (this.isVoucher) {
            EventBus.$on(
                "changeVoucherPageSize",
                function (pageSize) {
                    this.pageSize = pageSize;
                    this.calcPages();
                }.bind(this)
            );
            EventBus.$on(
                "changeVoucherDataCount",
                function (dataCount) {
                    this.dataCount = dataCount;
                    this.calcPages();
                }.bind(this)
            );
        } else if (this.isTouribook) {
            EventBus.$on(
                "changeTouribookPageSize",
                function (pageSize) {
                    this.pageSize = pageSize;
                    this.calcPages();
                }.bind(this)
            );
            EventBus.$on(
                "changeTouribookDataCount",
                function (dataCount) {
                    this.dataCount = dataCount;
                    this.calcPages();
                }.bind(this)
            );
        } else if (this.isUser) {
            EventBus.$on(
                "changeUserPageSize",
                function (pageSize) {
                    this.pageSize = pageSize;
                    this.calcPages();
                }.bind(this)
            );
            EventBus.$on(
                "changeUserDataCount",
                function (dataCount) {
                    this.dataCount = dataCount;
                    this.calcPages();
                }.bind(this)
            );
        } else if (this.isSettlement) {
            EventBus.$on(
                "changeSettlementPageSize",
                function (pageSize) {
                    this.pageSize = pageSize;
                    this.calcPages();
                }.bind(this)
            );
            EventBus.$on(
                "changeSettlementDataCount",
                function (dataCount) {
                    this.dataCount = dataCount;
                    this.calcPages();
                }.bind(this)
            );
        } else if (this.isDocument) {
            EventBus.$on(
                "changeDocumentPageSize",
                function (pageSize) {
                    this.pageSize = pageSize;
                    this.calcPages();
                }.bind(this)
            );
            EventBus.$on(
                "changeDocumentDataCount",
                function (dataCount) {
                    this.dataCount = dataCount;
                    this.calcPages();
                }.bind(this)
            );
        } else if (this.isNotification) {
            EventBus.$on(
                "changeNotificationPageSize",
                function (pageSize) {
                    this.pageSize = pageSize;
                    this.calcPages();
                }.bind(this)
            );
            EventBus.$on(
                "changeNotificationDataCount",
                function (dataCount) {
                    this.dataCount = dataCount;
                    this.calcPages();
                }.bind(this)
            );
        } else if (this.isContract) {
            EventBus.$on(
                "changeContractPageSize",
                function (pageSize) {
                    this.pageSize = pageSize;
                    this.calcPages();
                }.bind(this)
            );
            EventBus.$on(
                "changeContractDataCount",
                function (dataCount) {
                    this.dataCount = dataCount;
                    this.calcPages();
                }.bind(this)
            );
        } else if (this.isRestockContract) {
            EventBus.$on(
                "changeRestockContractPageSize",
                function (pageSize) {
                    this.pageSize = pageSize;
                    this.calcPages();
                }.bind(this)
            );
            EventBus.$on(
                "changeRestockContractDataCount",
                function (dataCount) {
                    this.dataCount = dataCount;
                    this.calcPages();
                }.bind(this)
            );
        }
        this.calcPages();
    },
    methods: {
        calcPages() {
            this.currentPage = 1;
            this.lastPage = Math.ceil(this.dataCount / this.pageSize);
            this.previousPage =
                this.currentPage - 1 > 1 ? this.currentPage - 1 : 1;
            this.nextPage =
                this.currentPage + 1 < this.lastPage
                    ? this.currentPage + 1
                    : this.lastPage;
        },
        goToFirstPage() {
            this.currentPage = 1;
            this.offset = (this.currentPage - 1) * this.pageSize;
            this.previousPage =
                this.currentPage - 1 > 1 ? this.currentPage - 1 : 1;
            this.nextPage =
                this.currentPage + 1 < this.lastPage
                    ? this.currentPage + 1
                    : this.lastPage;
            this.refreshOffset();
        },
        goToNextPage() {
            this.currentPage = this.nextPage;
            this.offset = (this.currentPage - 1) * this.pageSize;
            this.previousPage =
                this.currentPage - 1 > 1 ? this.currentPage - 1 : 1;
            this.nextPage =
                this.currentPage + 1 < this.lastPage
                    ? this.currentPage + 1
                    : this.lastPage;
            this.refreshOffset();
        },
        goToPreviousPage() {
            this.currentPage = this.previousPage;
            this.offset = (this.currentPage - 1) * this.pageSize;
            this.previousPage =
                this.currentPage - 1 > 1 ? this.currentPage - 1 : 1;
            this.nextPage =
                this.currentPage + 1 < this.lastPage
                    ? this.currentPage + 1
                    : this.lastPage;
            this.refreshOffset();
        },
        goToLastPage() {
            this.currentPage = this.lastPage;
            this.offset = (this.currentPage - 1) * this.pageSize;
            this.previousPage =
                this.currentPage - 1 > 1 ? this.currentPage - 1 : 1;
            this.nextPage =
                this.currentPage + 1 < this.lastPage
                    ? this.currentPage + 1
                    : this.lastPage;
            this.refreshOffset();
        },
        refreshOffset() {
            if (this.isProduct) {
                EventBus.$emit("changeProductOffset", this.offset);
            } else if (this.isVoucher) {
                EventBus.$emit("changeVoucherOffset", this.offset);
            } else if (this.isTouribook) {
                EventBus.$emit("changeTouribookOffset", this.offset);
            } else if (this.isUser) {
                EventBus.$emit("changeUserOffset", this.offset);
            } else if (this.isSettlement) {
                EventBus.$emit("changeSettlementOffset", this.offset);
            } else if (this.isDocument) {
                EventBus.$emit("changeDocumentOffset", this.offset);
            } else if (this.isNotification) {
                EventBus.$emit("changeNotificationOffset", this.offset);
            } else if (this.isContract) {
                EventBus.$emit("changeContractOffset", this.offset);
            } else if (this.isRestockContract) {
                EventBus.$emit("changeRestockContractOffset", this.offset);
            }
        },
    },
};
</script>

<style scoped></style>
