<template lang="pug">
#overlay
  #overlayText
    #tD-loading-wrapper
      #tD-loading-gradient
        #svg-position
          object(data="https://d1xkfcniu0czj4.cloudfront.net/testing/img/tD-loading.svg" type="image/svg+xml")
            <!-- Fallback -->
            | Ihr Browser kann leider keine svg darstellen!

      .text-center.mt-3(v-if="progress")
        h3(v-if="isNaN(progress)") {{ progress }}
        h3(v-else) {{ progress }}%

</template>

<script>
export default {
    name: "tD-loading-overlay",
    props: {
        progress: {
            type: String,
            required: false,
        },
    },
};
</script>
