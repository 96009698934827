<template lang="pug">
.container-fluid
  .row
    .col-12
      h2 {{$t("products.list.headline")}}
    .col-12.mt-2
      .float-right
        b-form-checkbox(switch :checked="isVisibleInItemListing" size="lg" v-model="isVisibleInItemListing" @input="filterProducts")
          span Nur aktive Produkte?
    .col-12.mt-2
      .input-group
        input.form-control.custom-control(type="text", :placeholder="this.$t('products.list.searchPlaceholder')" v-model="filter" v-on:keyup.enter="filterProducts")
        .input-group-append
          button.button.button-primary.button-tdays(@click="filterProducts") {{$t("constants.buttons.search")}}
    .col-12
      .table-responsive
        table.table.table-bordered.text-center.d-table
          thead
            tr
              th(scope="col") {{$t("products.list.table.productNo")}}
              th(scope="col") {{$t("products.list.table.value")}}
              th(scope="col") {{$t("products.list.table.price")}}
              th(scope="col") {{$t("products.list.table.dealPrice")}}
              th(scope="col") {{$t("products.list.table.arrangement")}}
              th(scope="col") {{$t("products.list.table.stock")}}
              th(scope="col") {{$t("products.list.table.dealStock")}}
              th(scope="col") {{$t("products.list.table.details")}}
          tbody
            product-table-column(:product="product" :key="product.Id" v-for="product in products")

    .col-12.mt-3
      new-pagination(:current-count="products.length" :overall-count="productsCount" :load-more="loadMoreProducts")

</template>

<script>
import EventBus from "../../../views/event-bus";
import { Products } from "@/lib/graphql/products/Products.ts";
import NewPagination from "@/views/partials/new-pagination.vue";

export default {
    name: "ProductList",
    components: { NewPagination },
    props: {
        slug: String,
    },
    data() {
        return {
            hostId: this.$session.get("hostId"),

            products: [],
            productsCount: 0,
            limit: 15,
            offset: 0,
            isVisibleInItemListing: true,
            filter: "",
        };
    },
    mounted() {
        try {
            EventBus.$on(
                "changeHostId",
                async function (hostId) {
                    this.hostId = hostId;
                    this.offset = 0;
                    this.products = [];
                    this.loadProducts();
                    this.loadProductsCount();
                }.bind(this)
            );
            this.loadProducts();
            this.loadProductsCount();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadProducts() {
            try {
                EventBus.$emit("changeLoadingState", true);
                const products = await this.$apollo
                    .query({
                        query: Products.Queries.Products,
                        fetchPolicy: "no-cache",
                        variables: {
                            hostId: this.hostId,
                            isVisibleInItemListing: this.isVisibleInItemListing,
                            offset: this.offset,
                            limit: this.limit,
                        },
                    })
                    .then(({ data }) => data?.products)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                EventBus.$emit("changeLoadingState", false);
                if (!products) {
                    return this.$alert(
                        "Es konnten keine Produkte geladen werden."
                    );
                }
                this.products = this.products.concat(products);
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProductsCount() {
            try {
                const productsCount = await this.$apollo
                    .query({
                        query: Products.Queries.ProductsCount,
                        fetchPolicy: "no-cache",
                        variables: {
                            hostId: this.hostId,
                            isVisibleInItemListing: this.isVisibleInItemListing,
                        },
                    })
                    .then(({ data }) => data?.productsCount)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (productsCount == undefined) {
                    return;
                }
                this.productsCount = productsCount;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadMoreProducts() {
            try {
                this.offset += this.limit;
                await this.loadProducts();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async filterProducts() {
            try {
                this.offset = 0;
                this.products = [];
                this.loadProducts();
                this.loadProductsCount();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
