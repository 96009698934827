import gql from "graphql-tag";

export const GetHostEmailSettingsQuery = gql`
    query hostEmailSettings($hostId: ID) {
        hostEmailSettings(hostId: $hostId) {
            Id
            HostId
            EmailSettingId
        }
    }
`;
