<template lang="pug">
  .wrapper
    tD-loading-overlay(v-if="$apollo.loading")
    .row
      .col-12
        h2 {{$t("account.security.headline")}}
    .row.mt-2
      .col-12
        .section-headline {{$t("account.security.sectionHeadline")}}
        p {{$t("account.security.textChangePassword")}}
      .col-12.col-sm-6
        .form-group
          label.form-label {{$t("account.security.enterNewPassword")}}
          input.form-control.custom-control(type="password" v-model="password")
      .col-12.col-sm-6
        .form-group
          label.form-label {{$t("account.security.repeatNewPassword")}}
          input.form-control.custom-control(type="password" v-model="confirmPassword")
      .col-12
        button.button.button-primary.button-tdays.float-right(@click="savePassword")
          | {{$t("account.security.buttonChangePassword")}}
</template>

<script>
import { PasswordValidatorComponent } from "../../../lib/components/passwordValidator/PasswordValidatorComponent";
import EventBus from "@/views/event-bus";
import UsersComponent from "@/lib/components/users/UsersComponent";

export default {
    name: "security",
    props: {
        slug: String,
    },
    data() {
        return {
            password: "",
            confirmPassword: "",
        };
    },
    methods: {
        async savePassword() {
            try {
                const confirmed = await this.$confirm(
                    this.$t("constants.messages.savePassword").toString(),
                    this.$t("constants.messages.questionTitle").toString()
                )
                    .then()
                    .catch((e) => e);
                if (!confirmed) {
                    return;
                }
                if (this.password.length == 0) {
                    this.$alert(
                        this.$t(
                            "constants.messages.passwordNotFilled"
                        ).toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                    return;
                }
                if (this.confirmPassword.length == 0) {
                    this.$alert(
                        this.$t(
                            "constants.messages.passwordRepeatNotFilled"
                        ).toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                    return;
                }
                if (this.password != this.confirmPassword) {
                    this.$alert(
                        this.$t(
                            "constants.messages.passwordsDoNotMatch"
                        ).toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                    return;
                }
                const result = this._validatePassword(this.password);
                if (!result) {
                    this.$alert(
                        this.$t(
                            "constants.messages.passwordNotStrongEnough"
                        ).toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                    return;
                }
                const usersComponent = new UsersComponent();
                EventBus.$emit("changeLoadingState", true);
                const updatedUserPassword =
                    await usersComponent.updateUserPassword(
                        this.$apollo,
                        this.$session.get("userId"),
                        this.password
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!updatedUserPassword) {
                    this.$alert(
                        this.$t(
                            "constants.messages.passwordNotSaved"
                        ).toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                this.$alert(
                    updatedUserPassword.Message,
                    this.$t("constants.messages.passwordTitle").toString()
                );
            } catch (e) {
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
        _validatePassword(password) {
            const passwordValidatorComponent = new PasswordValidatorComponent();
            return passwordValidatorComponent.validatePassword(password);
        },
    },
};
</script>

<style scoped></style>
