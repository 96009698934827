<template lang="pug">
  .col-12.col-lg-4.col-md-6.col-sm-12
    .row.mt-2
      template(v-if="searchCriteria.HasInput")
        .col.d-flex.align-self-center
          .form-check
            b-form-checkbox(switch :checked="searchCriteriaAssignment != undefined" size="lg" @change="check") {{ searchCriteria.Description }}
        .col
          input.form-control(type="text" v-model="searchCriteriaAssignment.Value" @input="change" v-if="searchCriteriaAssignment" maxlength=255)
          input.form-control.disabled(type="text" disabled v-else)
      template(v-else)
        .col-12.d-flex.align-self-center
          .form-check
            b-form-checkbox(switch :checked="searchCriteriaAssignment != undefined" size="lg" @change="check") {{ searchCriteria.Description }}

</template>

<script>
import { SearchCriteriaAssignments } from "@/lib/graphql/searchCriteriaAssignments/SearchCriteriaAssignments.ts";

export default {
    name: "datasheet-search-criteria-assignment",
    props: {
        searchCriteria: {
            type: Object,
            required: true,
        },
        hostSearchCriteriaAssignment: {
            type: Object,
            required: false,
        },
        addSearchCriteriaAssignment: {
            type: Function,
            required: true,
        },
        deleteSearchCriteriaAssignment: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            searchCriteriaAssignment: undefined,
        };
    },
    watch: {
        async hostSearchCriteriaAssignment() {
            try {
                await this.loadSearchCriteriaAssignment();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    methods: {
        async loadSearchCriteriaAssignment() {
            try {
                if (!this.hostSearchCriteriaAssignment) {
                    return;
                }
                const searchCriteriaAssignment = await this.$apollo
                    .query({
                        query: SearchCriteriaAssignments.Queries
                            .SearchCriteriaAssignment,
                        fetchPolicy: "no-cache",
                        variables: {
                            searchCriteriaId: this.searchCriteria.Id,
                            hostSearchCriteriaAssignmentId:
                                this.hostSearchCriteriaAssignment.Id,
                        },
                    })
                    .then(({ data }) => data?.searchCriteriaAssignment)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!searchCriteriaAssignment) {
                    return;
                }
                this.searchCriteriaAssignment = searchCriteriaAssignment;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async check(isChecked) {
            try {
                if (isChecked) {
                    this.searchCriteriaAssignment = {
                        SearchCriteriaId: this.searchCriteria.Id,
                    };
                    this.addSearchCriteriaAssignment(
                        this.searchCriteriaAssignment
                    );
                } else {
                    this.deleteSearchCriteriaAssignment(
                        this.searchCriteriaAssignment
                    );
                    this.searchCriteriaAssignment = undefined;
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        change() {
            try {
                this.addSearchCriteriaAssignment(this.searchCriteriaAssignment);
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>
