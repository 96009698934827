<template lang="pug">
.modal-wrap.p-3.mt-3
  .modal-close-wrap
    i.modal-close-button(@click="$emit('close')")
      font-awesome-icon(:icon="['fas', 'times']")
  .container-fluid
    .row
      .col-12
        .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.voucherNotesModal.modalHeadline")}}
    .row
      .col-12
        label.form-label {{$t("vouchers.detail.notes")}}
        textarea-autosize.form-control(v-model="voucher.BookingInfo")
    .row
      .col-12
        .form-group
          label(for="EditedBy").form-label {{$t("vouchers.detail.editedBy")}}
          input#EditedBy.form-control.custom-control(type="text" v-model="voucher.BookingInfoEditedBy")
    .row
      .col-12.col-sm-6.mb-3
        button.button.button-primary.button-tdays.w-100(@click="saveVoucher")
          | {{$t("constants.buttons.saveChanges")}}


</template>

<script>
import EventBus from "../event-bus";
import { Vouchers } from "@/lib/graphql/vouchers/Vouchers.ts";

export default {
    name: "VoucherBookingInfoModal",
    props: {
        voucher: {
            type: Object,
            required: true,
        },
        BookingInfo: String,
        BookingInfoEditedBy: String,
    },
    data() {
        return {
            bookingInfo: this.BookingInfo,
            bookingInfoEditedBy: this.BookingInfoEditedBy,
        };
    },
    methods: {
        async saveVoucher() {
            if (!this.voucher.BookingInfoEditedBy.length) {
                return this.$alert(
                    this.$t("constants.messages.editedBy").toString(),
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
            if (!this.voucher.BookingInfo.length) {
                return this.$alert(
                    this.$t("constants.messages.fillNotes").toString(),
                    this.$t("constants.messages.alertTitle").toString()
                );
            }

            const confirmed = await this.$confirm(
                this.$t("constants.messages.saveChanges").toString(),
                this.$t("constants.messages.questionTitle").toString()
            )
                .then()
                .catch((e) => e);
            if (!confirmed) {
                return;
            }
            EventBus.$emit("changeLoadingState", true);
            const savedVoucher = await this.$apollo
                .mutate({
                    mutation: Vouchers.Mutations.SaveVoucher,
                    fetchPolicy: "no-cache",
                    variables: {
                        voucher: this.voucher,
                    },
                })
                .then(({ data }) => data?.saveVoucher)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            EventBus.$emit("changeLoadingState", false);
            if (!savedVoucher) {
                return this.$alert(
                    "Der Gutschein konnte nicht gespeichert werden."
                );
            }
            if (!savedVoucher.Message) {
                this.$alert(savedVoucher.Message);
            }
            if (savedVoucher.IsSuccess) {
                this.$emit("close");
            }
        },
    },
};
</script>
