<template lang="pug">
  .card
    tD-loading-overlay(v-if="$apollo.loading")
    .card-body
      .card-title
        .row
          .col-12
            .text-left
              h5 {{header}}

      .card-text.p-4
        .text-center
          .sales-text(v-if="isLoading") Lädt...
          .sales-text(v-else) {{ salesCount }}

</template>

<script>
import EventBus from "../../../views/event-bus";
import { ItemsComponent } from "@/lib/components/items/ItemsComponent";
import { OrderStatusEnum } from "@/lib/enums/orderStatus/OrderStatusEnum.ts";

export default {
    name: "SalesCountWidget",
    props: {
        header: {
            type: String,
            required: true,
        },
        from: {
            type: Date,
            required: true,
        },
    },
    data() {
        return {
            hostId: this.$session.get("hostId"),
            items: [],
            previousItems: [],

            isLoading: false,
        };
    },
    async mounted() {
        try {
            EventBus.$on(
                "changeHostId",
                async function (hostId) {
                    this.hostId = hostId;
                    this.items = await this.getItems(this.from, new Date());
                }.bind(this)
            );
            this.items = await this.getItems(this.from, new Date());
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async getItems(from, to) {
            try {
                if (!this.hostId) {
                    return;
                }
                const itemsComponent = new ItemsComponent();
                this.isLoading = true;
                const items = await itemsComponent.getItems(
                    this.$apollo,
                    undefined,
                    this.hostId,
                    undefined,
                    undefined,
                    from,
                    to,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    [
                        OrderStatusEnum.Received,
                        OrderStatusEnum.Paid,
                        OrderStatusEnum.Completed,
                    ]
                );
                this.isLoading = false;
                if (!items) {
                    return;
                }
                return items;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
    computed: {
        salesCount() {
            try {
                if (!this.items) {
                    return 0;
                }
                let salesCount = 0;
                this.items.forEach((i) => (salesCount += i.Quantity));
                return salesCount;
            } catch (e) {
                console.error(e);
                return 0;
            }
        },
    },
    watch: {
        async hostId() {
            try {
                this.items = await this.getItems(this.from, new Date());
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
