const { AwsS3 } = require("@/lib/graphql/awsS3/AwsS3.ts");

class AwsS3Component {
    async getSignedObjectUrl(apollo, key) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!key) {
                return undefined;
            }
            const signedObjectUrl = await apollo
                .query({
                    query: AwsS3.Queries.SignedObjectUrl,
                    variables: {
                        key: key,
                    },
                })
                .then(({ data }) => data?.signedObjectUrl)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!signedObjectUrl) {
                return undefined;
            }
            return signedObjectUrl;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

module.exports = AwsS3Component;
