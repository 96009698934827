import gql from "graphql-tag";

export const SearchCriteriaAssignments = {
    Queries: {
        SearchCriteriaAssignments: gql`
        query searchCriteriaAssignments($hostSearchCriteriaAssignmentId: ID) {
            searchCriteriaAssignments(hostSearchCriteriaAssignmentId: $hostSearchCriteriaAssignmentId) {
                Id
                SearchCriteriaId
                Value
                HostSearchCriteriaAssignmentId
            }
        }`,
        SearchCriteriaAssignment: gql`
        query searchCriteriaAssignment($hostSearchCriteriaAssignmentId: ID, $searchCriteriaId: ID) {
            searchCriteriaAssignment(hostSearchCriteriaAssignmentId: $hostSearchCriteriaAssignmentId, searchCriteriaId: $searchCriteriaId) {
                Id
                SearchCriteriaId
                Value
                HostSearchCriteriaAssignmentId
            }
        }`,
    },
    Mutations: {
        CreateSearchCriteriaAssignment: gql`
        mutation createSearchCriteriaAssignment($searchCriteriaAssignment: SearchCriteriaAssignmentsInput) {
            createSearchCriteriaAssignment(searchCriteriaAssignment: $searchCriteriaAssignment) {
                IsSuccess
                Message
                StringData
            }
        }`,
    },
};
