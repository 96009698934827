<template lang="pug">
.col-12.mb-3
  h4 {{ searchCriteriaArea.Description }}
  .row
    datasheet-search-criteria-sub-area(
      :host-search-criteria-assignment="hostSearchCriteriaAssignment"
      :add-search-criteria-assignment="addSearchCriteriaAssignment"
      :delete-search-criteria-assignment="deleteSearchCriteriaAssignment"
      :search-criterias="searchCriterias"
      :search-criteria-sub-area="searchCriteriaSubArea"
      v-for="searchCriteriaSubArea in searchCriteriaSubAreas"
    )

</template>

<script>
import DatasheetSearchCriteriaSubArea from "@/views/pages/account/datasheet/datasheet-search-criteria-sub-area.vue";
import { SearchCriteriaSubAreas } from "@/lib/graphql/searchCriteriaSubAreas/SearchCriteriaSubAreas.ts";

export default {
    name: "datasheet-search-criteria-area",
    components: { DatasheetSearchCriteriaSubArea },
    props: {
        searchCriteriaArea: {
            type: Object,
            required: true,
        },
        searchCriterias: {
            type: Array,
            required: true,
        },
        hostSearchCriteriaAssignment: {
            type: Object,
            required: false,
        },
        addSearchCriteriaAssignment: {
            type: Function,
            required: true,
        },
        deleteSearchCriteriaAssignment: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            searchCriteriaSubAreas: [],
        };
    },
    async mounted() {
        try {
            await this.loadSearchCriteriaSubAreas();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadSearchCriteriaSubAreas() {
            try {
                const searchCriteriaSubAreas = await this.$apollo
                    .query({
                        query: SearchCriteriaSubAreas.Queries
                            .SearchCriteriaSubAreas,
                        variables: {
                            searchCriteriaAreaId: this.searchCriteriaArea.Id,
                            isActive: true,
                        },
                    })
                    .then(({ data }) => data?.searchCriteriaSubAreas)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!searchCriteriaSubAreas) {
                    return;
                }
                this.searchCriteriaSubAreas = searchCriteriaSubAreas;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>
