<template lang="pug">
.wrapper
  tD-loading-overlay(v-if="$apollo.loading")
  .row
    .col-12
      h2 {{$t("account.emailSettings.headline")}}
  .row.mt-2
    .col-12
      .section-headline {{$t("account.emailSettings.sectionHeadline")}}
      p {{$t("account.emailSettings.textEmailSettings")}}
  .row(v-if="emailSettings")
    .col-12
      template(v-for="emailSetting in emailSettings")
        .form-group.form-check
          input.form-check-input(type="checkbox", v-model="hostEmailSettingsIds" :value="emailSetting.Id")
          label.form-check-label {{emailSetting.DescriptionForHost}}

  .row.mt-3
    .col-12.float-right
      button.button.button-primary.button-tdays.float-right(@click="saveHostEmailSettings")
        | Speichern
</template>

<script>
import { GetEmailSettingsQuery } from "../../../lib/graphql/queries/emailSettings/GetEmailSettingsQuery";
import { SaveHostEmailSettingsMutation } from "../../../lib/graphql/mutations/saveHostEmailSettings/SaveHostEmailSettingsMutation";
import { GetHostEmailSettingsQuery } from "../../../lib/graphql/queries/hostEmailSettings/GetHostEmailSettingsQuery";
import EventBus from "../../event-bus";

export default {
    name: "email-settings",
    data() {
        return {
            emailSettings: {},
            hostEmailSettingsIds: [],
            hostEmailSettings: [],
            hostId: this.$session.get("hostId"),
        };
    },
    mounted() {
        EventBus.$on(
            "changeHostId",
            function (hostId) {
                this.hostId = hostId;
            }.bind(this)
        );
    },
    methods: {
        saveHostEmailSettings() {
            try {
                this.$apollo
                    .mutate({
                        mutation: SaveHostEmailSettingsMutation,
                        variables: {
                            hostId: this.hostId,
                            emailSettingsIds: this.hostEmailSettingsIds,
                        },
                    })
                    .then((response) => {
                        if (
                            !response ||
                            !response.data ||
                            !response.data.SaveHostEmailSettings
                        ) {
                            this.$alert(
                                "Die E-Mail Einstellungen konnten nicht gespeichert werden!"
                            );
                            return;
                        }
                        this.$apollo.queries.hostEmailSettings.refetch();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$alert(error.message);
                    });
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
    },
    apollo: {
        emailSettings: {
            query: GetEmailSettingsQuery,
            loadingKey: "loading",
            variables() {
                return {
                    id: this.hostId,
                };
            },
            error(error) {
                this.$alert(error.message);
            },
        },
        hostEmailSettings: {
            query: GetHostEmailSettingsQuery,
            loadingKey: "loading",
            variables() {
                return {
                    hostId: this.hostId,
                };
            },
            result(response) {
                if (
                    !response ||
                    !response.data ||
                    !response.data.hostEmailSettings
                ) {
                    return;
                }
                response.data.hostEmailSettings.forEach((s) =>
                    this.hostEmailSettingsIds.push(s.EmailSettingId)
                );
            },
        },
    },
};
</script>

<style scoped></style>
