<template lang="pug">
    .host-stats-turnover-value.widget()
        .card
            .card-body
                .card-title
                    .row
                        .col-12
                            .text-left
                                h5 {{ Headline }}
                .card-text.p-4(v-if="ShowPaidOut")
                    .text-center
                        .sales-text(v-if="isLoading") Lädt...
                        h4.sales-text(v-else) {{ hostStatsPaidOut.PaidOut | formatEuro }}
                        span.badge.badge-secondary(v-if="ShowCount") {{ hostStatsPaidOut.Vouchers }} Gutscheine
                .card-text.p-4(v-else-if="nextMonth")
                    .text-center
                        .sales-text(v-if="isLoading") Lädt...
                        h4.sales-text(v-else) {{ hostStatsPaidOut.PaidOut | formatEuro }}
                        span.badge.badge-secondary(v-if="ShowCount" ) {{ hostStatsPaidOut.Vouchers }} Gutscheine
                .card-text.p-4(v-else)
                    .text-center
                        .sales-text(v-if="isLoading") Lädt...
                        h4.sales-text(v-else) {{ hostStatsPaidOut.Turnover | formatEuro }}
                        span.badge.badge-secondary(v-if="ShowCount" ) {{ hostStatsPaidOut.Vouchers }} Gutscheine
</template>
<script>
import EventBus from "@/views/event-bus";
import gql from "graphql-tag";

export default {
    name: "host-stats-turnover-value.widget.vue",
    props: {
        ShowCount: {
            type: Boolean,
            required: true,
        },
        Headline: {
            type: String,
            required: true,
        },
        ShowPaidOut: {
            type: Boolean,
            required: false,
            default: false,
        },
        nextMonth: {
            type: Boolean,
            required: false,
            default: false,
        },
        lastXDays: {
            type: Number,
            required: false,
            default: 0,
        },
        statusArray: {
            type: Array,
            required: true,
            default: () => [2, 4, 5], //VoucherStatus
        },
        payoutDateHasValue: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            hostId: this.$session.get("hostId"),
            hostStatsPaidOut: null,
            isLoading: false,
        };
    },
    async mounted() {
        try {
            EventBus.$on(
                "changeHostId",
                async function (hostId) {
                    this.hostId = hostId;
                    //this.loadSalesValue();
                }.bind(this)
            );
            //this.loadSalesValue();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    apollo: {
        hostStatsPaidOut: {
            query: gql`
                query HostStatsPaidOut(
                    $hostId: Int
                    $nextMonth: Boolean
                    $lastXDays: Int
                    $statusArray: [Int]
                    $payoutDateHasValue: Boolean
                ) {
                    hostStatsPaidOut(
                        hostId: $hostId
                        nextMonth: $nextMonth
                        lastXDays: $lastXDays
                        statusArray: $statusArray
                        payoutDateHasValue: $payoutDateHasValue
                    ) {
                        Vouchers
                        Turnover
                        PaidOut
                    }
                }
            `,
            variables() {
                // Use vue reactive properties here
                return {
                    hostId: parseInt(this.hostId),
                    nextMonth: this.nextMonth,
                    statusArray: this.statusArray,
                    lastXDays: this.lastXDays,
                    payoutDateHasValue: this.payoutDateHasValue,
                };
            },
        },
    },
};
</script>
<style scoped></style>
