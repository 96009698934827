<template lang="pug">
  .request-panel-booking-date-request-table-column
    .row.m-0.d-flex.align-items-center(v-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesRequested")
      .col
        img.emoji(v-if="[BookingDateRequestStatusEnum.DateCanBeBooked, BookingDateRequestStatusEnum.DateBooked].includes(bookingDateRequest.BookingDateRequestStatusId)" src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Dashboard/happy.png")
        img.emoji(v-else-if="bookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateRejected" src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Dashboard/50-Tear1.png")
      .col
        span {{ bookingDateRequest.Date | formatDateWithDay($t("lang")) }} - {{ moment(bookingDateRequest.Date).add(product.Uebernachtungen, "days") | formatDateWithDay($t("lang")) }}
      .col.d-flex
        .form-check
          input.form-check-input(
            type="radio"
            v-model="bookingDateRequest.BookingDateRequestStatusId"
            :value="bookingTicket.IsBindingBooking ? BookingDateRequestStatusEnum.DateBooked : BookingDateRequestStatusEnum.DateCanBeBooked"
            :id="'accept-' + bookingDateRequest.Id"
            @change="changeBookingDateRequestStatus(bookingDateRequest)"
          )
          label.form-check-label(:for="'accept-' + bookingDateRequest.Id") {{ $t("constants.confirm.y") }}
        .form-check.ml-3
          input.form-check-input(
            type="radio"
            v-model="bookingDateRequest.BookingDateRequestStatusId"
            :value="BookingDateRequestStatusEnum.DateRejected"
            @change="changeBookingDateRequestStatus(bookingDateRequest)"
            :id="'decline-' + bookingDateRequest.Id"
          )
          label.form-check-label(:for="'decline-' + bookingDateRequest.Id") {{ $t("constants.confirm.n") }}
      //.col
        select.form-control(v-model="bookingDateRequest.RefusalReason" :disabled="bookingDateRequest.BookingDateRequestStatusId != BookingDateRequestStatusEnum.DateRejected")
          option(:value="undefined || null") {{ $t("touriBook.StatusType.Reason") }}
          option(:value="1") {{ $t("touriBook.StatusType.RefusalReason1") }}
          option(:value="2") {{ $t("touriBook.StatusType.RefusalReason2") }}
          option(:value="3") {{ $t("touriBook.StatusType.RefusalReason3") }}

    template(v-else)
      span
        b {{ bookingDateRequest.Date | formatDateWithDay($t("lang")) }} - {{ moment(bookingDateRequest.Date).add(product.Uebernachtungen, "days") | formatDateWithDay($t("lang")) }}&nbsp;
        span(v-if="bookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateCanBeBooked") ({{ $t("touriBook.bookingDateRequestStatus.DateCanBeBooked") }})
        span(v-else-if="bookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateBooked") ({{ $t("touriBook.bookingDateRequestStatus.DateBooked") }})
        span(v-else-if="bookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateRejected") ({{ $t("touriBook.bookingDateRequestStatus.DateRejected") }})
        span(v-else-if="bookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateCancelRequested") ({{ $t("touriBook.bookingDateRequestStatus.DateCancelRequested") }})
        span(v-else-if="bookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateCancelled") ({{ $t("touriBook.bookingDateRequestStatus.DateCancelled") }})
      br(v-if="bookingDateRequests.indexOf(bookingDateRequest) < bookingDateRequests.length - 1")

</template>

<script>
import { BookingDateRequestStatusEnum } from "@/lib/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";
import moment from "moment";
import { BookingTicketStatusEnum } from "@/lib/enums/bookingTicketStatus/BookingTicketStatusEnum";

export default {
    name: "RequestPanelBookingDateRequestTableColumn",
    props: {
        bookingTicket: {
            type: Object,
            required: true,
        },
        bookingDateRequest: {
            type: Object,
            required: true,
        },
        bookingDateRequests: {
            type: Array,
            required: true,
        },
        product: {
            type: Object,
            required: true,
        },
        changeBookingDateRequestStatus: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            moment: moment,
            BookingDateRequestStatusEnum: BookingDateRequestStatusEnum,
            BookingTicketStatusEnum: BookingTicketStatusEnum,
        };
    },
};
</script>

<style scoped></style>
