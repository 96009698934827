<template lang="pug">
#tD-loading-wrapper
  #tD-loading-gradient
    #svg-position
      object(data="https://d1xkfcniu0czj4.cloudfront.net/testing/img/tD-loading.svg" type="image/svg+xml")
        <!-- Fallback -->
        | Ihr Browser kann leider keine svg darstellen!

</template>

<script>
export default {
    name: "tD-loading",
};
</script>
