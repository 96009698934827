import gql from "graphql-tag";

export const Stock = {
    Queries: {
        Stock: gql`
        query stock($stockTypeId: ID, $productId: ID, $platformId: ID, $cutoffDate: Date) {
            stock(stockTypeId: $stockTypeId, productId: $productId, platformId: $platformId, cutoffDate: $cutoffDate) {
                Base
                Available
                Reserved
                Paid
            }
        }`,
    },
};
