import gql from "graphql-tag";

export const SaveHostEmailSettingsMutation = gql`
    mutation SaveHostEmailSettings($hostId: ID, $emailSettingsIds: [ID]) {
        SaveHostEmailSettings(
            hostId: $hostId
            emailSettingsIds: $emailSettingsIds
        )
    }
`;
