const {
    BookingRequests,
} = require("@/lib/graphql/bookingRequests/BookingRequests.ts");

class BookingRequestsComponent {
    async getBookingRequest(apollo, id) {
        try {
            if (!apollo || !id) {
                return undefined;
            }
            const bookingRequest = await apollo
                .query({
                    query: BookingRequests.Queries.BookingRequest,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: id,
                    },
                })
                .then(({ data }) => data?.bookingRequest)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return bookingRequest;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async saveBookingRequest(
        apollo,
        bookingRequest,
        vouchers,
        bookingTicket,
        bookingDateRequests
    ) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!bookingRequest) {
                return {
                    IsSuccess: false,
                    Message: "Keine Buchungsanfrage gefunden.",
                };
            }
            if (!vouchers) {
                return {
                    IsSuccess: false,
                    Message: "Keine Gutscheine gefunden.",
                };
            }
            if (!bookingTicket) {
                return {
                    IsSuccess: false,
                    Message: "Kein Buchungsticket gefunden.",
                };
            }
            if (!bookingDateRequests) {
                return {
                    IsSuccess: false,
                    Message: "Keine Buchungsdaten gefunden.",
                };
            }
            const savedBookingRequest = await apollo
                .mutate({
                    mutation: BookingRequests.Mutations.SaveBookingRequest,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingRequest: bookingRequest,
                        vouchers: vouchers,
                        bookingTicket: bookingTicket,
                        bookingDateRequests: bookingDateRequests,
                    },
                })
                .then(({ data }) => data?.saveBookingRequest)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedBookingRequest) {
                return {
                    IsSuccess: false,
                    Message:
                        "Die Buchungsanfrage konnte nicht gespeichert werden.",
                };
            }
            return savedBookingRequest;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}

module.exports = BookingRequestsComponent;
