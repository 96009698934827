import gql from "graphql-tag";

export const ResetUserPasswordSessions = {
    Queries: {
        ResetUserPasswordSession: gql`
        query resetUserPasswordSession($guid: String, $userGuid: String) {
            resetUserPasswordSession(guid: $guid, userGuid: $userGuid) {
                Id
                Guid
                UserGuid
                ValidTill
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
                IsDone
            }
        }`,
    },
};
