<template lang="pug">
  .wrapper
    tD-loading-overlay(v-if="$apollo.loading")
    .row
      .col-12
        h2 {{$t("account.settlements.headline")}}
    .row.mt-2
      .col-12.col-sm-6.col-lg-4
        pagesize(:isSettlement="true")
    .row.mt-4
      .col-12
        .table-responsive
          table.table.table-bordered.text-center.w-100.d-table
            thead
              tr
                th(scope="col") {{$t("account.settlements.table.invoiceNumber")}}
                th(scope="col") {{$t("account.settlements.table.date")}}
                th(scope="col") {{$t("account.settlements.table.totalAmount")}}
                th(scope="col") {{$t("account.settlements.table.invoicePDF")}}
            tbody(v-if="settlementsForHost && settlementsForHost.length > 0")
              tr(v-for="settlement in settlementsForHost" v-if="moment().isSameOrAfter(settlement.Vorgangdatum)")
                td {{settlement.ID}}
                td {{settlement.Vorgangdatum | formatDate }}
                td {{settlement.Sum | formatEuro }}
                td
                  button.button.button-primary.button-tdays(@click="openSignedObjectUrl(`settlements/Rechnung_${settlement.ID}.pdf`)" :disabled="settlement.System == 'EAD'" :class="{ 'disabled': settlement.System == 'EAD' }") {{$t("account.documents.table.showPdf")}}

    pagination(v-if="!$apollo.loading" :isSettlement="true" :DataCount="this.settlementsForHostCount" :PageSize="this.pageSize" :DataCountText="'account.settlements.count'")
</template>

<script>
import EventBus from "@/views/event-bus";
import { GetHostSettlementsQuery } from "@/lib/graphql/queries/hosts/GetHostSettlements";
import { GetHostSettlementsCountQuery } from "@/lib/graphql/queries/hosts/GetHostSettlementsCount";
import moment from "moment";
import AwsS3Component from "@/lib/components/awsS3/AwsS3Component";

export default {
    name: "account-settlements",
    props: {
        slug: String,
    },
    data() {
        return {
            settlementsForHost: [],
            settlementsForHostCount: 0,
            hostId: this.$session.get("hostId"),
            moment: moment,
            pageSize: 10,
            offset: 0,
        };
    },
    mounted() {
        EventBus.$on(
            "changeHostId",
            function (hostId) {
                this.hostId = hostId;
            }.bind(this)
        );
        EventBus.$on(
            "changeSettlementOffset",
            function (offset) {
                this.offset = offset;
            }.bind(this)
        );
        EventBus.$on(
            "changeSettlementPageSize",
            function (pageSize) {
                this.pageSize = parseFloat(pageSize);
            }.bind(this)
        );
    },
    methods: {
        setPageLimit() {
            this.pageSize = parseInt(this.privatePageSize);
            EventBus.$emit("changeSettlementPageSize", this.pageSize);
        },
        async openSignedObjectUrl(key) {
            try {
                if (!key) {
                    return this.$alert(
                        "Kein Key zum generieren der Url gefunden."
                    );
                }
                const awsS3Component = new AwsS3Component();
                EventBus.$emit("changeLoadingState", true);
                const signedObjectUrl = await awsS3Component.getSignedObjectUrl(
                    this.$apollo,
                    key
                );
                EventBus.$emit("changeLoadingState", false);
                if (!signedObjectUrl) {
                    return this.$alert("Es konnte keine Url generiert werden.");
                }
                window.open(signedObjectUrl, "_blank").focus();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    apollo: {
        settlementsForHost: {
            query: GetHostSettlementsQuery,
            loadingKey: "loading",
            variables() {
                return {
                    host: this.hostId,
                    offset: this.offset,
                    limit: this.pageSize,
                };
            },
        },
        settlementsForHostCount: {
            query: GetHostSettlementsCountQuery,
            loadingKey: "loading",
            variables() {
                return {
                    host: this.hostId,
                };
            },
            result(response) {
                if (
                    !response ||
                    !response.data ||
                    !response.data.settlementsForHostCount
                ) {
                    this.$alert(
                        "Es konnten keine Abrechnungen gefunden werden!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                EventBus.$emit(
                    "changeSettlementDataCount",
                    response.data.settlementsForHostCount
                );
            },
        },
    },
};
</script>

<style scoped></style>
