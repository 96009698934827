<template lang="pug">
.wrapper
  tD-loading-overlay(v-if="$apollo.loading")
  .row
    .col-12
      h2 {{$t("account.data.headline")}}
  .row.mt-2
    .col-12
      .section-headline {{$t("account.data.hostHeadline")}}
  .row
    .col-12.col-md-4
      .form-group
        label.form-label {{$t("account.data.hostNumber")}}
        input.form-control.custom-control(type="text", disabled="true", :value="host.Id")
    .col-12.col-md-4
      .form-group
        label.form-label {{$t("account.data.hostProvision")}}
        input.form-control.custom-control(type="text", disabled="true", :value="host.ProvisionFP | formatPercentage")
    .col-12.col-md-4
      .form-group
        label.form-label {{$t("account.data.hostHandlingFee")}}
        input.form-control.custom-control(type="text", disabled="true", :value="host.Verkaufsgebuehren | formatEuro")
  .row
    .col-12.col-md-4.mt-2
      .row
        .col-12
          .section-headline {{$t("account.data.hostAddressHeadline")}}
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostTypeHeadline")}}
            select.custom-select
              option {{$t("account.data.hostTypeHotel")}}
              option {{$t("account.data.hostTypeApartment")}}
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostName")}}
            input.form-control.custom-control(type="text", v-model="host.Match1")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostContactPerson")}}
            input.form-control.custom-control(type="text", v-model="host.PAdresse3")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostAddress")}}
            input.form-control.custom-control(type="text", v-model="host.PAdresse4")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostZip")}}
            input.form-control.custom-control(type="text", v-model="host.PPLZ")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostCity")}}
            input.form-control.custom-control(type="text", v-model="host.PORT")
        .col-12
          .form-group
            label.form-label.w-100 {{$t("account.data.hostCountry")}}
              select.custom-select
                option(selected="host.PLAND == 'DEU'") {{$t("constants.countries.de")}}
                option {{$t("constants.countries..be")}}
                option {{$t("constants.countries.fr")}}
                option {{$t("constants.countries.it")}}
                option {{$t("constants.countries.lu")}}
                option {{$t("constants.countries.nl")}}
                option {{$t("constants.countries.at")}}
                option {{$t("constants.countries.pl")}}
                option {{$t("constants.countries.ch")}}
    .col-12.col-md-4.mt-2
      .row
        .col-12
          .section-headline {{$t("account.data.hostInvoiceHeadline")}}
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostInvoiceName")}}
            input.form-control.custom-control(type="text", v-model="host.RAdresse1")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostInvoiceName2")}}
            input.form-control.custom-control(type="text", v-model="host.RAdresse2")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostInvoiceCEO")}}
            input.form-control.custom-control(type="text", v-model="host.RAdresse3")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostInvoiceAddress")}}
            input.form-control.custom-control(type="text", v-model="host.RAdresse4")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostInvoiceZip")}}
            input.form-control.custom-control(type="text", v-model="host.RPLZ")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostInvoiceCity")}}
            input.form-control.custom-control(type="text", v-model="host.RORT")
        .col-12
          .form-group
            label.form-label.w-100 Land
            select.custom-select(v-model="host.RLAND")
              option(selected="host.RLAND == 'DEU'") {{$t("constants.countries.de")}}
              option {{$t("constants.countries.be")}}
              option {{$t("constants.countries.fr")}}
              option {{$t("constants.countries.it")}}
              option {{$t("constants.countries.lu")}}
              option {{$t("constants.countries.nl")}}
              option {{$t("constants.countries.at")}}
              option {{$t("constants.countries.pl")}}
              option {{$t("constants.countries.ch")}}
    .col-12.col-md-4.mt-2
      .row
        .col-12
          .section-headline {{$t("account.data.hostContactHeadline")}}
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostContactPhone1")}}
            input.form-control.custom-control(type="text", v-model="host.TELEFON1")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostContactPhone2")}}
            input.form-control.custom-control(type="text", v-model="host.TELEFON2")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostContactTelefax")}}
            input.form-control.custom-control(type="text", v-model="host.TELEFAX1")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostContactPersonTouriDat")}}
            input.form-control.custom-control(type="text", v-model="host.Ansprechpartner")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostContactPersonTouriDatEmail")}}
            input.form-control.custom-control(type="text", v-model="host.EMAIL2")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostContactEmailGuest")}}
            input.form-control.custom-control(type="text", v-model="host.EMAIL1")
        .col-12
          .form-group
            label.form-label {{$t("account.data.hostContactHomepage")}}
            input.form-control.custom-control(type="text", v-model="host.Internetadresse2")
      .row
        .col-12
          button.button.button-primary.button-tdays.float-right(@click="saveHostData")
            | {{$t("constants.buttons.submitChanges")}}

    .col-12.mt-3
      .panel.panel-default
        .panel-heading Bankverbindung
        .panel-body
          .row
            .col-12
              label.form-label(for="Iban")
              input#Iban.form-control(type="text" v-model="iban" disabled)
            .col-12
              label.form-label(for="AccountOwner")
              input#AccountOwner.form-control(type="text" v-model="host.AccountOwner" disabled)

            .col-12.mt-3
              button.button.button-primary.button-tdays.float-right(@click="editAccountInformation")
                | Bankverbindung editieren


</template>

<script>
import { GetHostQuery } from "../../../lib/graphql/queries/hosts/GetHostQuery";
import { CreateHostChangeTodoMutation } from "../../../lib/graphql/mutations/todos/CreateHostChangeTodoMutation";
import EventBus from "../../../views/event-bus";
import { IBAN } from "ibankit";
import HostAccountInformationModal from "../../modals/hostAccountInformation/HostAccountInformationModal";

export default {
    name: "account-data",
    props: {
        slug: String,
    },
    data() {
        return {
            hostId: this.$session.get("hostId"),
            host: {},

            iban: "",
        };
    },
    mounted() {
        EventBus.$on(
            "changeHostId",
            function (hostId) {
                this.hostId = hostId;
            }.bind(this)
        );
        EventBus.$on(
            "updateHostData",
            function () {
                this.$apollo.queries.host.refetch();
            }.bind(this)
        );
    },
    methods: {
        saveHostData() {
            this.$confirm(
                this.$t("constants.messages.saveChangedHostData").toString(),
                this.$t("constants.messages.questionTitle").toString()
            ).then(() => {
                try {
                    delete this.host.__typename;
                    this.$apollo
                        .mutate({
                            mutation: CreateHostChangeTodoMutation,
                            variables: {
                                host: this.host,
                            },
                        })
                        .then((response) => {
                            if (
                                !response ||
                                !response.data ||
                                !response.data.CreateHostChangeTodo
                            ) {
                                this.$alert(
                                    this.$t(
                                        "constants.messages.hostDataChangesNotSend"
                                    ).toString(),
                                    this.$t(
                                        "constants.messages.alertTitle"
                                    ).toString()
                                );
                                return;
                            }
                            this.$alert(
                                this.$t(
                                    "constants.messages.requestDataChangesSend"
                                ).toString(),
                                this.$t(
                                    "constants.messages.sendTitle"
                                ).toString()
                            );
                        })
                        .catch((error) => {
                            this.$alert(
                                error.message,
                                this.$t(
                                    "constants.messages.alertTitle"
                                ).toString()
                            );
                            console.log(error);
                        });
                } catch (e) {
                    this.$alert(
                        e.message,
                        this.$t("constants.messages.alertTitle").toString()
                    );
                    console.log(e);
                }
            });
        },
        editAccountInformation() {
            try {
                this.$modal.show(
                    HostAccountInformationModal,
                    {
                        HostId: this.hostId,
                    },
                    {
                        width: "400",
                        height: "300",
                    }
                );
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
    },
    apollo: {
        host: {
            query: GetHostQuery,
            loadingKey: "loading",
            fetchPolicy: "no-cache",
            variables() {
                return {
                    id: this.hostId,
                };
            },
            result(response) {
                if (!response || !response.data || !response.data.host) {
                    return;
                }

                const host = response.data.host;
                if (!host) {
                    return;
                }

                let tempIban = "";
                if (host.IBAN) {
                    const iban = IBAN.printFormat(host.IBAN);
                    const ibanParts = iban.split(" ");
                    for (const ibanPart of ibanParts) {
                        tempIban += tempIban.length == 0 ? "" : " ";
                        if (
                            ibanParts.indexOf(ibanPart) == 0 ||
                            ibanParts.indexOf(ibanPart) == ibanParts.length - 1
                        ) {
                            tempIban += ibanPart.toString();
                        } else {
                            tempIban += "····";
                        }
                    }
                } else {
                    tempIban = "-";
                }
                this.iban = tempIban;
            },
        },
    },
};
</script>

<style scoped></style>
