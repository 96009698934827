import gql from "graphql-tag";

export const GetBookingTicketStatusQuery = gql`
    query GetBookingTicketStatus {
        BookingTicketStatus {
            Id
            Description
            DisplayedDescriptionHost
        }
    }
`;
