import gql from "graphql-tag";

export const Orders = {
    Queries: {
        Order: gql`
        query order($id: ID) {
            order(id: $id) {
                Id
                OrderId
                CustomerID
                CreatedAt
                PaidAt
                OrderDate
                TotalInvoiceGross
                AlreadyPaid
                PaymentStatusId
                UpdatedAt
                Cancelled
                CancelDate
                Country
                OrderStatusId
                ConfirmationMailSentDate
                VoucherMailSentDate
                CancelMailSentDate
                GoodsIssueBookedOn
            }
        }`,
        OrderByVoucherId: gql`
        query orderByVoucherId($voucherId: ID) {
            orderByVoucherId(voucherId: $voucherId) {
                Id
                OrderId
                CustomerID
                CreatedAt
                PaidAt
                OrderDate
                TotalInvoiceGross
                AlreadyPaid
                PaymentStatusId
                UpdatedAt
                Cancelled
                CancelDate
                Country
                OrderStatusId
                ConfirmationMailSentDate
                VoucherMailSentDate
                CancelMailSentDate
                GoodsIssueBookedOn
            }
        }`,
    },
};
