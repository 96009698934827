import gql from "graphql-tag";

export const VoucherStatus = {
    Queries: {
        VoucherStatus: gql`
        query voucherStatus {
            voucherStatus {
                Id
                Description
            }
        }`,
    }
}
