<template lang="pug">
  .container(style="margin-top: 35vh;")
    .row.mt-3(v-if="!user")
      .col-12.text-center
        h2 {{$t("reset-password.headline")}}
      .col-sm-2.col-lg-3
      .col-12.col-sm-8.col-lg-6
        .form-group
          label.form-label {{$t("reset-password.form.email")}}
          input.form-control.custom-control(v-model="email" :placeholder="this.$t('reset-password.form.email')" v-on:keyup.enter="resetPassword")

        button.button.button-primary.button-tdays.w-100(@click="resetPassword")
          | {{$t("reset-password.button.sendMail")}}

    .row.mt-3(v-else)
      .col-12
        h2 {{$t("account.security.headline")}}
      .col-12.mt-2
        .section-headline {{$t("account.security.sectionHeadline")}}
        p {{$t("account.security.textChangePassword")}}
      .col-12.col-sm-6
        .form-group
          label.form-label {{$t("account.security.enterNewPassword")}}
          input.form-control.custom-control(type="password" v-model="password")
      .col-12.col-sm-6
        .form-group
          label.form-label {{$t("account.security.repeatNewPassword")}}
          input.form-control.custom-control(type="password" v-model="confirmPassword" v-on:keyup.enter="updatePassword")
      .col-12
        button.button.button-primary.button-tdays.float-right(@click="updatePassword")
          | {{$t("account.security.buttonChangePassword")}}

</template>

<script>
import EventBus from "../views/event-bus";
import { EmailValidatorComponent } from "../lib/components/emailValidator/EmailValidatorComponent";
import { PasswordValidatorComponent } from "../lib/components/passwordValidator/PasswordValidatorComponent";
import { Users } from "../lib/graphql/users/Users.ts";
import { ResetUserPasswordSessions } from "@/lib/graphql/resetUserPasswordSessions/ResetUserPasswordSessions.ts";
import moment from "moment";
import UsersComponent from "@/lib/components/users/UsersComponent";

export default {
    name: "ResetPassword",
    data() {
        return {
            user: undefined,
            password: "",
            confirmPassword: "",

            email: "",
        };
    },
    async mounted() {
        try {
            if (!this.$route.query.guid || !this.$route.query.user) {
                return;
            }
            EventBus.$emit("changeLoadingState", true);
            const resetUserPasswordSession = await this.$apollo
                .query({
                    query: ResetUserPasswordSessions.Queries
                        .ResetUserPasswordSession,
                    fetchPolicy: "no-cache",
                    variables: {
                        guid: this.$route.query.guid,
                        userGuid: this.$route.query.user,
                    },
                })
                .then(({ data }) => data?.resetUserPasswordSession)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            EventBus.$emit("changeLoadingState", false);
            if (!resetUserPasswordSession) {
                this.$alert("Es konnte keine Passwort-Session geladen werden.");
                return await this.$router.push({ name: "Login" });
            }
            if (moment().isAfter(resetUserPasswordSession.ValidTill)) {
                this.$alert("Die Passwort-Session ist bereits abgelaufen.");
                return await this.$router.push({ name: "Login" });
            }
            if (resetUserPasswordSession.IsDone) {
                this.$alert("Die Passwort-Session ist bereits erledigt.");
                return await this.$router.push({ name: "Login" });
            }
            const user = await this.getUser(resetUserPasswordSession.UserGuid);
            if (!user) {
                this.$alert("Es konnte kein Benutzer geladen werden.");
                return await this.$router.push({ name: "Login" });
            }
            this.user = user;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    },
    methods: {
        async getUser(userGuid) {
            try {
                const user = await this.$apollo
                    .query({
                        query: Users.Queries.UserByGuid,
                        fetchPolicy: "no-cache",
                        variables: {
                            guid: userGuid,
                        },
                    })
                    .then(({ data }) => data?.userByGuid)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                return user;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        async updatePassword() {
            try {
                const confirmed = await this.$confirm(
                    this.$t("constants.messages.savePassword").toString(),
                    this.$t("constants.messages.questionTitle").toString()
                )
                    .then()
                    .catch((e) => e);
                if (!confirmed) {
                    return;
                }
                if (this.password.length == 0) {
                    return this.$alert(
                        this.$t(
                            "constants.messages.passwordNotFilled"
                        ).toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (this.confirmPassword.length == 0) {
                    return this.$alert(
                        this.$t(
                            "constants.messages.passwordRepeatNotFilled"
                        ).toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (this.password != this.confirmPassword) {
                    return this.$alert(
                        this.$t(
                            "constants.messages.passwordsDoNotMatch"
                        ).toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                const validatedPassword = this._validatePassword(this.password);
                if (!validatedPassword) {
                    return this.$alert(
                        this.$t(
                            "constants.messages.passwordNotStrongEnough"
                        ).toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                const usersComponent = new UsersComponent();
                EventBus.$emit("changeLoadingState", true);
                const updatedUserPassword =
                    await usersComponent.updateUserPassword(
                        this.$apollo,
                        this.user.Id,
                        this.password
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!updatedUserPassword) {
                    return this.$alert(
                        "Das Password konnte nicht aktualisiert werden",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (updatedUserPassword.Message) {
                    if (updatedUserPassword.IsSuccess) {
                        this.$alert(
                            updatedUserPassword.Message,
                            this.$t(
                                "constants.messages.successTitle"
                            ).toString()
                        );
                    } else {
                        this.$alert(
                            updatedUserPassword.Message,
                            this.$t("constants.messages.alertTitle").toString()
                        );
                    }
                }
                if (updatedUserPassword.IsSuccess) {
                    return await this.$router.push({ name: "Login" });
                }
            } catch (e) {
                EventBus.$emit("changeLoadingState", false);
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
        async resetPassword() {
            try {
                this.email = this.email.trim();
                if (this.email.length == 0) {
                    return this.$alert(
                        this.$t("login.errorMessages.e7").toString(),
                        this.$t("constants.messages.errorTitle").toString()
                    );
                }
                if (!this._validateEmail(this.email)) {
                    return this.$alert(
                        this.$t("login.errorMessages.e8").toString(),
                        this.$t("constants.messages.errorTitle").toString()
                    );
                }

                EventBus.$emit("changeLoadingState", true);
                const sentResetUserPasswordEmail = await this.$apollo
                    .mutate({
                        mutation: Users.Mutations.SendResetUserPasswordEmail,
                        variables: {
                            email: this.email,
                        },
                    })
                    .then(({ data }) => data?.sendResetUserPasswordEmail)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                EventBus.$emit("changeLoadingState", false);
                if (!sentResetUserPasswordEmail) {
                    return this.$alert(
                        "Die Email konnte nicht gesendet werden, lassen sie eventuell Ihren Account vom touriDat Service prüfen!",
                        "Achtung!"
                    );
                }
                if (!sentResetUserPasswordEmail.IsSuccess) {
                    return this.$alert(
                        "Die E-Mail konnte nicht versendet werden.",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                this.$alert(
                    this.$t("reset-password.emailGotSent").toString(),
                    this.$t("constants.messages.successTitle").toString()
                );
                return await this.$router.push({ name: "Login" });
            } catch (e) {
                console.error(e);
                this.$alert(
                    e.message,
                    this.$t("constants.messages.errorTitle").toString()
                );
                return await this.$router.push({ name: "Login" });
            }
        },
        _validateEmail(email) {
            const emailValidatorComponent = new EmailValidatorComponent();
            return emailValidatorComponent.validateEmail(email);
        },
        _validatePassword(password) {
            const passwordValidatorComponent = new PasswordValidatorComponent();
            return passwordValidatorComponent.validatePassword(password);
        },
    },
};
</script>
