<template lang="pug">
  button.button.button-primary.button-tdays(
    @click="saveVoucher"
    :class="{ 'disabled': isDisabled }"
    :disabled="isDisabled"
  ) {{$t("constants.buttons.saveChanges")}}
</template>

<script>
import { EmailValidatorComponent } from "@/lib/components/emailValidator/EmailValidatorComponent";
import VoucherStatusEnum from "@/lib/enums/voucherStatus/VoucherStatusEnum";
import moment from "moment/moment";
import EventBus from "@/views/event-bus";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";

export default {
    name: "SaveVoucherButton",
    props: {
        voucher: {
            type: Object,
            required: true,
        },
        backupVoucher: {
            type: Object,
            required: false,
        },
        bookingRequest: {
            type: Object,
            required: true,
        },
        loadVoucher: {
            type: Function,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            VoucherStatusEnum: VoucherStatusEnum,
        };
    },
    methods: {
        async saveVoucher() {
            try {
                if (this.voucher.Status == VoucherStatusEnum.Cancelled) {
                    return this.$alert(
                        "Der Gutschein ist storniert!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (!this.bookingRequest.Email) {
                    return this.$alert(
                        "Bitte füllen Sie die E-Mail Adresse!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                const emailValidatorComponent = new EmailValidatorComponent();
                const validationResult = emailValidatorComponent.validateEmail(
                    this.bookingRequest.Email
                );
                if (!validationResult) {
                    return this.$alert(
                        "Die E-Mail Adresse ist nicht gültig!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (!this.bookingRequest.Title) {
                    return this.$alert(
                        "Bitte füllen Sie die Anrede!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (!this.bookingRequest.FirstName) {
                    return this.$alert(
                        "Bitte füllen Sie den Vornamen!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (!this.bookingRequest.LastName) {
                    return this.$alert(
                        "Bitte füllen Sie den Nachnamen!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (!this.voucher.BookedAt) {
                    return this.$alert(
                        "Bitte füllen Sie das Anreisedatum korrekt!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (
                    this.voucher.BookingInfo &&
                    !this.voucher.BookingInfoEditedBy
                ) {
                    return this.$alert(
                        "Falls Sie eine Notiz hinterlegen möchten, bitte geben Sie ebenfalls die bearbeitende Person an.",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                if (
                    !this.voucher.BookingInfo &&
                    this.voucher.BookingInfoEditedBy
                ) {
                    return this.$alert(
                        "Falls Sie eine bearbeitende Person angeben, müssen Sie auch eine Notiz hinterlegen.",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                const blockedHostIds = [31022];
                const isBlocked = blockedHostIds.includes(
                    this.$session.get("hostId")
                );
                const dateChanged =
                    !!this.backupVoucher.BookedAt &&
                    !moment(this.voucher.BookedAt).isSame(
                        this.backupVoucher.BookedAt,
                        "day"
                    );
                if (isBlocked || dateChanged) {
                    const code = await this.$prompt(
                        this.$t("constants.messages.enterCode").toString()
                    )
                        .then((code) => code)
                        .catch((e) => e);
                    if (!code) {
                        return;
                    }
                    if (
                        this.voucher.Guid.slice(-6).toLowerCase() !=
                        code.toLowerCase()
                    ) {
                        return this.$alert(
                            this.$t(
                                "constants.messages.noCodeMatch"
                            ).toString(),
                            this.$t("constants.messages.alertTitle").toString()
                        );
                    }
                } else {
                    const confirmed = await this.$confirm(
                        this.$t("constants.messages.saveChanges").toString(),
                        this.$t("constants.messages.questionTitle").toString()
                    )
                        .then()
                        .catch((e) => e);
                    if (!confirmed) {
                        return;
                    }
                }
                const savedVoucher = await this._saveVoucher(
                    this.voucher,
                    this.bookingRequest
                );
                if (!savedVoucher) {
                    return this.$alert(
                        "Der Gutschein konnte nicht gespeichert werden."
                    );
                }
                if (savedVoucher.Message) {
                    this.$alert(savedVoucher.Message);
                }
                if (savedVoucher.IsSuccess) {
                    EventBus.$emit("updateVouchersList");
                    this.loadVoucher();
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async _saveVoucher(voucher, bookingRequest) {
            try {
                if (!voucher) {
                    return {
                        IsSuccess: false,
                        Message: "Kein Gutschein gefunden.",
                    };
                }
                if (!bookingRequest.Id) {
                    bookingRequest.CreatedBy = this.$session.get("userId");
                } else {
                    bookingRequest.UpdatedBy = this.$session.get("userId");
                }
                const vouchersComponent = new VouchersComponent();
                EventBus.$emit("changeLoadingState", true);
                const savedVoucher = await vouchersComponent.saveVoucher(
                    this.$apollo,
                    voucher,
                    bookingRequest
                );
                EventBus.$emit("changeLoadingState", false);
                if (!savedVoucher) {
                    return {
                        IsSuccess: false,
                        Message:
                            "Der Gutschein konnte nicht gespeichert werden.",
                    };
                }
                return savedVoucher;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
