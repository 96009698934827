import gql from "graphql-tag";

export const BookingDateRequests = {
    Queries: {
        BookingDateRequestsByBookingTicketId: gql`
        query bookingDateRequestsByBookingTicketId($bookingTicketId: ID) {
            bookingDateRequestsByBookingTicketId(bookingTicketId: $bookingTicketId) {
                Id
                Guid
                Date
                BookingDateRequestStatusId
                BookingTicketId
                BookingRequestId
                CreatedAt
                UpdatedAt
                RefusalReason
                Inactive
                BookedByQuota
                ProductQuotaID
                CreatedBy
                UpdatedBy
            }
        }`,
    },
    Mutations: {
        SaveBookingDateRequest: gql`
        mutation saveBookingDateRequest($bookingDateRequest: BookingDateRequestsInput) {
            saveBookingDateRequest(bookingDateRequest: $bookingDateRequest) {
                IsSuccess
                Message
            }
        }`,
    },
};
