<template lang="pug">
  .table-responsive
    table.table.table-bordered
      thead
        tr
          th Name
          th Gesamt
          th Sales
          th Deal-Sales
          th Stornos
      tbody
        template(v-for="option in options")
          sales-evaluations-table-head-row(
            v-b-toggle="option.id"
            :id="option.id"
            :is-host="!!option.isHost"
            :from="moment(from).startOf(displayUnit).toDate()"
            :to="moment(to).endOf(displayUnit).toDate()"
          )
          sales-evaluations-table-sub-table(
            :option="option"
            :dates="dates"
            :display-unit="displayUnit"
          )

</template>

<script>
import moment from "moment";
import SalesEvaluationsTableSubTable from "@/views/pages/sales/SalesEvaluationsTableSubTable.vue";
import SalesEvaluationsTableHeadRow from "@/views/pages/sales/SalesEvaluationsTableHeadRow.vue";

export default {
    name: "SalesEvaluationsTable",
    components: {
        SalesEvaluationsTableHeadRow,
        SalesEvaluationsTableSubTable,
    },
    props: {
        // { name: 'test', id: 1, isHost: true, }
        options: {
            type: Array,
            required: true,
        },
        from: {
            type: Date,
            required: true,
        },
        to: {
            type: Date,
            required: true,
        },
        displayUnit: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            moment: moment,
        };
    },
    computed: {
        dates() {
            try {
                const from = this.from;
                const to = this.to;
                const displayUnit = this.displayUnit;

                const dates = [];
                const difference = moment(to).diff(from, displayUnit);
                if (difference == 0) {
                    dates.push(moment(from).toDate());
                } else {
                    for (let i = 0; i < difference; i++) {
                        dates.push(moment(from).add(i, displayUnit).toDate());
                    }
                }
                return dates;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
};
</script>
