<template lang="pug">
.wrapper
  h2 {{$t("not-found.headline")}}
  p {{$t("not-found.text")}}
  router-link.btn-dark.btn(to="home") {{$t("not-found.button")}}
</template>

<script>
export default {
    name: "not-found",
};
</script>

<style scoped></style>
