<template lang="pug">
  tr(:key="user.Id")
    td {{ user.Email }}
    td {{ user.FirstName }}
    td {{ user.LastName }}
    td
      ul(v-if="availableHosts.length <= 10")
        li.text-left(v-for="availableHost in availableHosts") {{ availableHost.Match1 }}
      ul(v-else)
        a.text-left(data-toggle="collapse" :href="'#toggleHostPoolHosts' + user.Id" role="button" :aria-controls="'toggleHostPoolHosts' + user.Id") Alle Gastgeber anzeigen
        div.collapse(:id="'toggleHostPoolHosts' + user.Id")
          li.text-left(v-for="availableHost in availableHosts") {{ availableHost.Match1 }}
    td
      router-link.button.button-primary.button-tdays(:to="{ path: `/account/users/detail/${user.Id}` }" tag="button")
        font-awesome-icon(:icon="['fas','pencil-alt']")
    td
      button.button.button-primary.button-tpics(@click="deleteUser")
        font-awesome-icon(:icon="['fas','trash']")
</template>

<script>
import EventBus from "../../../../views/event-bus";
import { Users } from "../../../../lib/graphql/users/Users.ts";
import { Hosts } from "../../../../lib/graphql/hosts/Hosts.ts";

export default {
    name: "UsersListTableRow",
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            availableHosts: [],
        };
    },
    async mounted() {
        try {
            await this.loadAvailableHosts();
        } catch (e) {
            console.log(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadAvailableHosts() {
            try {
                const availableHosts = await this.$apollo
                    .query({
                        query: Hosts.Queries.AvailableHosts,
                        variables: {
                            userId: this.user.Id,
                        },
                    })
                    .then(({ data }) => data?.availableHosts)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!availableHosts) {
                    return;
                }
                this.availableHosts = availableHosts;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async deleteUser() {
            try {
                const confirmed = await this.$confirm(
                    this.$t("constants.messages.deleteUser").toString(),
                    this.$t("constants.messages.questionTitle").toString()
                )
                    .then()
                    .catch((e) => e);
                if (!confirmed) {
                    return;
                }
                const deletedUser = await this.$apollo
                    .mutate({
                        mutation: Users.Mutations.DeleteUser,
                        variables: {
                            userId: this.user.Id,
                        },
                    })
                    .then(({ data }) => data?.DeleteUser)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!deletedUser) {
                    return this.$alert(
                        this.$t("constants.messages.UserNotDeleted").toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                this.$alert(
                    this.$t("constants.messages.userDeleted").toString(),
                    this.$t("constants.messages.UserDeletedTitle").toString()
                );
                EventBus.$emit("updateUsersList");
            } catch (e) {
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
    },
};
</script>

<style scoped></style>
