<template lang="pug">
  .tb-panel(v-if="isReady")
    .tb-panel-header
      .row
        .col-12(:class="{ 'col-lg-4': !voucherIds, 'col-lg': voucherIds }")
          .tb-ticket-no {{ $t("touriBook.ticketNo") }}:&nbsp;
            span.font-weight-bold {{ bookingTicket.Id }}
        .col-12(:class="{ 'col-lg-4': !voucherIds, 'col-lg': voucherIds }")
          .tb-ticket-date {{ $t("touriBook.ticketDate") }}:&nbsp;
            span.font-weight-bold {{ bookingTicket.CreatedAt | formatDate }}
        .col-12(:class="{ 'col-lg-4': !voucherIds, 'col-lg': voucherIds }")
          .tb-ticket-voucher-no {{ $t("touriBook.ticketVoucherNo") }}:&nbsp;
            router-link(:to="{ name: 'VoucherDetails', params: { voucherId: voucher.Id } }")
              span.font-weight-bold {{ voucher.Id }}
        .col-12.col-lg(v-if="vouchers && Array.isArray(vouchers) && vouchers.length")
          voucher-group-booking-vouchers.tb-ticket-voucher-no(
            :vouchers="vouchers"
            :show-edit="false"
            :load-vouchers="loadVouchers"
            :booking-request="bookingRequest"
          )

    .tb-panel-body
      .row
        .col-12
          .table-responsive
            table.table-borderless(:class="{ 'table': bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesRequested, 'table-sm': bookingTicket.BookingTicketStatusId != BookingTicketStatusEnum.DatesRequested }")
              tbody
                tr
                  td.align-top {{ $t("touriBook.location") }}:&nbsp;
                  td
                    .font-weight-bold {{ host.Match1 }}
                tr
                  td.align-top {{ $t("touriBook.guestName") }}:&nbsp;
                  td
                    .font-weight-bold {{ bookingRequest.FirstName }} {{ bookingRequest.LastName }}
                    | {{ bookingRequest.Street }}
                    br
                    | {{ bookingRequest.ZipCode }} {{ bookingRequest.City }}
                tr
                  td
                    .tb-ticket-status {{ $t("touriBook.ticketStatus") }}:&nbsp;
                  td.book
                    span(v-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesRequested")
                      .others.cancellation.font-weight-bold(v-if="bookingTicket.IsBindingBooking")
                        | {{ $t("touriBook.bookingTicketStatus." + bookingTicketStatus.Description) }}&nbsp;({{ $t("touriBook.bindingBooking") }})
                      .others.cancellation.font-weight-bold(v-else)
                        | {{ $t("touriBook.bookingTicketStatus." + bookingTicketStatus.Description) }}
                    span(v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCancelled").cancellation.font-weight-bold
                      | {{ $t("touriBook.bookingTicketStatus." + bookingTicketStatus.Description) }}
                    span(v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesBooked").booked.font-weight-bold
                      | {{ $t("touriBook.bookingTicketStatus." + bookingTicketStatus.Description) }}
                    span(v-else).others.cancellation.font-weight-bold
                      | {{ $t("touriBook.bookingTicketStatus." + bookingTicketStatus.Description) }}

                tr
                  td.align-top {{ $t("touriBook.dateRequests") }}:&nbsp;
                  td
                    request-panel-booking-date-request-table-column(
                      v-for="bookingDateRequest in bookingDateRequests"
                      :booking-ticket="bookingTicket"
                      :booking-date-request="bookingDateRequest"
                      :booking-date-requests="bookingDateRequests"
                      :product="product"
                      :change-booking-date-request-status="changeBookingDateRequestStatus"
                      :key="bookingDateRequest.Id"
                    )

      .row
        .col-12
          router-link.button.button-primary.button-tdays.float-none.float-lg-right.mb-1.mr-2(v-if="displayDetailButton" :to="{ name: 'TouribookDetails', params: { bookingTicketId: bookingTicket.Id } }" tag="button")
            | {{ $t("constants.buttons.showDetails") }}
          button.button.button-secondary.button-tdays.float-none.float-lg-right.mb-1.mr-2(@click="saveBookingRequest" v-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesRequested")
            | {{ $t("touriBook.btnSendDates") }}
          button.button.button-secondary.button-tpics.float-none.float-lg-right.mb-1.mr-2(@click="cancelBookingTicket" v-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesBooked")
            | {{ $t("touriBook.btnCancelTicket") }}
          .float-right(v-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCancelRequested")
            button.button.button-secondary.button-tpics.float-none.float-lg-right.mb-1.mr-2(@click="cancelRequestedBookingTicket(false)")
              | {{ $t("touriBook.btnDeclineCancel") }}
            button.button.button-secondary.button-tdays.float-none.float-lg-right.mb-1.mr-2(@click="cancelRequestedBookingTicket(true)")
              | {{ $t("touriBook.btnAcceptCancel") }}

</template>

<script>
import moment from "moment";
import { BookingDateRequestStatusEnum } from "../../../lib/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";
import { BookingTicketStatusEnum } from "../../../lib/enums/bookingTicketStatus/BookingTicketStatusEnum";
import EventBus from "../../event-bus";
import { HostsComponent } from "@/lib/components/hosts/HostsComponent";
import ProductsComponent from "@/lib/components/products/ProductsComponent";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";
import BookingRequestsComponent from "@/lib/components/bookingRequests/BookingRequestsComponent";
import BookingDateRequestsComponent from "@/lib/components/bookingDateRequests/BookingDateRequestsComponent";
import BookingTicketsComponent from "@/lib/components/bookingTickets/BookingTicketsComponent";
import BookingTicketStatusComponent from "@/lib/components/bookingTicketStatus/BookingTicketStatusComponent";
import RequestPanelBookingDateRequestTableColumn from "@/views/pages/touribook/RequestPanelBookingDateRequestTableColumn.vue";
import VoucherGroupBookingVouchers from "@/views/partials/voucherGroupBookings/VoucherGroupBookingVouchers.vue";

export default {
    name: "touribook-request-panel",
    components: {
        VoucherGroupBookingVouchers,
        RequestPanelBookingDateRequestTableColumn,
    },
    props: {
        bookingTicketId: {
            type: String,
            allowNull: false,
        },
        displayDetailButton: Boolean,
    },
    data() {
        return {
            bookingTicket: undefined,
            bookingTicketStatus: undefined,
            bookingRequest: undefined,
            voucher: undefined,
            vouchers: [],
            product: undefined,
            host: undefined,
            bookingDateRequests: [],
            BookingDateRequestStatusEnum: BookingDateRequestStatusEnum,
            BookingTicketStatusEnum: BookingTicketStatusEnum,
            moment: moment,
        };
    },
    async mounted() {
        try {
            this.loadBookingTicket();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadBookingTicket() {
            try {
                const bookingTicketsComponent = new BookingTicketsComponent();
                const bookingTicket =
                    await bookingTicketsComponent.getBookingTicket(
                        this.$apollo,
                        this.bookingTicketId
                    );
                if (!bookingTicket) {
                    return;
                }
                this.bookingTicket = bookingTicket;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTicketStatus() {
            try {
                const bookingTicketStatusComponent =
                    new BookingTicketStatusComponent();
                const bookingTicketStatus =
                    await bookingTicketStatusComponent.getBookingTicketStatusById(
                        this.$apollo,
                        this.bookingTicket?.BookingTicketStatusId
                    );
                if (!bookingTicketStatus) {
                    return;
                }
                this.bookingTicketStatus = bookingTicketStatus;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingDateRequests() {
            try {
                const bookingDateRequestsComponent =
                    new BookingDateRequestsComponent();
                const bookingDateRequests =
                    await bookingDateRequestsComponent.getBookingDateRequests(
                        this.$apollo,
                        this.bookingTicket?.Id
                    );
                if (!bookingDateRequests) {
                    return;
                }
                if (
                    !bookingDateRequests ||
                    !Array.isArray(bookingDateRequests) ||
                    !bookingDateRequests.length
                ) {
                    return;
                }
                this.bookingDateRequests = bookingDateRequests;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        async loadBookingRequest() {
            try {
                const bookingRequestsComponent = new BookingRequestsComponent();
                const bookingRequest =
                    await bookingRequestsComponent.getBookingRequest(
                        this.$apollo,
                        this.bookingTicket?.BookingRequestId
                    );
                if (!bookingRequest) {
                    return;
                }
                this.bookingRequest = bookingRequest;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadVoucher() {
            try {
                const vouchersComponent = new VouchersComponent();
                const voucher = await vouchersComponent.getVoucher(
                    this.$apollo,
                    this.bookingRequest?.VoucherId
                );
                if (!voucher) {
                    return;
                }
                this.voucher = voucher;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadVouchers() {
            try {
                const vouchersComponent = new VouchersComponent();
                const vouchers = await vouchersComponent.getVouchers(
                    this.$apollo,
                    undefined,
                    undefined,
                    this.bookingRequest?.Id
                );
                if (!vouchers) {
                    return;
                }
                this.vouchers = vouchers;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProduct() {
            try {
                const productsComponent = new ProductsComponent();
                const product = await productsComponent.getProduct(
                    this.$apollo,
                    this.voucher?.ProductId
                );
                if (!product) {
                    return;
                }
                this.product = product;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadHost() {
            try {
                const hostsComponent = new HostsComponent();
                const host = await hostsComponent.getHost(
                    this.$apollo,
                    this.product?.HostId
                );
                if (!host) {
                    return;
                }
                this.host = host;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        changeBookingDateRequestStatus(bookingDateRequest) {
            try {
                if (!bookingDateRequest) {
                    return;
                }
                if (!this.bookingTicket.IsBindingBooking) {
                    return;
                }
                if (
                    bookingDateRequest.BookingDateRequestStatusId !=
                    BookingDateRequestStatusEnum.DateBooked
                ) {
                    return;
                }
                this.bookingDateRequests
                    .filter(
                        (b) =>
                            b.Id != bookingDateRequest.Id &&
                            b.BookingDateRequestStatusId ==
                                BookingDateRequestStatusEnum.DateBooked
                    )
                    .forEach(
                        (b) =>
                            (b.BookingDateRequestStatusId =
                                BookingDateRequestStatusEnum.DateRejected)
                    );
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async saveBookingRequest() {
            try {
                if (
                    this.bookingDateRequests.find(
                        (b) =>
                            b.BookingDateRequestStatusId ==
                            BookingDateRequestStatusEnum.DateRequested
                    )
                ) {
                    return this.$alert(
                        "Bitte wählen Sie für alle Datumsanfragen einen Status aus."
                    );
                }
                const confirmed = await this.$confirm(
                    this.$t(
                        "constants.confirm.touriBookDateRequestQ1"
                    ).toString(),
                    this.$t("constants.confirm.globalTitle").toString()
                )
                    .then()
                    .catch((e) => e);
                if (!confirmed) {
                    return;
                }
                this.bookingDateRequests
                    .filter(
                        (b) =>
                            b.BookingDateRequestStatusId !=
                            BookingDateRequestStatusEnum.DateRejected
                    )
                    .forEach((b) => (b.RefusalReason = null));
                const savedBookingRequest = await this._saveBookingRequest(
                    this.bookingRequest,
                    this.vouchers,
                    this.bookingTicket,
                    this.bookingDateRequests
                );
                if (!savedBookingRequest) {
                    return this.$alert(
                        "Die Anfrage konnte nicht gespeichert werden."
                    );
                }
                if (savedBookingRequest.Message) {
                    this.$alert(savedBookingRequest.Message);
                }
                if (savedBookingRequest.IsSuccess) {
                    this.loadBookingTicket();
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async cancelRequestedBookingTicket(cancelAccepted) {
            try {
                this.bookingDateRequests
                    .filter(
                        (b) =>
                            b.BookingDateRequestStatusId ==
                            BookingDateRequestStatusEnum.DateCancelRequested
                    )
                    .forEach(
                        (b) =>
                            (b.BookingDateRequestStatusId = cancelAccepted
                                ? BookingDateRequestStatusEnum.DateCancelled
                                : BookingDateRequestStatusEnum.DateBooked)
                    );
                const savedBookingRequest = await this._saveBookingRequest(
                    this.bookingRequest,
                    this.vouchers,
                    this.bookingTicket,
                    this.bookingDateRequests
                );
                if (!savedBookingRequest) {
                    return this.$alert(
                        "Die Anfrage konnte nicht gespeichert werden"
                    );
                }
                if (savedBookingRequest.Message) {
                    this.$alert(savedBookingRequest.Message);
                }
                if (savedBookingRequest.IsSuccess) {
                    this.loadBookingTicket();
                }
            } catch (e) {
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
        async cancelBookingTicket() {
            try {
                const code = await this.$prompt(
                    this.$t("constants.messages.enterCode").toString()
                )
                    .then((code) => code)
                    .catch((e) => e);
                if (!code) {
                    return;
                }
                if (
                    this.voucher.Guid.slice(-6).toLowerCase() !=
                    code.toLowerCase()
                ) {
                    return this.$alert(
                        this.$t("constants.messages.noCodeMatch").toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                this.bookingDateRequests
                    .filter(
                        (b) =>
                            b.BookingDateRequestStatusId ==
                            BookingDateRequestStatusEnum.DateBooked
                    )
                    .forEach(
                        (b) =>
                            (b.BookingDateRequestStatusId =
                                BookingDateRequestStatusEnum.DateCancelled)
                    );
                const savedBookingRequest = await this._saveBookingRequest(
                    this.bookingRequest,
                    this.vouchers,
                    this.bookingTicket,
                    this.bookingDateRequests
                );
                if (!savedBookingRequest) {
                    return this.$alert(
                        "Die Anfrage konnte nicht gespeichert werden."
                    );
                }
                if (savedBookingRequest.Message) {
                    this.$alert(savedBookingRequest.Message);
                }
                if (savedBookingRequest.IsSuccess) {
                    this.bookingTicket = undefined;
                    this.loadBookingTicket();
                }
            } catch (e) {
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
        async _saveBookingRequest(
            bookingRequest,
            vouchers = undefined,
            bookingTicket = undefined,
            bookingDateRequests = undefined
        ) {
            try {
                if (!bookingRequest) {
                    return {
                        IsSuccess: false,
                        Message: "Keine Buchungsanfrage gefunden.",
                    };
                }
                const bookingRequestsComponent = new BookingRequestsComponent();
                EventBus.$emit("changeLoadingState", true);
                const savedBookingRequest =
                    await bookingRequestsComponent.saveBookingRequest(
                        this.$apollo,
                        bookingRequest,
                        vouchers,
                        bookingTicket,
                        bookingDateRequests
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!savedBookingRequest) {
                    return {
                        IsSuccess: false,
                        Message:
                            "Ihre Buchungsdaten konnten nicht gespeichert werden, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
                    };
                }
                return savedBookingRequest;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    watch: {
        bookingTicket() {
            try {
                this.loadBookingRequest();
                this.loadBookingDateRequests();
                this.loadBookingTicketStatus();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        bookingRequest() {
            try {
                this.loadVoucher();
                this.loadVouchers();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        voucher() {
            try {
                this.loadProduct();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        product() {
            try {
                this.loadHost();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        bookingDateRequests() {
            try {
                if (!this.bookingTicket) {
                    return;
                }
                if (
                    this.bookingTicket.BookingTicketStatusId !=
                    BookingTicketStatusEnum.DatesRequested
                ) {
                    return;
                }
                if (
                    !this.bookingDateRequests ||
                    !Array.isArray(this.bookingDateRequests) ||
                    !this.bookingDateRequests.length
                ) {
                    return;
                }
                this.bookingDateRequests
                    .filter((b) => this.bookingDateRequests.indexOf(b) == 0)
                    .forEach(
                        (b) =>
                            (b.BookingDateRequestStatusId = this.bookingTicket
                                .IsBindingBooking
                                ? BookingDateRequestStatusEnum.DateBooked
                                : BookingDateRequestStatusEnum.DateCanBeBooked)
                    );
                this.bookingDateRequests
                    .filter((b) => this.bookingDateRequests.indexOf(b) > 0)
                    .forEach(
                        (b) =>
                            (b.BookingDateRequestStatusId =
                                BookingDateRequestStatusEnum.DateRejected)
                    );
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    computed: {
        isReady() {
            try {
                if (!this.bookingTicket) {
                    return false;
                }
                if (!this.bookingRequest) {
                    return false;
                }
                if (!this.voucher) {
                    return false;
                }
                if (!this.product) {
                    return false;
                }
                if (!this.host) {
                    return false;
                }
                if (!this.bookingTicketStatus) {
                    return false;
                }
                return true;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        voucherIds() {
            try {
                if (
                    !this.vouchers ||
                    !Array.isArray(this.vouchers) ||
                    !this.vouchers.length
                ) {
                    return undefined;
                }
                const voucherIds = [];
                this.vouchers.forEach((v) => voucherIds.push(v.Id));
                return voucherIds.join(", ");
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
};
</script>
