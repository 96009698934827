<template lang="pug">
.modal-wrap.p-3.mt-3
  .modal-close-wrap
    i.modal-close-button(@click="close")
      font-awesome-icon(:icon="['fas', 'times']")
  .container-fluid#help-modal
    .row
      .col-12
        .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.contactModal.modalHeadline")}}
      .col-12
        ContactPanel

</template>

<script>
import ContactPanel from "../../views/partials/contact-panel.vue";

export default {
    name: "contact-modal",
    components: {
        ContactPanel,
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
};
</script>
