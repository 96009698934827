const moment = require("moment");

export class ChartComponent {
    getLineChartSeriesByItems(items, from, to, displayUnit, name, includeTouriDays) {
        try {
            if (!items) {
                return undefined;
            }
            if (!from) {
                return undefined;
            }
            if (!to) {
                return undefined;
            }
            if (!displayUnit) {
                return undefined;
            }
            if (!name) {
                return undefined;
            }
            const dates = this._getDates(from, to, displayUnit);
            const result = [];
            const totalSalesValueData = [];
            for (const date of dates) {
                const _items = items.filter(i => moment(i.Order.OrderDate).isSameOrAfter(date) && moment(i.Order.OrderDate).isSameOrBefore(moment(date).endOf(displayUnit).toDate()));
                if (!_items) {
                    return undefined;
                }
                let value = 0.00;
                _items.forEach(i => value += i.Quantity * (i.SingleItemPriceGross - (i.InsuranceAmount ? i.InsuranceAmount : 0.00)));
                totalSalesValueData.push(parseFloat(value.toFixed(2)));
            }
            result.push({
                name: name,
                data: totalSalesValueData,
            });
            if (!includeTouriDays) {
                return result;
            }
            const touriDaysValueData = [];
            for (const date of dates) {
                const _items = items.filter(i => moment(i.Order.OrderDate).isSameOrAfter(date) && moment(i.Order.OrderDate).isSameOrBefore(moment(date).endOf(displayUnit).toDate()));
                if (!_items) {
                    return undefined;
                }
                let value = 0.00;
                _items.forEach(i => value += (i.InsuranceAmount ? i.InsuranceAmount : 0.00));
                touriDaysValueData.push(parseFloat(value.toFixed(2)));
            }
            result.push({
                name: "touriDays",
                data: touriDaysValueData,
            });
            return result;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    getLineChartLabels(from, to, displayUnit) {
        try {
            if (!from) {
                return undefined;
            }
            if (!to) {
                return undefined;
            }
            if (!displayUnit) {
                return undefined;
            }
            let dateFormat = undefined;
            if (displayUnit == "days") { // DISPLAY DAYS
                dateFormat = "DD.MM.YYYY";
            } else if (displayUnit == "weeks") { // DISPLAY EVERY MONTH
                dateFormat = "DD.MM.YYYY";
            } else if (displayUnit == "months") { // DISPLAY EVERY MONTH
                dateFormat = "MM.YYYY";
            } else if (displayUnit == "years") { // DISPLAY EVERY YEAR
                dateFormat = "YYYY";
            }
            if (!dateFormat) {
                return undefined;
            }
            const _dates = this._getDates(from, to, displayUnit);
            const dates = [];
            for (const _date of _dates) {
                dates.push(moment(_date).format(dateFormat));
            }
            return dates;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    _getDates(from, to, displayUnit) {
        try {
            if (!from) {
                return undefined;
            }
            if (!to) {
                return undefined;
            }
            if (!displayUnit) {
                return undefined;
            }
            from = moment(from).startOf(displayUnit).toDate();
            to = moment(to).startOf(displayUnit).toDate();

            let dates = [];
            const difference = moment(to).diff(from, displayUnit) + 1;
            if (difference == 1) {
                dates = [
                    moment(from).subtract(1, displayUnit).toDate(),
                    moment(from).toDate(),
                    moment(from).add(1, displayUnit).toDate()
                ];
            } else {
                for (let i = 0; i < difference; i++) {
                    dates.push(moment(from).add(i, displayUnit).toDate());
                }
            }
            return dates;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
