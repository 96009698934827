import moment from "moment";

let VueFilter = {
    formatDateFilter: {
        name: "formatDate",
        func: (value, lang = "de") => {
            if (!value) {
                return undefined;
            }
            return moment(value).locale(lang).format("DD.MM.YYYY");
        },
    },
    formatDateTimeFilter: {
        name: "formatDateTime",
        func: (value, lang = "de") => {
            if (!value) {
                return undefined;
            }
            return moment(value).locale(lang).format("DD.MM.YYYY HH:mm");
        },
    },
    formatDatePickerValueFilter: {
        name: "formatDatePickerValue",
        func: (value, lang = "de") => {
            if (!value) {
                return undefined;
            }
            return moment(value).locale(lang).format("YYYY-MM-DD");
        },
    },
    formatDateWithDayFilter: {
        name: "formatDateWithDay",
        func: (value, lang = "de") => {
            if (!value) {
                return undefined;
            }
            const date = moment(value).locale(lang);
            return date.format("dd") + ". " + date.format("DD.MM.YYYY");
        },
    },
    formatPercentageFilter: {
        name: "formatPercentage",
        func: (value) => {
            if (value) {
                return parseFloat(value).toFixed(2).toString() + " %";
            }
            return parseFloat(0.0).toFixed(2) + " %";
        },
    },
    formatEuroFilter: {
        name: "formatEuro",
        func: (value) => {
            if (!value) {
                return "0,00 €";
            }
            return `${parseFloat(parseFloat(value).toFixed(2)).toLocaleString(
                "de-DE",
                { minimumFractionDigits: 2 }
            )} €`;
        },
    },
};

export default VueFilter;
