import gql from "graphql-tag";

export const SearchCriterias = {
    Queries: {
        SearchCriterias: gql`
        query searchCriterias($searchCriteriaSubAreaId: ID, $isActive: Boolean) {
            searchCriterias(searchCriteriaSubAreaId: $searchCriteriaSubAreaId, isActive: $isActive) {
                Id
                HasInput
                Description
                SortNumber
                SearchCriteriaSubAreaId
                IsActive
            }
        }`,
    },
};
