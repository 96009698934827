<template lang="pug">
option(:value="Id") {{ Id + " | " + Name }}
</template>

<script>
export default {
    name: "sales-select-option",
    props: {
        Id: String,
        Name: String,
    },
};
</script>

<style scoped></style>
