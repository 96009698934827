import gql from "graphql-tag";

export const AwsS3 = {
    Queries: {
        SignedObjectUrl: gql`
        query signedObjectUrl($key: String) {
            signedObjectUrl(key: $key)
        }`,
    },
};
