<template lang="pug">
  tr(:class="{ 'text-danger': moment(voucher.ValidTill).isBefore(new Date()) }")
    th(:class="{ 'text-decoration-line-through': !!parentVoucher }") {{ voucher.Id }}
    td
      span(v-if="order") {{ order.PaidAt | formatDateTime }}
    td
      span(v-if="product") {{ product.Uebernachtungen }}
    td
      span(v-if="bookingRequest") {{ bookingRequest.LastName }}, {{ bookingRequest.FirstName }}
    td
      span(v-if="voucher.IsSelfSale") touriDat-Gutschein Werbemaßnahme
      template(v-else)
        span(v-if="item") {{ item.SingleItemPriceGross - item.InsuranceAmount | formatEuro }}
    td
      label {{ $t("vouchers.voucherStatus." + voucher.Status) }}
    td
      .form-group.custom-form-group.align-self-center
        .input-group(@click="showArrivalModal" :disabled="isDisabled")
          input.form-control.custom-control.cursor-pointer(type="text" :value="voucher.BookedAt | formatDate" :placeholder="$t('vouchers.tableColumn.arrivalOn')" :class="{ 'disabled': isDisabled }" :disabled="isDisabled")
          .input-group-append
            button.button.button-primary.button-td-grey.button-icon(:class="{ 'disabled': isDisabled }" :disabled="isDisabled")
              font-awesome-icon(:icon="['fas','calendar']")

    td
      .form-group.custom-form-group.align-self-center
        .input-group(@click="showNotesModal" :disabled="isDisabled")
          input.form-control.custom-control.cursor-pointer(type="text" :value="voucher.BookingInfo" :placeholder="$t('vouchers.tableColumn.notes')" :class="{ 'disabled': isDisabled }" :disabled="isDisabled")
          .input-group-append
            button.button.button-primary.button-td-grey.button-icon(:class="{ 'disabled': isDisabled }" :disabled="isDisabled")
              font-awesome-icon(:icon="['fas','pen']")

    td
      router-link.button.button-primary.button-tdays(:to="{ name: 'VoucherDetails', params: { voucherId: voucher.Id } }")
        | {{ $t("constants.buttons.show") }}

</template>

<script>
import VoucherArrivalModal from "../../../modals/VoucherArrivalModal.vue";
import VoucherStatusEnum from "../../../../lib/enums/voucherStatus/VoucherStatusEnum";
import VoucherBookingInfoModal from "@/views/modals/VoucherBookingInfoModal";
import ProductsComponent from "@/lib/components/products/ProductsComponent";
import BookingRequestsComponent from "@/lib/components/bookingRequests/BookingRequestsComponent";
import OrdersComponent from "@/lib/components/orders/OrdersComponent";
import { ItemsComponent } from "@/lib/components/items/ItemsComponent";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";

const moment = require("moment");

export default {
    name: "VouchersTableRow",
    components: { VoucherArrivalModal },
    props: {
        voucher: {
            type: Object,
            required: true,
        },
        isSmallTableColumn: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            item: undefined,
            order: undefined,
            product: undefined,
            bookingRequest: undefined,
            parentVoucher: undefined,

            VoucherStatusEnum: VoucherStatusEnum,
            moment: moment,
        };
    },
    mounted() {
        try {
            this.loadBookingRequest();
            this.loadItem();
            this.loadProduct();
            this.loadOrder();
            this.loadParentVoucher();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadProduct() {
            try {
                const productsComponent = new ProductsComponent();
                const product = await productsComponent.getProduct(
                    this.$apollo,
                    this.voucher.ProductId
                );
                if (!product) {
                    return;
                }
                this.product = product;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingRequest() {
            try {
                const bookingRequestsComponent = new BookingRequestsComponent();
                const bookingRequest =
                    await bookingRequestsComponent.getBookingRequest(
                        this.$apollo,
                        this.voucher?.Id
                    );
                if (!bookingRequest) {
                    return;
                }
                this.bookingRequest = bookingRequest;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadItem() {
            try {
                const itemsComponent = new ItemsComponent();
                const item = await itemsComponent.getItemByVoucherId(
                    this.$apollo,
                    this.voucher?.Id
                );
                if (!item) {
                    return;
                }
                this.item = item;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadOrder() {
            try {
                const ordersComponent = new OrdersComponent();
                const order = await ordersComponent.getOrderByVoucherId(
                    this.$apollo,
                    this.voucher?.Id
                );
                if (!order) {
                    return;
                }
                this.order = order;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadParentVoucher() {
            try {
                const vouchersComponent = new VouchersComponent();
                const voucher = await vouchersComponent.getVoucher(
                    this.$apollo,
                    undefined,
                    this.voucher?.Id
                );
                if (!voucher) {
                    return;
                }
                this.parentVoucher = voucher;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        showArrivalModal() {
            this.$modal.show(
                VoucherArrivalModal,
                {
                    voucherId: this.voucher.Id,
                },
                {
                    resizable: true,
                    height: "auto",
                    width: "60%",
                }
            );
        },
        showNotesModal() {
            this.$modal.show(
                VoucherBookingInfoModal,
                {
                    voucher: this.voucher,
                },
                {
                    resizable: true,
                    height: "auto",
                }
            );
        },
    },
    computed: {
        isDisabled() {
            try {
                return (
                    (!this.voucher.IsSelfSale &&
                        this.voucher.Status == VoucherStatusEnum.Created) ||
                    this.voucher.Status == VoucherStatusEnum.Cancelled ||
                    this.voucher.Status == VoucherStatusEnum.HostCancel ||
                    !!this.parentVoucher
                );
            } catch (e) {
                console.error(e);
                return true;
            }
        },
    },
};
</script>
