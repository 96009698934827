<template lang="pug">
  .row
    .col-12
      input(type="file" accept="image/*" ref="fileInput" name="inputfile" id="inputfile" multiple @input="pickFiles")
      label(for="inputfile")
    .col.mt-2(v-for="imageToUpload of imagesToUpload")
      .row
        .col-6
          h5 {{ imageToUpload.name }}
        .col-6
          .float-right
            button.button.button-primary.button-icon.button-tpics(@click="imagesToUpload.splice(imagesToUpload.indexOf(imageToUpload.name), 1)")
              font-awesome-icon(:icon="['fas', 'times']")
        .col-12
          img.img-fluid(:src="imageToUpload.dataUrl")

    .col-12.mt-3
      button.button.button-primary.button-tdays.w-100(type='button' @click="uploadImages" :disabled="imagesToUpload.length == 0" :class="{ 'disabled': imagesToUpload.length == 0 }")
        | hochladen

</template>

<script>
import EventBus from "@/views/event-bus";
import { Images } from "@/lib/graphql/images/Images.ts";
import moment from "moment";
import { UserGroups } from "@/lib/graphql/userGroups/UserGroups.ts";
import { NotificationTypesEnum } from "@/lib/enums/notificationTypes/NotificationTypesEnum.ts";
import { Notifications } from "@/lib/graphql/notifications/Notifications.ts";

export default {
    name: "upload-images",
    props: {
        hostId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            imagesToUpload: [],
        };
    },
    methods: {
        async pickFiles() {
            try {
                const input = this.$refs.fileInput;
                let files = input.files;
                if (!files) {
                    return;
                }
                const imagesToUpload = [];
                for (const file of files) {
                    if (this.imagesToUpload.find((f) => f.name == file.name)) {
                        continue;
                    }
                    file.dataUrl = await this.fileToBase64(file);
                    const imageToUpload = {
                        name: file.name,
                        dataUrl: file.dataUrl,
                        lastModified: moment(file.lastModified).toDate(),
                        size: file.size,
                        type: file.type,
                        webkitRelativePath: file.webkitRelativePath,
                    };
                    imagesToUpload.push(imageToUpload);
                }
                this.imagesToUpload =
                    this.imagesToUpload.concat(imagesToUpload);
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async fileToBase64(file) {
            try {
                const reader = new FileReader();
                return new Promise((resolve) => {
                    reader.onload = (ev) => {
                        resolve(ev.target.result);
                    };
                    reader.readAsDataURL(file);
                });
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        async uploadImages() {
            try {
                if (this.imagesToUpload.length == 0) {
                    return this.$alert(
                        "Bitte wählen Sie zuerst Bilder für den Upload aus."
                    );
                } else if (this.imagesToUpload.length == 1) {
                    const confirmed = await this.$confirm(
                        "Sie haben nur ein Bild den Upload ausgewählt, sind Sie sicher dass sie nur ein einziges Bild hochladen möchten statt mehrere gleichzeitig?"
                    )
                        .then()
                        .catch((e) => e);
                    if (!confirmed) {
                        return;
                    }
                }
                EventBus.$emit("changeLoadingState", true);
                const uploadedImages = await this.$apollo
                    .mutate({
                        mutation: Images.Mutations.UploadImages,
                        fetchPolicy: "no-cache",
                        variables: {
                            hostId: this.hostId,
                            files: this.imagesToUpload,
                        },
                    })
                    .then(({ data }) => data?.uploadImages)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!uploadedImages) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(
                        "Die Bilder konnten nicht hochgeladen werden."
                    );
                }
                if (!uploadedImages.IsSuccess) {
                    EventBus.$emit("changeLoadingState", false);
                    this.$alert(uploadedImages.Message);
                }
                let userGroup = await this.$apollo
                    .query({
                        query: UserGroups.Queries.UserGroupByIndicator,
                        fetchPolicy: "no-cache",
                        variables: {
                            indicator: "images",
                        },
                    })
                    .then(({ data }) => data?.userGroupByIndicator)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!userGroup) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(
                        "Keine zu addressierenden touriDat-Benutzer gefunden. Ihre Bilder wurden trotzdessen erfolgreich hochgeladen."
                    );
                }
                let notification = {
                    Title: `${this.imagesToUpload.length} ${
                        this.imagesToUpload.length == 1 ? "Bild" : "Bilder"
                    } hochgeladen.`,
                    UserGroupId: userGroup.Id,
                    HostId: this.hostId,
                    CreatedBy: this.$session.get("userId"),
                    NotificationTypeId: NotificationTypesEnum.ProductManager,
                };
                let savedNotification = await this.$apollo
                    .mutate({
                        mutation: Notifications.Mutations.SaveNotification,
                        fetchPolicy: "no-cache",
                        variables: {
                            notification: notification,
                        },
                    })
                    .then(({ data }) => data?.saveNotification)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!savedNotification) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(
                        "Die Benachrichtigung für das touriDat-Service Team konnte nicht erstellt werden."
                    );
                }
                if (!savedNotification.IsSuccess) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(savedNotification.Message);
                }
                userGroup = await this.$apollo
                    .query({
                        query: UserGroups.Queries.UserGroupByIndicator,
                        fetchPolicy: "no-cache",
                        variables: {
                            indicator: "büroleitung",
                        },
                    })
                    .then(({ data }) => data?.userGroupByIndicator)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!userGroup) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(
                        "Keine zu addressierenden touriDat-Benutzer gefunden. Ihre Bilder wurden trotzdessen erfolgreich hochgeladen."
                    );
                }
                notification = {
                    Title: `${this.imagesToUpload.length} ${
                        this.imagesToUpload.length == 1 ? "Bild" : "Bilder"
                    } hochgeladen.`,
                    UserGroupId: userGroup.Id,
                    HostId: this.hostId,
                    CreatedBy: this.$session.get("userId"),
                    NotificationTypeId: NotificationTypesEnum.ProductManager,
                };
                savedNotification = await this.$apollo
                    .mutate({
                        mutation: Notifications.Mutations.SaveNotification,
                        fetchPolicy: "no-cache",
                        variables: {
                            notification: notification,
                        },
                    })
                    .then(({ data }) => data?.saveNotification)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!savedNotification) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(
                        "Die Benachrichtigung für das touriDat-Team konnte nicht erstellt werden"
                    );
                }
                if (savedNotification.Message) {
                    EventBus.$emit("changeLoadingState", false);
                    this.$alert(savedNotification.Message);
                }
                EventBus.$emit("changeLoadingState", false);
                if (savedNotification.IsSuccess) {
                    this.imagesToUpload = [];
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
