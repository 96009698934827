<template lang="pug">
.container-fluid
  .row
    .col-12
      h2 {{$t("account.users.list.headline")}}
    .col-12.mt-2
      .table-responsive
        table.table.table-bordered.text-center.w-100.d-table
          thead
            tr
              th(scope="col") {{$t("account.users.list.table.email")}}
              th(scope="col") {{$t("account.users.list.table.firstName")}}
              th(scope="col") {{$t("account.users.list.table.lastName")}}
              th(scope="col") {{$t("account.users.list.table.host")}}
              th(scope="col") {{$t("account.users.list.table.edit")}}
              th(scope="col") {{$t("account.users.list.table.delete")}}
          tbody
            users-list-table-row(v-for="user in users" :user="user" :key="user.Id")

  .col-12.mt-2
    new-pagination(:load-more="loadMoreUsers" :overall-count="usersCount" :current-count="users.length")

  .col-12.mt-2
    router-link.button.button-primary.button-tdays.float-right(to="/account/users/new" tag="button")
      | {{$t("account.users.list.addUserButton")}}

</template>

<script>
import EventBus from "../../../../views/event-bus";
import { Users } from "../../../../lib/graphql/users/Users.ts";
import UsersListTableRow from "./UsersListTableRow.vue";
import NewPagination from "../../../partials/new-pagination.vue";

export default {
    name: "UsersList",
    components: { NewPagination, UsersListTableRow },
    props: {
        slug: String,
    },
    data() {
        return {
            users: [],
            usersCount: 0,

            offset: 0,
            limit: 15,
        };
    },
    async mounted() {
        try {
            EventBus.$on(
                "updateUsersList",
                function () {
                    this.$apollo.queries.users.refetch();
                }.bind(this)
            );
            await this.loadUsers();
            await this.loadUsersCount();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadUsers() {
            try {
                EventBus.$emit("changeLoadingState", true);
                const users = await this.$apollo
                    .query({
                        query: Users.Queries.UsersByHostPoolUserId,
                        fetchPolicy: "no-cache",
                        variables: {
                            userId: this.$session.get("userId"),
                            offset: this.offset,
                            limit: this.limit,
                        },
                    })
                    .then(({ data }) => data?.usersByHostPoolUserId);
                EventBus.$emit("changeLoadingState", false);
                if (!users) {
                    return;
                }
                this.users = this.users.concat(users);
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadUsersCount() {
            try {
                const usersCount = await this.$apollo
                    .query({
                        query: Users.Queries.UsersCountByHostPoolUserId,
                        fetchPolicy: "no-cache",
                        variables: {
                            userId: this.$session.get("userId"),
                        },
                    })
                    .then(({ data }) => data?.usersCountByHostPoolUserId);
                if (usersCount == undefined) {
                    return;
                }
                this.usersCount = usersCount;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadMoreUsers() {
            try {
                if (this.usersCount == this.users.length) {
                    return;
                }
                this.offset += this.limit;
                await this.loadUsers();
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
