<template lang="pug">
  .wrapper
    tD-loading-overlay(v-if="$apollo.loading")
    .row
      .col-12
        h2 {{$t("sales.detail.headline")}}
      .col-9
        label {{$t("sales.detail.product")}}
        .input-group
          select.form-control(v-model="productId")
            option(:value="undefined") {{$t("sales.detail.pleaseSelectFilter")}}
            option(v-for="product in products" :value="product.Id" :checked="productId == product.Id") {{ product.Id }}: {{ product.Name1 }}
          .input-group-append
            button.btn.button-primary.button-tdays.rounded-0(@click="addChartFilter") {{$t("sales.detail.addFilter")}}
      .col-3.align-content-center
        b-form-checkbox(switch size="lg" v-model="isHostSalesCompared") {{$t("sales.detail.addHostSales")}}

      .col-12
        .row.mt-3(v-for="chartFilter in chartFilters")
          .col-12.mt-2
            h5 {{chartFilter.Title}}
          .col-12.col-sm-6.col-xl-4.mt-2
            label {{$t("sales.detail.from")}}
            input.form-control.custom-control(type="date", v-model="chartFilter.From")
          .col-12.col-sm-6.col-xl-4.mt-2
            label {{$t("sales.detail.until")}}
            input.form-control.custom-control(type="date", v-model="chartFilter.To")
          .col-12.col-sm-6.col-xl-4.mt-2
            label.form-label &nbsp;
            button.button.button-primary.button-tpics(@click="chartFilters.splice(chartFilters.indexOf(chartFilter), 1)" v-if="chartFilters.length > 1")
              font-awesome-icon(:icon="['fas', 'trash']")

      .col-12.mt-5
        p.mb-0.mb-md-1 {{$t("sales.detail.xAxis")}}
      .col-12.col-md-6.mt-3.mt-md-0
        .card.custom-card.card-body
          .row.align-self-centers
            .col-3
              .form-check
                input.form-check-input(type="radio", id="cbYear", value="years" v-model="chartDisplayUnit")
                label.form-check-label(for="cbYear") {{$t("sales.detail.year")}}
            .col-3
              .form-check
                input.form-check-input(type="radio", id="cbMonth", value="months", v-model="chartDisplayUnit")
                label.form-check-label(for="cbMonth") {{$t("sales.detail.month")}}
            .col-3
              .form-check
                input.form-check-input(type="radio", id="cbWeek", value="weeks", v-model="chartDisplayUnit")
                label.form-check-label(for="cbWeek") {{$t("sales.detail.week")}}
            .col-3
              .form-check
                input.form-check-input(type="radio", id="cbDay", value="days", v-model="chartDisplayUnit")
                label.form-check-label(for="cbDay") {{$t("sales.detail.day")}}
      .col-12.col-md-6.mt-3.mt-md-0
        button.button.button-primary.button-tdays.w-100(@click="recalcData" :disabled="chartFilters.length == 0" :class="{ 'disabled': chartFilters.length == 0 }") {{$t("sales.detail.calculateStatisticsButton")}}
      .col-12.mt-5
        h5 {{$t("sales.detail.salesChart")}}
      .col-12.mt-3
        LineChart(:options="options", :from="from", :to="to", :display-unit="chartDisplayUnit" v-if="moment(from).isValid() && moment(to).isValid() && chartFilters.length > 0")

      .col-12(v-if="salesTableOptions && salesTableOptions.length > 0")
        sales-evaluations-table(:options="salesTableOptions" :from="moment(from).toDate()" :to="moment(to).toDate()" :display-unit="chartDisplayUnit")

</template>

<script>
import EventBus from "../../../views/event-bus";
import LineChart from "@/views/components/charts/LineChart";
import { Products } from "@/lib/graphql/products/Products.ts";
import moment from "moment";
import SalesEvaluationsTable from "@/views/pages/sales/SalesEvaluationsTable.vue";
import { Hosts } from "@/lib/graphql/hosts/Hosts.ts";
import { ItemsComponent } from "@/lib/components/items/ItemsComponent";
import { OrderStatusEnum } from "@/lib/enums/orderStatus/OrderStatusEnum.ts";

export default {
    name: "sales-detail",
    components: { SalesEvaluationsTable, LineChart },
    props: {
        slug: String,
    },
    async mounted() {
        EventBus.$on(
            "changeHostId",
            function (hostId) {
                this.hostId = hostId;
                this.loadProducts();
                this.chartFilters = [];
            }.bind(this)
        );
        await this.loadProducts();
    },
    data() {
        return {
            hostId: this.$session.get("hostId"),
            productId: undefined,
            isHostSalesCompared: false,
            products: [],
            chartFilters: [],
            options: [],
            salesTableOptions: [],
            tableData: [],
            chartDisplayUnit: "years",

            moment: moment,
        };
    },
    methods: {
        async loadProducts() {
            try {
                const products = await this.$apollo
                    .query({
                        query: Products.Queries.Products,
                        variables: {
                            hostId: this.hostId,
                            isVisibleInItemListing: true,
                        },
                    })
                    .then(({ data }) => data?.products)
                    .catch((e) => {
                        console.log(e);
                        this.$alert(e.message);
                    });
                if (!products) {
                    return this.$alert(
                        this.$t("sales.detail.noProducts").toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                this.products = products;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async addChartFilter() {
            try {
                if (!this.productId) {
                    return this.$alert(
                        "Bitte wählen Sie zuerst ein Produkt aus."
                    );
                }
                if (
                    this.chartFilters.find((c) => c.ProductId == this.productId)
                ) {
                    return this.$alert("Produktfilter existiert bereits.");
                }
                const product = this.products.find(
                    (p) => p.Id == this.productId
                );
                if (!product) {
                    return this.$alert(
                        `Kein Produkt für ${this.productId} gefunden.`
                    );
                }
                this.chartFilters.push({
                    Title: `${product.Id}: ${product.Name1}`,
                    ProductId: this.productId,
                });
                this.productId = undefined;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async recalcData() {
            try {
                if (this.chartFilters.length == 0) {
                    return this.$alert(
                        "Bitte fügen Sie zuerst einen Filter hinzu."
                    );
                }
                if (
                    this.chartFilters.some(
                        (c) => c.From == undefined || c.To == undefined
                    )
                ) {
                    return this.$alert(
                        "Mindestens ein Filterdatum ist nicht gefüllt."
                    );
                }
                const _options = [];
                EventBus.$emit("changeLoadingState", true);
                this.salesTableOptions = [];
                const itemsComponent = new ItemsComponent();
                for (const chartFilter of this.chartFilters) {
                    const items = await itemsComponent.getItems(
                        this.$apollo,
                        chartFilter.ProductId,
                        undefined,
                        undefined,
                        undefined,
                        chartFilter.From,
                        chartFilter.To,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        [
                            OrderStatusEnum.Received,
                            OrderStatusEnum.Paid,
                            OrderStatusEnum.Completed,
                        ]
                    );
                    if (!items) {
                        EventBus.$emit("changeLoadingState", false);
                        return this.$alert(
                            `Es konnten keine Artikel für Produkt ${chartFilter.ProductId} geladen werden.`
                        );
                    }
                    _options.push({
                        items: items,
                        name: chartFilter.Title,
                    });
                    const product = this.products.find(
                        (p) => p.Id == chartFilter.ProductId
                    );
                    if (!product) {
                        return this.$alert(
                            `Es konnte kein Produkt mit Id ${chartFilter.ProductId} geladen werden.`
                        );
                    }
                    this.salesTableOptions.push({
                        id: chartFilter.ProductId,
                        name: `${product.Id}: ${product.Uebernachtungen} ÜN / ${product.Verpflegung}`,
                        items: items,
                    });
                    if (this.isHostSalesCompared) {
                        const host = await this.$apollo
                            .query({
                                query: Hosts.Queries.Host,
                                variables: {
                                    id: this.hostId,
                                },
                            })
                            .then(({ data }) => data?.host)
                            .catch((e) => {
                                console.error(e);
                                return undefined;
                            });
                        if (!host) {
                            return this.$alert(
                                `Es konnte kein Gastgeber mit der Id ${this.hostId} geladen werden.`
                            );
                        }
                        const itemsComponent = new ItemsComponent();
                        const items = await itemsComponent.getItems(
                            this.$apollo,
                            undefined,
                            this.hostId,
                            undefined,
                            undefined,
                            this.from,
                            this.to,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            [
                                OrderStatusEnum.Received,
                                OrderStatusEnum.Paid,
                                OrderStatusEnum.Completed,
                            ]
                        );
                        if (!items) {
                            EventBus.$emit("changeLoadingState", false);
                            return this.$alert(
                                `Es konnten keine Artikel für Gastgeber ${this.hostId} geladen werden.`
                            );
                        }
                        _options.push({
                            items: items,
                            name: "Gastgeberumsatz",
                        });
                        this.salesTableOptions.push({
                            id: host.Id,
                            name: host.Name,
                            items: items,
                            isHost: true,
                        });
                    }
                    EventBus.$emit("changeLoadingState", false);
                    this.options = _options;
                }
                console.log("stop");
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        excelExport() {
            EventBus.$emit("changeLoadingState", true);
            let table = `
                            <table border='2px'>
                                <thead>
                                    <tr>
                                        <th scope="col">Datum</th>
                                        <th scope="col">Verkäufe</th>
                                        <th scope="col">Gesamtsumme</th>
                                        <th scope="col">Anzahl Stornierungen</th>
                                        <th scope="col">Umsatz Stornierungen</th>
                                    </tr>
                                </thead>`;

            for (const tableEntry of this.tableData) {
                table += `<td colspan="5" style="font-weight:500; text-align:left">${tableEntry.header}</td>`;
                for (const sale of tableEntry.sales) {
                    table += `
                                <tr>
                                    <td>${sale.Date}</td>
                                    <td>${sale.BookedCount}</td>
                                    <td>${sale.BookedTotal.toString().replace(
                                        ".",
                                        ","
                                    )} €</td>
                                    <td>${sale.CancelledCount}</td>
                                    <td>${sale.CancelledTotal.toString().replace(
                                        ".",
                                        ","
                                    )} €</td>
                                </tr>
                            `;
                }
            }

            table = table + "</table>";
            table = table.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
            table = table.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
            table = table.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

            EventBus.$emit("changeLoadingState", false);

            const sa = window.open(
                "data:application/vnd.ms-excel," + encodeURIComponent(table)
            );
            return sa;
        },
    },
    computed: {
        from() {
            try {
                if (this.chartFilters.length == 0) {
                    return undefined;
                }
                const _chartFilters = JSON.parse(
                    JSON.stringify(this.chartFilters)
                );
                _chartFilters.sort((a, b) => {
                    return b.From - a.To;
                });
                return moment(_chartFilters[0].From).toDate();
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        to() {
            try {
                if (this.chartFilters.length == 0) {
                    return undefined;
                }
                const _chartFilters = JSON.parse(
                    JSON.stringify(this.chartFilters)
                );
                _chartFilters.sort((a, b) => {
                    return b.From - a.To;
                });
                return moment(
                    _chartFilters[_chartFilters.length - 1].To
                ).toDate();
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
};
</script>

<style scoped></style>
