<template lang="pug">
span
  .button-notifications.txt-white(v-popover:notifications)
    span(v-if="unseenNotificationsCount > 0") {{ unseenNotificationsCount }}
    i(style="cursor: pointer;")
      font-awesome-icon(:icon="['fas', 'bell']")

  popover(name="notifications" :width="parseFloat(280)")
    .row
      .col-12(v-for="notification in notifications")
        NotificationPanel(:Notification="notification")
      .col-12
        p.text-center.p-2.m-0(v-if="notifications.length == 0") Keine neuen Benachrichtigungen
      .col-12.col-lg-6
        router-link.button.button-primary.button-tdays(:to="`/account/notifications`") Alle anzeigen
      .col-12.col-lg-6
        button.button.button-primary.button-tbook alle als gelesen

</template>

<script>
import NotificationPanel from "../notificationPanel/notification-panel.vue";
import EventBus from "../../event-bus";
import { Notifications } from "@/lib/graphql/notifications/Notifications.ts";
import { NotificationTypesEnum } from "@/lib/enums/notificationTypes/NotificationTypesEnum.ts";

export default {
    name: "NotificationButton",
    components: {
        NotificationPanel,
    },
    data() {
        return {
            notifications: [],
            localNotifications: [],
            unseenNotificationsCount: 0,
        };
    },
    mounted() {
        try {
            EventBus.$on(
                "refreshUnseenNotifications",
                function () {
                    this.loadNotifications();
                    this.loadUnseenNotificationsCount();
                }.bind(this)
            );
            EventBus.$on(
                "changeHostId",
                function () {
                    this.loadNotifications();
                    this.loadUnseenNotificationsCount();
                }.bind(this)
            );
            this.loadNotifications();
            this.loadUnseenNotificationsCount();
        } catch (e) {
            console.error(e);
        }
    },
    methods: {
        async loadUnseenNotificationsCount() {
            try {
                const unseenNotificationsCount = await this.$apollo
                    .query({
                        query: Notifications.Queries.NotificationsCount,
                        fetchPolicy: "no-cache",
                        variables: {
                            hostIds: [this.$session.get("hostId")],
                            isSeen: false,
                            notificationTypeId: NotificationTypesEnum.Dashboard,
                        },
                    })
                    .then(({ data }) => data?.notificationsCount)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (unseenNotificationsCount == undefined) {
                    return;
                }
                this.unseenNotificationsCount = unseenNotificationsCount;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadNotifications() {
            try {
                const notifications = await this.$apollo
                    .query({
                        query: Notifications.Queries.Notifications,
                        fetchPolicy: "no-cache",
                        variables: {
                            hostIds: [this.$session.get("hostId")],
                            notificationTypeId: NotificationTypesEnum.Dashboard,
                        },
                    })
                    .then(({ data }) => data?.notifications)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!notifications) {
                    return this.$alert(
                        "Es konnten keine Benachrichtigungen geladen werden."
                    );
                }
                this.notifications = notifications;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
