<template lang="pug">
#contact-panel.text-center.align-bottom
  b.d-none.d-lg-block {{$t("partials.contactPanel.headline")}}
  hr.d-none.d-lg-block
  .row.no-gutters.contact-single-wrap.mt-3(v-if="contactPerson")
    .col-3.align-self-center
      .td-branding
        //img(v-if="contactPerson.IsTouriBook" src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touribook/branding/touribook-branding-50x50.png")
        img(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touridays/branding/touridays-branding-50x50.png")
      img(v-if="contactPerson.Bild" :src="contactPerson.Bild")
      img(v-else src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Dashboard/Kundenberater/dummy.png")
    .col-9.pl-1.text-left
      p.mb-2
        b {{contactPerson.Vorname}} {{contactPerson.Name}}
        |
        br
        |
        i {{contactPerson.Position}}
      table
        tr
          td
            font-awesome-icon.mr-1(:icon="['fas','phone']")
          td
            a(:href="'tel:029749697' + contactPerson.Durchwahl") 02974 9697 - {{contactPerson.Durchwahl}}
        tr
          td
            font-awesome-icon.mr-1(:icon="['fas','envelope']")
          td
            a(:href="'mailto:' + contactPerson.Email") {{contactPerson.Email}}

  .row.no-gutters.contact-single-wrap.mt-3(v-if="touriBookAdviser")
    .col-3.align-self-center
      .td-branding
        img(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touribook/branding/touribook-branding-50x50.png")
      img(v-if="touriBookAdviser.Bild" :src="touriBookAdviser.Bild")
      img(v-else src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Dashboard/Kundenberater/dummy.png")
    .col-9.pl-1.text-left
      p.mb-2
        b {{touriBookAdviser.Vorname}} {{touriBookAdviser.Name}}
        |
        br
        |
        i {{touriBookAdviser.Position}}
      table
        tr
          td
            font-awesome-icon.mr-1(:icon="['fas','phone']")
          td
            a(:href="'tel:029749697' + touriBookAdviser.Durchwahl") 02974 9697 - {{touriBookAdviser.Durchwahl}}
        tr
          td
            font-awesome-icon.mr-1(:icon="['fas','envelope']")
          td
            a(:href="'mailto:' + touriBookAdviser.Email") {{touriBookAdviser.Email}}

</template>

<script>
import EventBus from "../../views/event-bus";
import { Employees } from "@/lib/graphql/employees/Employees.ts";

export default {
    name: "ContactPanel",
    data() {
        return {
            hostId: this.$session.get("hostId"),
            contactPerson: undefined,
            touriBookAdviser: undefined,
        };
    },
    mounted() {
        EventBus.$on(
            "changeHostId",
            function (hostId) {
                this.hostId = hostId;
            }.bind(this)
        );
    },
    apollo: {
        contactPerson: {
            query: Employees.Queries.ContactPersons,
            loadingKey: "loading",
            variables() {
                return {
                    hostId: this.hostId,
                };
            },
        },
        touriBookAdviser: {
            query: Employees.Queries.TouriBookAdviser,
            loadingKey: "loading",
            variables() {
                return {
                    hostId: this.hostId,
                };
            },
        },
    },
};
</script>
