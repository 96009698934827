const {
    PermissionTypesEnum,
} = require("@/lib/enums/permissionTypes/PermissionTypesEnum");
const { Permissions } = require("@/lib/graphql/permissions/Permissions.ts");

class PermissionsComponent {
    async getPermissions(apollo, userId) {
        try {
            if (!apollo) {
                return undefined;
            }
            const permissions = await apollo
                .query({
                    query: Permissions.Queries.Permissions,
                    fetchPolicy: "no-cache",
                    variables: {
                        userId: userId,
                        permissionTypeId: PermissionTypesEnum.Dashboard,
                    },
                })
                .then(({ data }) => data?.permissions)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return permissions;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

module.exports = PermissionsComponent;
