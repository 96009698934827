<template lang="pug">
.container-fluid
  .row
    .col-12.mt-3
      h2 {{$t("account.datasheet.headline")}}
  datasheet-search-criteria-areas(:host-id="hostId")

</template>

<script>
import EventBus from "../../../views/event-bus";
import DatasheetSearchCriteriaAreas from "@/views/pages/account/datasheet/datasheet-search-criteria-areas.vue";

export default {
    name: "account-datasheet",
    components: { DatasheetSearchCriteriaAreas },
    data() {
        return {
            hostId: this.$session.get("hostId"),
        };
    },
    mounted() {
        EventBus.$on(
            "changeHostId",
            function (hostId) {
                this.hostId = hostId.toString();
            }.bind(this)
        );
    },
};
</script>
