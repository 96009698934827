import gql from "graphql-tag";

export const VoucherGroupBookings = {
    Queries: {
        VoucherGroupBooking: gql`
        query voucherGroupBooking($voucherId: ID, $bookingRequestId: ID) {
            voucherGroupBooking(voucherId: $voucherId, bookingRequestId: $bookingRequestId) {
                Id
                BookingRequestId
                VoucherId
                CreatedAt
                VoucherGroupBookingStatusId
            }
        }`,
    },
    Mutations: {
        SaveVoucherGroupBooking: gql`
        mutation saveVoucherGroupBooking($voucherGroupBooking: VoucherGroupBookingsInput) {
            saveVoucherGroupBooking(voucherGroupBooking: $voucherGroupBookings) {
                IsSuccess
                Message
            }
        }`,
        DeleteVoucherGroupBooking: gql`
        mutation deleteVoucherGroupBooking($voucherId: ID, $bookingRequestId: ID) {
            deleteVoucherGroupBooking(voucherId: $voucherId, bookingRequestId: $bookingRequestId) {
                IsSuccess
                Message
            }
        }`,
    },
};
