import gql from "graphql-tag";

export const UserGroups = {
    Queries: {
        UserGroupByIndicator: gql`
        query userGroupByIndicator($indicator: String) {
            userGroupByIndicator(indicator: $indicator) {
                Id
                Name
                Description
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
                Indicator
            }  
        }`,
    },
};
