<template lang="pug">
.step-bar
  .step-progress
    .value(:style="`--progress:${progressValue};`")
  .steps
    a.step.d-flex.flex-wrap.align-items-center.justify-content-center.disabled(v-for="step in steps" :class="{ 'active-step': step === activeStep, 'done-step': step.isDone, 'locked-step': step.isLocked }" @click="setStep(step.step)")
      .value.h2.m-0.d-flex.flex-wrap.align-items-center.justify-content-center
        font-awesome-icon(icon="check" v-if="step.isDone")
        font-awesome-icon(icon="lock" v-else-if="step.isLocked")
        template(v-else) {{step.step}}
      .description.small.w-100
        | {{step.label}}
        template(v-if="!step.required") &nbsp;(optional)

</template>
<script>
export default {
    name: "StepBar",
    props: {
        setStep: {
            type: Function,
            required: true,
        },
        currentStep: {
            type: Number,
            default: 1,
            validator(value) {
                return [1, 2, 3, 4].includes(value);
            },
        },
        steps: {
            type: Array,
            validator(steps) {
                return steps.every(
                    (step) => typeof step === "object" && !Array.isArray(step)
                );
            },
            required: true,
        },
    },
    computed: {
        progressValue() {
            try {
                const maxStep =
                    [...this.steps].sort((a, b) => a.step - b.step)?.pop()
                        ?.step || 1;
                const pValue = (this.currentStep - 1) / maxStep;

                return pValue + 0.3 * pValue;
            } catch (e) {
                console.error(e);
                return 0;
            }
        },
        activeStep() {
            try {
                return this.steps[this.currentStep - 1];
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
};
</script>
<style scoped lang="scss">
@import "@/styles/colors";

.step-bar {
    position: relative;

    .steps {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(4, auto);
    }

    .step-progress {
        width: calc(100% - 24%);
        top: calc(50% - 10px);
        background-color: $td-grey-lighter;
        left: 12%;

        &,
        .value {
            position: absolute;
            height: 3px;
            border-radius: 2px;
        }

        .value {
            width: calc((var(--progress) * 100%));
            background-color: $tdays-lighter;
            transition: width 0.8s;
        }
    }

    .step {
        text-decoration: none;

        &.locked-step {
            .value {
                cursor: not-allowed;
            }
        }

        .value {
            cursor: pointer;
            user-select: none;
            position: relative;
            border-radius: 50%;
            aspect-ratio: 1;
            width: 4rem;
            background-color: $page-bg;
            color: $td-grey-light;
            transition: all 0.4s;
            transform: scale(0.9);
        }

        .description {
            text-align: center;
            top: 100%;
            transition: all 0.4s;
            color: $td-grey-light;
            cursor: default;
            user-select: none;
        }

        &.active-step,
        &.done-step {
            .value {
                color: $white;
                opacity: 1;
            }

            .description {
                color: $td-grey-darker;
            }
        }

        &.done-step {
            .value {
                background-color: $tdays-basic;
            }
        }

        &.active-step {
            .value {
                transform: scale(1);
                background-color: $tdays-lighter;
            }
        }
    }
}
</style>
