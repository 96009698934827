import gql from "graphql-tag";

export const Addresses = {
    Queries: {
        DeliveryAddressByVoucherId: gql`
        query deliveryAddressByVoucherId($voucherId: ID) {
            deliveryAddressByVoucherId(voucherId: $voucherId) {
                Id
                Guid
                FirstName
                LastName
                FormOfAddress
                Title
                Company
                Street
                Street2
                PostalCode
                City
                Country
                Phone
                Email
            }
        }`,
        InvoiceAddressByVoucherId: gql`
        query invoiceAddressByVoucherId($voucherId: ID) {
            invoiceAddressByVoucherId(voucherId: $voucherId) {
                Id
                Guid
                FirstName
                LastName
                FormOfAddress
                Title
                Company
                Street
                Street2
                PostalCode
                City
                Country
                Phone
                Email
            }
        }`,
    }
}
