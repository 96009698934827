import gql from "graphql-tag";

export const ProductContracts = {
    Queries: {
        OpenProductContractsCount: gql`
        query openProductContractsCount($hostId: ID, $isRestock: Boolean) {
            openProductContractsCount(hostId: $hostId, isRestock: $isRestock)
        }`,
        ProductContracts: gql`
        query productContracts($hostId: ID, $userId: ID, $productDraftId: ID, $productContractStatusIds: [ID], $productContractTypeIds: [ID], $offset: Int, $limit: Int) {
            productContracts(hostId: $hostId, userId: $userId, productDraftId: $productDraftId, productContractStatusIds: $productContractStatusIds, productContractTypeIds: $productContractTypeIds, offset: $offset, limit: $limit) {
                Id
                Guid
                ProductContractNumber
                CreatedAt
                CreatedBy
                UpdatedAt
                PdfFilePath
                ProductContractStatusId
                SentAt
                SentBy
                SendNotice
                ConfirmedAt
                ConfirmedBy
                RejectedAt
                RejectedBy
                RejectionNotice
                IsRestock
                Editor
                RevokedAt
                RevokedBy
                RevokationNotice
                Language
                Location
                Signature
                Receiver
                ProductContractTypeId
                ProductContractName
                ProductContractId
                AccountOwner
                IBAN
                BIC
                UStIDNr
            }
        }`,
        ProductContractsCount: gql`
        query productContractsCount($hostId: ID, $userId: ID, $productDraftId: ID, $productContractStatusIds: [ID], $productContractTypeIds: [ID]) {
            productContractsCount(hostId: $hostId, userId: $userId, productDraftId: $productDraftId, productContractStatusIds: $productContractStatusIds, productContractTypeIds: $productContractTypeIds)
        }`,
        ProductContract: gql`
        query productContract(
            $id: ID
        ) {
            productContract(
                id: $id
            ) {
                Id
                Guid
                ProductContractNumber
                CreatedAt
                CreatedBy
                UpdatedAt
                PdfFilePath
                ProductContractStatusId
                SentAt
                SentBy
                SendNotice
                ConfirmedAt
                ConfirmedBy
                RejectedAt
                RejectedBy
                RejectionNotice
                IsRestock
                Editor
                RevokedAt
                RevokedBy
                RevokationNotice
                Language
                Location
                Signature
                Receiver
                ProductContractTypeId
                ProductContractName
                ProductContractId
                AccountOwner
                IBAN
                BIC
                UStIDNr
            }
        }`,
    },
    Mutations: {
        UpdateProductContracts: gql`
        mutation updateProductContract(
            $productContractId: ID
            $productContractStatusId: ID
            $editor: String
            $location: String
            $signature: String
            $notice: String
            $userId: ID
        ) {
            updateProductContract(
                productContractId: $productContractId
                productContractStatusId: $productContractStatusId
                editor: $editor
                location: $location,
                signature: $signature
                notice: $notice
                userId: $userId
            ) {
                IsSuccess
                Message
            }
        }`,
        SaveProductContract: gql`
        mutation saveProductContract($productContract: ProductContractsInput) {
            saveProductContract(productContract: $productContract) {
                IsSuccess
                Message
            }
        }`,
        LoadProductContractHtml: gql`
        mutation loadProductContractHtml($productContractId: ID) {
            loadProductContractHtml(productContractId: $productContractId) {
                IsSuccess
                Message
                StringData
            }
        }`,
    },
};
