import gql from "graphql-tag";

export const Permissions = {
    Queries: {
        Permissions: gql`
        query permissions($userId: ID, $permissionTypeId: ID) {
            permissions(userId: $userId, permissionTypeId: $permissionTypeId) {
                Id
                Description
            }
        }`,
    },
};
