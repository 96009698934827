const { Users } = require("@/lib/graphql/users/Users.ts");

class UsersComponent {
    async saveUser(apollo, user, permissionIds, hostIds) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!user) {
                return {
                    IsSuccess: false,
                    Message: "Kein Benutzer zum speichern gefunden.",
                };
            }
            if (!user.Email) {
                return {
                    IsSuccess: false,
                    Message: "Vergeben Sie bitte eine E-Mail Adresse.",
                };
            }
            if (!user.FirstName) {
                return {
                    IsSuccess: false,
                    Message: "Vergeben Sie bitte einen Vornamen.",
                };
            }
            if (!user.LastName) {
                return {
                    IsSuccess: false,
                    Message: "Vergeben Sie bitte einen Nachnamen.",
                };
            }
            if (
                !permissionIds ||
                !Array.isArray(permissionIds) ||
                !permissionIds.length
            ) {
                return {
                    IsSuccess: false,
                    Message: "Vergeben Sie mindestens ein Benutzerrecht.",
                };
            }
            delete user.IsProductManagerUser;
            const savedUser = await apollo
                .mutate({
                    mutation: Users.Mutations.SaveUser,
                    fetchPolicy: "no-cache",
                    variables: {
                        user: user,
                        permissionIds: permissionIds,
                        hostIds: hostIds,
                    },
                })
                .then(({ data }) => data?.saveUser)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedUser) {
                return {
                    IsSuccess: false,
                    Message: "Der Benutzer konnte nicht gespeichert werden.",
                };
            }
            return savedUser;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async updateUserPassword(apollo, userId, password) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: `Kein Apollo-Client gefunden.`,
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: `Kein Benutzer zum speichern gefunden.`,
                };
            }
            if (!password) {
                return {
                    IsSuccess: false,
                    Message: `Kein Passwort zum speichern gefunden.`,
                };
            }
            const updatedUserPassword = await apollo
                .mutate({
                    mutation: Users.Mutations.UpdateUserPassword,
                    fetchPolicy: "no-cache",
                    variables: {
                        userId: userId,
                        password: password,
                    },
                })
                .then(({ data }) => data?.updateUserPassword)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!updatedUserPassword) {
                return undefined;
            }
            return updatedUserPassword;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getUser(apollo, id) {
        try {
            if (!apollo || !id) {
                return undefined;
            }
            const user = await apollo
                .query({
                    query: Users.Queries.User,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: id,
                    },
                })
                .then(({ data }) => data?.user)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!user) {
                return undefined;
            }
            return user;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

module.exports = UsersComponent;
