import Vue from "vue";
import VueI18n from "vue-i18n";
import requireContext from "require-context.macro";

Vue.use(VueI18n);

const numberFormats = {
    de: {
        currency: {
            style: "currency",
            currency: "EUR",
        },
    },
    en: {
        currency: {
            style: "currency",
            currency: "EUR",
        },
    },
};

const setDateTimeFormats = {
    short: {
        year: "numeric",
        month: "short",
        day: "numeric",
    },
    long: {
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
    },
};

const dateTimeFormats = {
    de: setDateTimeFormats,
    en: setDateTimeFormats,
};

function loadLocaleMessages() {
    const locales = requireContext(
        "../locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || "de",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "de",
    messages: loadLocaleMessages(),
    dateTimeFormats: dateTimeFormats,
    numberFormats: numberFormats,
});
