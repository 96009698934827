<template lang="pug">
.container-fluid
  .row
    .col-12
      h2 {{$t("notifications.list.headline")}}
    .col-12.mt-3
      h5 {{$t("notifications.list.overviewHeadline")}}
    .col-12.mt-3
      .table-responsive
        table.table.table-bordered
          thead
            tr
              th(scope="col") Id
              th(scope="col") Titel
              th(scope="col") Gastgeber
              th(scope="col") Erstellt am
              th(scope="col") Gesehen von
              th(scope="col") Gesehen am
              th(scope="col") Details
          tbody
            notifications-table-row(:notification="notification" v-for="notification in notifications")
    .col-12.mt-3
      new-pagination(:load-more="loadMoreNotifications" :overall-count="notificationsCount" :current-count="notifications.length")
    .col-12.mt-3
      .float-right
        button.button.button-primary.button-tbook(@click="setAllNotificationsAsSeen") alle als gelesen markieren

</template>

<script>
import { Notifications } from "@/lib/graphql/notifications/Notifications.ts";
import NotificationsTableRow from "@/views/pages/account/notifications/NotificationsTableRow.vue";
import NewPagination from "@/views/partials/new-pagination.vue";
import EventBus from "@/views/event-bus";
import { NotificationTypesEnum } from "@/lib/enums/notificationTypes/NotificationTypesEnum.ts";

export default {
    name: "NotificationsList",
    components: { NewPagination, NotificationsTableRow },
    props: {
        slug: String,
    },
    data() {
        return {
            notifications: [],
            notificationsCount: 0,
            offset: 0,
            limit: 15,
        };
    },
    async mounted() {
        try {
            EventBus.$on(
                "changeHostId",
                function () {
                    this.notifications = [];
                    this.offset = 0;
                    this.loadNotifications();
                    this.loadNotificationsCount();
                }.bind(this)
            );
            await this.loadNotifications();
            await this.loadNotificationsCount();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadNotifications() {
            try {
                EventBus.$emit("changeLoadingState", true);
                const notifications = await this.$apollo
                    .query({
                        query: Notifications.Queries.Notifications,
                        fetchPolicy: "no-cache",
                        variables: {
                            hostIds: [this.$session.get("hostId")],
                            notificationTypeId: NotificationTypesEnum.Dashboard,
                            offset: this.offset,
                            limit: this.limit,
                        },
                    })
                    .then(({ data }) => data?.notifications)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                EventBus.$emit("changeLoadingState", false);
                if (!notifications) {
                    return;
                }
                this.notifications = this.notifications.concat(notifications);
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadNotificationsCount() {
            try {
                const notificationsCount = await this.$apollo
                    .query({
                        query: Notifications.Queries.NotificationsCount,
                        fetchPolicy: "no-cache",
                        variables: {
                            hostIds: [this.$session.get("hostId")],
                            notificationTypeId: NotificationTypesEnum.Dashboard,
                        },
                    })
                    .then(({ data }) => data?.notificationsCount)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (notificationsCount == undefined) {
                    return;
                }
                this.notificationsCount = notificationsCount;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadMoreNotifications() {
            try {
                this.offset += this.limit;
                await this.loadNotifications();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async setAllNotificationsAsSeen() {
            try {
                const confirmed = await this.$confirm(
                    "Sind Sie sicher, dass Sie alle Benachrichtigungen als gelesen markieren möchten?"
                )
                    .then()
                    .catch();
                if (!confirmed) {
                    return;
                }
                EventBus.$emit("changeLoadingState", true);
                const setAllNotificationsAsSeen = await this.$apollo
                    .mutate({
                        mutation:
                            Notifications.Mutations.SetAllNotificationsAsSeen,
                        fetchPolicy: "no-cache",
                        variables: {
                            seenBy: this.$session.get("userId"),
                            hostIds: [this.$session.get("hostId")],
                        },
                    })
                    .then(({ data }) => data?.setAllNotificationsAsSeen)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                EventBus.$emit("changeLoadingState", false);
                if (!setAllNotificationsAsSeen) {
                    return this.$alert(
                        "Die Benachrichtigungen konnten nicht als gelesen markiert werden."
                    );
                }
                if (setAllNotificationsAsSeen.Message) {
                    if (setAllNotificationsAsSeen.IsSuccess) {
                        this.$alert(setAllNotificationsAsSeen.Message);
                    } else {
                        this.$alert(setAllNotificationsAsSeen.Message);
                    }
                }
                EventBus.$emit("refreshUnseenNotifications");
                this.offset = 0;
                this.notifications = [];
                await this.loadNotifications();
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
