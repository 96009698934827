<template lang="pug">
.container-fluid.p-5
  .row(v-if="state == 1")
    .col-12
      .section-headline Vertragsdaten
    .col-6
      .form-group
        label(for="Editor").form-label Name*
        input#Editor.form-control.custom-control(type="text", v-model="productContract.Editor")
    .col-6
      .form-group
        label(for="Location").form-label Ort*
        input#Location.form-control.custom-control(type="text", v-model="location")
    .col-12
      .form-group
        label.form-label Unterschrift*
        template(v-if="signature")
          br
          img.border.w-100(:src="signature")
          button.button.button-primary.button-tpics.w-100(@click="signature = undefined") Unterschrift löschen
        VueSignaturePad.border.w-100(width="100%" height="100%" ref="signaturePad" :options="{ onEnd }" v-else)
    .col-12
      .form-group
        label.form-label(for="Notice").form-label Notiz
        input#Notice.form-control.custom-control(type="text", v-model="notice")
    .offset-6.col-6
      .float-right
        button.button.button-primary.button-tdays(:disabled="!productContract.Editor || !location || !tempSignature" :class="{ 'disabled': !productContract.Editor || !location || !tempSignature }" @click="setState(2)") weiter

  .row(v-if="state == 2")
    .col-12
      .section-headline Bankverbindung
    .col-12
      .form-group
        label.form-label IBAN*
        input.form-control(type="text" v-model="iban")
    .col-12
      .form-group
        label.form-label BIC*
        input.form-control(type="text" v-model="bic")
    .col-12
      .form-group
        label.form-label Kontoinhaber(in)*
        input.form-control(type="text" v-model="accountOwner")
    .col-12
      .form-check
        input#ProductContractTerms.form-check-input(type="checkbox" v-model="areTermsAccepted")
        label.form-check-label(for="ProductContractTerms")
          | Ich stimme den&nbsp;
          a(href="https://td-cloud.s3.eu-central-1.amazonaws.com/Gastgeber_AGB_de.pdf" target="_blank") AGB
          | &nbsp;der touriDat GmbH & Co. KG zu.*
      p.small
        | Falls Sie den&nbsp;
        a.small(:href="productContract.PdfFilePath" target="_blank") Vertrag
        | &nbsp;händisch unterzeichnen möchten, senden Sie uns diesen inkl. den&nbsp;
        a.small(href="https://td-cloud.s3.eu-central-1.amazonaws.com/Auftrags-AGB-07-2021.pdf" target="_blank") AGB
        | &nbsp;zu.
    .col-6
      button.button.button-primary.button-tbook(@click="setState(1)") zurück
    .col-6
      .float-right
        button.button.button-primary.button-tdays(:disabled="!isIBANValid || !isBICValid || !accountOwner || !areTermsAccepted" :class="{ 'disabled': !isIBANValid || !isBICValid || !accountOwner || !areTermsAccepted }" @click="saveProductContract") speichern

  .row(v-if="state == 3")
    .col-12
      ul.nav.nav-tabs
        li.nav-item.text-center.w-50
          a.nav-link.active(data-toggle="tab" href='#datasheet') Datenblatt
        li.nav-item.text-center.w-50
          a.nav-link(data-toggle="tab" href='#uploadImages') Bilder
      .tab-content.mb-3
        #datasheet.tab-pane.show.active
          datasheet-search-criteria-areas(:host-id="host.Id" v-if="host")
        #uploadImages.tab-pane
          .row
            .col-12
              p hier bilder uploaden


</template>

<script>
import { ProductContractStatusEnum } from "../../../lib/enums/productContractStatus/ProductContractStatusEnum";
import EventBus from "../../event-bus";
import { ProductContracts } from "@/lib/graphql/productContracts/ProductContracts.ts";
import { IbanComponent } from "@/lib/components/iban/IbanComponent.ts";
import DatasheetSearchCriteriaAreas from "@/views/pages/account/datasheet/datasheet-search-criteria-areas.vue";
import { Hosts } from "@/lib/graphql/hosts/Hosts.ts";

export default {
    name: "product-contract-modal",
    components: { DatasheetSearchCriteriaAreas },
    props: {
        productContractId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            productContract: undefined,
            state: 1,

            location: "",
            signature: "",
            tempSignature: "",
            notice: "",

            iban: "",
            bic: "",
            accountOwner: "",
            areTermsAccepted: false,

            host: undefined,

            ProductContractStatusEnum: ProductContractStatusEnum,
        };
    },
    async mounted() {
        try {
            await this.loadProductContract();
            await this.loadHost();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadProductContract() {
            try {
                EventBus.$emit("changeLoadingState", true);
                const productContract = await this.$apollo
                    .query({
                        query: ProductContracts.Queries.ProductContract,
                        fetchPolicy: "no-cache",
                        variables: {
                            id: this.productContractId,
                        },
                    })
                    .then(({ data }) => data?.productContract)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                EventBus.$emit("changeLoadingState", false);
                if (!productContract) {
                    return this.$alert(
                        "Es konnte kein Vertrag geladen werden."
                    );
                }
                this.productContract = productContract;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async saveProductContract() {
            try {
                EventBus.$emit("changeLoadingState", true);

                EventBus.$emit("changeLoadingState", false);
                EventBus.$emit("updateProductContracts");
                this.state = 3;
            } catch (e) {
                EventBus.$emit("changeLoadingState", false);
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadHost() {
            try {
                const host = await this.$apollo
                    .query({
                        query: Hosts.Queries.HostByProductContractId,
                        variables: {
                            productContractId: this.productContract.Id,
                        },
                    })
                    .then(({ data }) => data?.hostByProductContractId)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!host) {
                    return;
                }
                this.host = host;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        setState(state) {
            try {
                if (!state) {
                    return;
                }
                if (state == 2 && !this.signature) {
                    const { data } = this.$refs.signaturePad.saveSignature();
                    this.signature = data;
                }
                this.state = state;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        onEnd() {
            try {
                const { data } = this.$refs.signaturePad.saveSignature();
                this.tempSignature = data;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    computed: {
        isIBANValid() {
            try {
                if (!this.iban) {
                    return false;
                }
                const ibanComponent = new IbanComponent();
                const isIBANValid = ibanComponent.isIBANValid(this.iban);
                if (!isIBANValid) {
                    return false;
                }
                return true;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        isBICValid() {
            try {
                if (!this.bic) {
                    return false;
                }
                const ibanComponent = new IbanComponent();
                const isBICValid = ibanComponent.isBICValid(this.bic);
                if (!isBICValid) {
                    return false;
                }
                return true;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
    },
};
</script>
