<template lang="pug">
  b-card
    .row.p-3
      .col-12.d-flex.align-items-center
        h5 {{ $t('touriBook.voucher') }}:&nbsp;
        router-link(:to="{ name: 'VoucherDetails', params: { voucherId: voucher.Id } }")
          h5.font-weight-bold {{ voucher.Id }}
        .ml-auto(v-if="voucherGroupBooking")
          template(v-if="voucherGroupBooking.VoucherGroupBookingStatusId == VoucherGroupBookingStatusEnum.CustomerDeletionRequested")
            button.button.button-primary.button-tdays(
              @click="deleteVoucherGroupBooking"
              :disabled="isDisabled"
              :class="{ 'disabled': isDisabled }"
            ) annehmen
            button.button.button-primary.button-tpics(
              @click="saveVoucherGroupBooking"
              :disabled="isDisabled"
              :class="{ 'disabled': isDisabled }"
            ) ablehnen
          button.button.button-primary.button-tpics(
            v-else
            @click="confirmAndDeleteVoucherGroupBooking"
            :disabled="isDisabled"
            :class="{ 'disabled': isDisabled }"
          ) entfernen

</template>

<script>
import { VoucherGroupBookingStatusEnum } from "@/lib/enums/voucherGroupBookingStatus/VoucherGroupBookingStatusEnum.ts";
import EventBus from "@/views/event-bus";
import VoucherGroupBookingsComponent from "@/lib/components/voucherGroupBookings/VoucherGroupBookingsComponent";

export default {
    name: "VoucherGroupBookingVoucher",
    props: {
        voucher: {
            type: Object,
            required: true,
        },
        bookingRequest: {
            type: Object,
            required: true,
        },
        loadVouchers: {
            type: Function,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            voucherGroupBooking: undefined,
            VoucherGroupBookingStatusEnum: VoucherGroupBookingStatusEnum,
        };
    },
    async mounted() {
        try {
            this.loadVoucherGroupBooking();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadVoucherGroupBooking() {
            try {
                const voucherGroupBookingsComponent =
                    new VoucherGroupBookingsComponent();
                const voucherGroupBooking =
                    await voucherGroupBookingsComponent.getVoucherGroupBooking(
                        this.$apollo,
                        this.voucher?.Id,
                        this.bookingRequest?.Id
                    );
                if (!voucherGroupBooking) {
                    return;
                }
                this.voucherGroupBooking = voucherGroupBooking;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async confirmAndDeleteVoucherGroupBooking() {
            try {
                const code = await this.$prompt(
                    this.$t("constants.messages.enterCode").toString()
                )
                    .then((code) => code)
                    .catch((e) => e);
                if (!code) {
                    return;
                }
                if (
                    this.voucher.Guid.slice(-6).toLowerCase() !=
                    code.toLowerCase()
                ) {
                    return this.$alert(
                        this.$t("constants.messages.noCodeMatch").toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                EventBus.$emit("changeLoadingState", true);
                const deletedVoucherGroupBooking =
                    await this._deleteVoucherGroupBooking();
                EventBus.$emit("changeLoadingState", false);
                if (!deletedVoucherGroupBooking) {
                    return this.$alert(
                        `Die Gutscheingruppenanfrage für Gutschein ${this.voucher.Id} konnte nicht gelöscht werden.`
                    );
                }
                if (deletedVoucherGroupBooking.Message) {
                    this.$alert(deletedVoucherGroupBooking.Message);
                }
                if (deletedVoucherGroupBooking.IsSuccess) {
                    this.loadVouchers();
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async deleteVoucherGroupBooking() {
            try {
                const code = await this.$prompt(
                    this.$t("constants.messages.enterCode").toString()
                )
                    .then((code) => code)
                    .catch((e) => e);
                if (!code) {
                    return;
                }
                if (
                    this.voucher.Guid.slice(-6).toLowerCase() !=
                    code.toLowerCase()
                ) {
                    return this.$alert(
                        this.$t("constants.messages.noCodeMatch").toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                EventBus.$emit("changeLoadingState", true);
                const deletedVoucherGroupBooking =
                    await this._deleteVoucherGroupBooking();
                EventBus.$emit("changeLoadingState", false);
                if (!deletedVoucherGroupBooking) {
                    return this.$alert(
                        `Die Gutscheingruppenanfrage für Gutschein ${this.voucher.Id} konnte nicht gelöscht werden.`
                    );
                }
                if (deletedVoucherGroupBooking.Message) {
                    this.$alert(deletedVoucherGroupBooking.Message);
                }
                if (deletedVoucherGroupBooking.IsSuccess) {
                    this.loadVouchers();
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async saveVoucherGroupBooking() {
            try {
                this.voucherGroupBooking.VoucherGroupBookingStatusId =
                    VoucherGroupBookingStatusEnum.Created;
                const voucherGroupBookingsComponent =
                    new VoucherGroupBookingsComponent();
                const savedVoucherGroupBooking =
                    await voucherGroupBookingsComponent.saveVoucherGroupBooking(
                        this.$apollo,
                        this.voucherGroupBooking
                    );
                if (!savedVoucherGroupBooking) {
                    return this.$alert(
                        "Die Gutscheingruppenanfrage konnte nicht gespeichert werden."
                    );
                }
                if (savedVoucherGroupBooking.Message) {
                    this.$alert(savedVoucherGroupBooking.Message);
                }
                if (savedVoucherGroupBooking.IsSuccess) {
                    this.loadVouchers();
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async _deleteVoucherGroupBooking() {
            try {
                const voucherGroupBookingsComponent =
                    new VoucherGroupBookingsComponent();
                const deletedVoucherGroupBooking =
                    await voucherGroupBookingsComponent.deleteVoucherGroupBooking(
                        this.$apollo,
                        this.voucher.Id,
                        this.bookingRequest.Id
                    );
                if (!deletedVoucherGroupBooking) {
                    return {
                        IsSuccess: false,
                        Message: `Die Gutscheingruppenanfrage für Gutschein ${this.voucher.Id} konnte nicht gelöscht werden.`,
                    };
                }
                return deletedVoucherGroupBooking;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
