import gql from "graphql-tag";

export const GetEmailSettingsQuery = gql`
    query emailSettings {
        emailSettings {
            Id
            Description
            DescriptionForHost
        }
    }
`;
