<template lang="pug">
select.form-control.custom-control(type="dropdown" @change="setPageLimit" v-model="pageSize")
  option(selected, value=10) 10
  option(value=25) 25
  option(value=50) 50
  option(value=100) 100

</template>

<script>
import EventBus from "../../views/event-bus";

export default {
    name: "pagesize",
    props: {
        isProduct: {
            type: Boolean,
            required: false,
        },
        isVoucher: {
            type: Boolean,
            required: false,
        },
        isTouribook: {
            type: Boolean,
            required: false,
        },
        isUser: {
            type: Boolean,
            required: false,
        },
        isSettlement: {
            type: Boolean,
            required: false,
        },
        isNotification: {
            type: Boolean,
            required: false,
        },
        isContract: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            pageSize: 10,
            privatePageSize: 10,
        };
    },
    methods: {
        setPageLimit() {
            if (this.isProduct) {
                EventBus.$emit("changeProductPageSize", this.pageSize);
            } else if (this.isVoucher) {
                EventBus.$emit("changeVoucherPageSize", this.pageSize);
            } else if (this.isTouribook) {
                EventBus.$emit("changeTouribookPageSize", this.pageSize);
            } else if (this.isUser) {
                EventBus.$emit("changeUserPageSize", this.pageSize);
            } else if (this.isSettlement) {
                EventBus.$emit("changeSettlementPageSize", this.pageSize);
            } else if (this.isNotification) {
                EventBus.$emit("changeNotificationPageSize", this.pageSize);
            } else if (this.isContract) {
                EventBus.$emit("changeProductContractsPageSize", this.pageSize);
            }
        },
    },
};
</script>
