<template lang="pug">
.voucher-group-booking-parent-voucher.mb-2(v-if="parentVoucher")
  h5 {{ $t('touriBook.parentVoucher') }}:
  b-card
    .row.p-3
      .col-12.d-flex.align-self-center
        h5 {{ $t('touriBook.voucher') }}:&nbsp;
        router-link(:to="{ name: 'VoucherDetails', params: { voucherId: parentVoucher.Id } }")
          h5.font-weight-bold {{ parentVoucher.Id }}

</template>

<script>
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";

export default {
    name: "VoucherGroupBookingParentVoucher",
    props: {
        voucher: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            parentVoucher: undefined,
        };
    },
    async mounted() {
        try {
            this.loadParentVoucher();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadParentVoucher() {
            try {
                const vouchersComponent = new VouchersComponent();
                const voucher = await vouchersComponent.getVoucher(
                    this.$apollo,
                    undefined,
                    this.voucher.Id
                );
                if (!voucher) {
                    return;
                }
                this.parentVoucher = voucher;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
