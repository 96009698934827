import gql from "graphql-tag";

export const SystemFiles = {
    Queries: {
        HostSystemFiles: gql`
        query hostSystemFiles($hostId: ID, $status: String, $offset: Int, $limit: Int) {
            hostSystemFiles(hostId: $hostId, status: $status, offset: $offset, limit: $limit) {
                Id
                HostId
                Guid
                Name
                Description
                FileName
                FilePath
                FileType
                Ending
                CreatedAt
                Size
                PublishedAt
                Status
                SalesUserId
                EmployeeId
                RemoteFilePath
            }
        }`,
        HostSystemFilesCount: gql`
        query hostSystemFilesCount($hostId: ID, $status: String) {
            hostSystemFilesCount(hostId: $hostId, status: $status)
        }`,
    },
};
