<template lang="pug">
.modal-wrap.p-3
  .modal-close-wrap
    i.modal-close-button(@click="close")
      font-awesome-icon(:icon="['fas', 'times']")

  .container-fluid#help-modal
    //login
    template(v-if="viewDescription == 'login'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.modalHeadline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") Login

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.helpContent1")}}
            p
              b {{$t("modals.helpModal.helpContent2")}}
              br
              | {{$t("modals.helpModal.helpContent3")}}
            p
              | Mail:&nbsp;
              a.txt-tdays-lighter(href="mailto:info@touridat.de") info@touridat.de
              br
              | {{$t("modals.helpModal.helpContent4")}}
            // img.img-fluid.border.mb-4(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Dashboard/v2/help/login.gif" alt="")


    //home
    template(v-if="viewDescription == 'home'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.home.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.home.widgetsHeadline")}}
            li(v-on:click="showSecondPic") {{$t("modals.helpModal.home.contactHeadline")}}
            li(v-on:click="showThirdPic") {{$t("modals.helpModal.home.switchHostHeadline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.home.widgetsText1")}}
            p {{$t("modals.helpModal.home.widgetsText2")}}
            p {{$t("modals.helpModal.home.widgetsText3")}}
            p {{$t("modals.helpModal.home.widgetsText4")}}

          .help-content(v-show="showSecond")
            p  {{$t("modals.helpModal.home.contactText")}}
          .help-content(v-show="showThird")
            p {{$t("modals.helpModal.home.switchHostText")}}

    //products-list
    template(v-if="viewDescription == 'ProductList'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.productList.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.productList.headline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.productList.productText1")}}
            p {{$t("modals.helpModal.productList.productText2")}}


    //products-detail
    template(v-if="viewDescription == 'product-detail'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.productDetail.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.productDetail.headline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.productDetail.productDetailText")}}


    //VoucherList
    template(v-if="viewDescription == 'VoucherList'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.voucherList.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.voucherList.headline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.voucherList.voucherListText1")}}
            p {{$t("modals.helpModal.voucherList.voucherListText2")}}
            p {{$t("modals.helpModal.voucherList.voucherListText3")}}

    //voucher-detail
    template(v-if="viewDescription == 'voucher-detail'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.voucherDetail.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.voucherDetail.headline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.voucherDetail.voucherDetailText")}}

    //sales-detail
    template(v-if="viewDescription == 'sales-detail'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.salesDetail.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.salesDetail.headline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.salesDetail.salesDetailText1")}}
            p {{$t("modals.helpModal.salesDetail.salesDetailText2")}}

    //touribook-list
    template(v-if="viewDescription == 'touribook-list'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.touriBookList.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.touriBookList.headline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.touriBookList.touriBookListText1")}}
            p {{$t("modals.helpModal.touriBookList.touriBookListText2")}}
            p {{$t("modals.helpModal.touriBookList.touriBookListText3")}}

    //touribook-detail
    template(v-if="viewDescription == 'touribook-detail'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.touriBookDetail.headline1")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.touriBookDetail.headline2")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.touriBookDetail.touriBookDetailText1")}}
            p {{$t("modals.helpModal.touriBookDetail.touriBookDetailText2")}}

    //account-data
    template(v-if="viewDescription == 'account-data'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.accountData.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.accountData.headline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.accountData.text")}}

    //account-settlements
    template(v-if="viewDescription == 'account-settlements'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.accountSettlements.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.accountSettlements.headline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.accountSettlements.text")}}

    //account-docs
    template(v-if="viewDescription == 'account-docs'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.accountDocs.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.accountDocs.headline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.accountDocs.text1")}}
            p {{$t("modals.helpModal.accountDocs.text2")}}

    //account-datasheet
    template(v-if="viewDescription == 'account-datasheet'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.accountDatasheet.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.accountDatasheet.headline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.accountDatasheet.text1")}}
            p {{$t("modals.helpModal.accountDatasheet.text2")}}

    //account-users-list
    template(v-if="viewDescription == 'account-users-list'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.accountUsersList.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.accountUsersList.headline")}}
        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.accountUsersList.text1")}}
            p {{$t("modals.helpModal.accountUsersList.text2")}}

    //account-users-detail
    template(v-if="viewDescription == 'account-users-detail'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.accountUsersDetail.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.accountUsersDetail.headline")}}
        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.accountUsersDetail.text")}}

    //account-users-new
    template(v-if="viewDescription == 'account-users-new'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.accountUsersNew.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.accountUsersNew.headline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.accountUsersNew.text1")}}
            p {{$t("modals.helpModal.accountUsersNew.text2")}}
            p {{$t("modals.helpModal.accountUsersNew.text3")}}

    //account-security
    template(v-if="viewDescription == 'security'")
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom {{$t("modals.helpModal.accountSecurity.headline")}}
      .row
        .col-2
          ul
            li(v-on:click="showFirstPic") {{$t("modals.helpModal.accountSecurity.headline")}}

        .col-10
          .help-content(v-show="showFirst")
            p {{$t("modals.helpModal.accountSecurity.text")}}

</template>

<script>
export default {
    name: "help-modal",
    props: {
        viewDescription: String,
    },
    data() {
        return {
            showFirst: true,
            showSecond: false,
            showThrid: false,
        };
    },
    methods: {
        showFirstPic() {
            this.showFirst = true;
            this.showSecond = false;
            this.showThird = false;
        },
        showSecondPic() {
            this.showSecond = true;
            this.showFirst = false;
            this.showThird = false;
        },
        showThirdPic() {
            this.showThird = true;
            this.showFirst = false;
            this.showSecond = false;
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>

<style scoped>
#help-modal ul {
    padding-left: 0;
    list-style-type: none;
}

#help-modal ul li {
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 8px;
}

#help-modal ul li:hover {
    color: #488345;
}
</style>
